import React, { memo } from "react";

const PropertyComment = () => {
  return (
    <div className="PropertyDetail_comment PropertyDetails_notes">
      <h2 className="property_Details_heading">Comment</h2>
      <h6>
        Have questions or insights? Share your thoughts—your input could make
        all the difference!
      </h6>
      <textarea placeholder="Type your comment here..." rows={5}></textarea>
      <br />
      <button className="propertyDetails_moreInfo">Send</button>
    </div>
  );
};

export default memo(PropertyComment);
