import React, { useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const PieStatisticsChart = ({data}) => {
    const [activeIndex, setActiveIndex] = useState(null);


    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    return (
        <div className="Demographics_container">
        <div style={{ width: "30%",minWidth:'165px' }}>
                <PieChart width={150} height={150}>
                    <Pie
                        data={data}
                        dataKey="value"
                        cx={70}
                        cy={65}
                        outerRadius={70}
                        innerRadius={8}
                        startAngle={0}
                        endAngle={360}
                        cornerRadius={12}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={index}
                                fill={entry.color}
                                fillOpacity={activeIndex === null || activeIndex === index ? 1 : 0.2}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </div>
            <div className='Chart_listItems'>
                <ul className='propDetails_chartsStatistics'>
                    {data.map((entry, index) => (
                        <li
                            key={index}
                            style={{ paddingBottom: '15px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className='Chart_circle_icon' style={{ width: '20px', height: '20px', backgroundColor: entry.color, marginRight: '10px', borderRadius: '100%' }}></div>
                            <span className='PropertyDetails_Mbl_f14' style={{color:'#282828'}}>{entry.name}: {entry.value}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PieStatisticsChart
