import React , {memo} from "react";

const QueryCard = ({handleInfoModalClick}) => {

  return (
    <div className="w-100">
      <div className="row">
        <div className="col-md-6">
          <div
            className="propertyDetail_homeValueCard prop_details_getMoreInfo prop_detailsQueryCard"
            style={{ padding: "20px 16px" }}
          >
            <h6 className="PropertyDetails_Mbl_f18">Still have a question</h6>
            <button
              className="propertyDetails_moreInfo PropDetails_gradientBtn"
              onClick={handleInfoModalClick}
            >
              Contact us for more info
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(QueryCard);
