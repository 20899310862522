import React, { useEffect, useRef, useState } from "react";
import "@fontsource/open-sans";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import { Link } from "react-scroll";
import Navbar from "../Navbar/Navbar";
import {
  BedIcon,
  PerSqftIcon,
  SqftIcon,
  BuildingIcon,
  StoreyIcon,
  PropertyCommuteIcon,
  EditIcon,
  ScrollArrowIcon,
  ScrollArrowUpIcon,
  BathIcon,
  WatchSoldIcon,
  WatchingNeighbourIcon,
} from "../../SvgIcons/Icons";
import Cookies from "universal-cookie";
import PropertyImages from "./SubComponents/PropertyImages";
import NearbyCities from "../PropertyDetails/SubComponents/NearByCities";
import FindNearby from "../PropertyDetails/SubComponents/FindNearby";
import PopularSearches from "../PropertyDetails/SubComponents/PopularSearches";
import AgentQueryModal from "../PropertyDetails/SubComponents/Modals/AgentQueryModal";
import Footer from "../Footer/Footer";
import PropertyNotes from "../PropertyDetails/SubComponents/PropertyNotes";
import RatingModal from "../PropertyDetails/SubComponents/Modals/RatingModal";
import PropertyAgentCard from "../PropertyDetails/SubComponents/PropertyAgentCard";
import WhyBuyProperty from "../PropertyDetails/SubComponents/WhyBuyProperty";
import AboutProperty from "../PropertyDetails/SubComponents/AboutProperty";
import GetAlertCard from "../PropertyDetails/SubComponents/GetAlertCard";
import ImagesModal from "../PropertyDetails/SubComponents/Modals/ImagesModal";
import PropertyHighlights from "../PropertyDetails/SubComponents/PropertyHighlights";
import KeyFacts from "../PropertyDetails/SubComponents/KeyFacts";
import Rooms from "../PropertyDetails/SubComponents/Rooms";
import Amenities from "../PropertyDetails/SubComponents/Amenities";
import Incentives from "../PropertyDetails/SubComponents/Incentives";
import FeatureAndFinishes from "../PropertyDetails/SubComponents/FeatureAndFinishes";
import MaintenanceFee from "../PropertyDetails/SubComponents/MaintenanceFee";
import HomeValue from "../PropertyDetails/SubComponents/HomeValue";
import ValuesAndTrends from "../PropertyDetails/SubComponents/ValuesAndTrends";
import ExploreProperty from "../PropertyDetails/SubComponents/ExploreProperty/Index";
import Demographics from "../PropertyDetails/SubComponents/Demographics";
import HouseHoldComposition from "../PropertyDetails/SubComponents/HouseHoldComposition";
import PropertyTypeDistribution from "../PropertyDetails/SubComponents/PropertyTypeDistribution";
import AreaStatistics from "../PropertyDetails/SubComponents/AreaStatistics";
import ListingComparable from "../PropertyDetails/SubComponents/ListingComparable";
import PropertyFaqs from "../PropertyDetails/SubComponents/PropertyFaqs";
import QueryCard from "../PropertyDetails/SubComponents/QueryCard";
import MortgageCalculator from "../PropertyDetails/SubComponents/MortgageCalculator";
import WatchNeigbourHoodModal from "../PropertyDetails/SubComponents/Modals/WatchNeighbourHoodModal";
import SignUpModal from "../Modals/SignUpModal";
import ListingSoldModal from "../PropertyDetails/SubComponents/Modals/ListingSoldModal";
import CommuteModal from "../PropertyDetails/SubComponents/Modals/CommuteModal";
import EmailConfirmationModal from "../Modals/EmaiConfirmationModal";
import Skeleton from "@mui/material/Skeleton";
import MarketActivity from "../PropertyDetails/SubComponents/MarketActivity";
import BasicDetails from "../PropertyDetails/SubComponents/BasicDetails";
import PropertyComment from "../PropertyDetails/SubComponents/PropertyComment";
import FeaturedListing from "../PropertyDetails/SubComponents/FeaturedListing";
import PropertyHeader from "../PropertyDetails/SubComponents/PropertyHeader";
import EnvironmentFactor from "../PropertyDetails/SubComponents/EnvironmentFactor";
import AreaFactor from "../PropertyDetails/SubComponents/AreaFactor";
import Transits from "../PropertyDetails/SubComponents/Transits";

const AssignmentDetails = () => {
  const sections = [
    { id: "highlights", label: "Highlights" },
    { id: "incentives", label: "Incentives" },
    { id: "includeInMaintanceFee", label: "Included fee" },
    { id: "reasonToBuy", label: "Reason to buy" },
    { id: "calculator", label: "Mortgage calculator" },
    { id: "homeValue", label: "Home Value" },
    { id: "exploreProperty", label: "Explore Neighborhood" },
    { id: "compareListing", label: "Listing Comparable" },
  ];

  const cookies = new Cookies();
  const isUserlogin = cookies.get("jwt_authorization");
  const [isLoading, setIsLoading] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const lastScrollTopRef = useRef(0);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [isNeighborhoodModalOpen, setIsNeighborhoodModalOpen] = useState(false);
  const [isNotifyWhenSoldModalOpen, setIsNotifyWhenSoldModalOpen] =
    useState(false);
  const [isNotifyLoading, setIsNotifyLoading] = useState(false);
  const [isCommuteModalOpen, setIsCommuteModalOpen] = useState(false);
  const [isEmailConfirmedModal, setIsEmailConfirmedModal] = useState(false);
  const [signUpModalHeading, setSignUpModalHeading] =
    useState("Create an account");

  //Hide or Show Navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTopRef.current && !isScrollingDown) {
        setIsScrollingDown(true);
      } else if (
        currentScrollTop < lastScrollTopRef.current &&
        isScrollingDown
      ) {
        setIsScrollingDown(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingDown]);

  const handleSignUpModalClose = () => {
    setIsSignUpModalOpen(false);
    setSignUpModalHeading("Create an account");
  };

  const handleInfoModalClick = () => {
    setIsInfoModalOpen(true);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleRatingModalClose = () => {
    setRatingModalOpen(false);
  };

  const handleWatchNeighborHoodClick = () => {
    if (isUserlogin) {
      setIsNeighborhoodModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to view nearby Listings!");
    }
  };

  const handleNeighbourHoodModalClose = () => {
    setIsNeighborhoodModalOpen(false);
  };

  const handleNotifyWhenSoldClick = () => {
    if (isUserlogin) {
      setIsNotifyWhenSoldModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to view sold Listings!");
    }
  };

  const handleNotifyWhenSoldModalClose = () => {
    setIsNotifyWhenSoldModalOpen(false);
  };

  const handleCommuteModalClick = () => {
    if (isUserlogin) {
      setIsCommuteModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to navigate Your Commute!");
    }
  };

  const handleEmailConfirmedModalClose = () => {
    setIsEmailConfirmedModal(false);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const PropertyStatus = "Sale";
  const isPriceDrop = false;

  const latitude = "43.65107";
  const longitude = "-79.347015";

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {isLoading ? (
        <div className="container mt-3">
          <Skeleton animation="wave" variant="rounded" height={300} />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: "1.5rem" }}
          />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: "1rem" }} />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: isScrollingDown ? 100 : 1100,
              transition: "all ease .4s",
            }}
          >
            <Navbar />
          </div>
          <PropertyHeader
            isUserlogin={isUserlogin}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
            handleWatchNeighborHoodClick={handleWatchNeighborHoodClick}
            handleNotifyWhenSoldClick={handleNotifyWhenSoldClick}
            isNotifyLoading={isNotifyLoading}
            handleInfoModalClick={handleInfoModalClick}
            handleCommuteModalClick={handleCommuteModalClick}
            isMobileView={isMobileView}
            isScrollingDown={isScrollingDown}
          />
          <div className="container Propert_details_main_container">
            {/* <p className="mt-3 mb-0 prop_detailsBreadCrumbs PropertyDetails_Mbl_f14">
              402 - 18A Hazelton Ave &nbsp;
              <NavLink>Toronto</NavLink>
              <span>, </span>
              <NavLink>Down town</NavLink>
            </p> */}

            <div className="">
              <PropertyImages
                isMobileView={isMobileView}
                setImageModalOpen={setImageModalOpen}
              />
            </div>

            <div className="row mt-4">
              <div className="col-lg-8 col-12">
                <div className="FloorPlan_main_information Assignment_main_information d-flex justify-content-between">
                  <div className="Prop_assignment_contentDetails">
                    <div
                      className="PropertyStatusBadge PropertyDetails_Mbl_f14"
                      style={{
                        background:
                          PropertyStatus === "Sale" ? "#D1FADF" : "#FFDFC3",
                        color:
                          PropertyStatus === "Sale" ? "#008733" : "#BC300A",
                      }}
                    >
                      <span
                        style={{
                          background:
                            PropertyStatus === "Sale" ? "#008733" : "#BC300A",
                        }}
                      ></span>
                      {PropertyStatus === "Sale" ? "Selling Now" : "Sold"}
                    </div>
                    <h1 className="mt-2 mb-0">Shore Club Towns</h1>
                    <div className="d-flex align-items-center">
                      <h1>$85,000</h1>
                      <p
                        className={`${
                          isPriceDrop
                            ? "prop_show_upgrade"
                            : "prop_show_downgrade"
                        } m-0 ps-2 d-flex align-items-center`}
                        style={{
                          fontWeight: "600",
                          color: isPriceDrop ? "#008733" : "red",
                        }}
                      >
                        {isPriceDrop ? (
                          <ScrollArrowIcon color={"#008733"} size={24} />
                        ) : (
                          <ScrollArrowUpIcon color={"red"} size={24} />
                        )}
                        <span> $45K</span>
                      </p>
                    </div>
                    <p className="description PropertyDetails_Mbl_f14 m-0">
                      5601 Hudson Bend Rd Austin, TX 78734| Hudson Bend{" "}
                    </p>
                    <p className="description PropertyDetails_Mbl_f14 m-0">
                      {" "}
                      Est. $1335/mo
                      <span style={{ cursor: "pointer" }}>
                        {" "}
                        <Link
                          spy={true}
                          activeClass="active"
                          offset={-100}
                          smooth={true}
                          duration={500}
                          to={"calculator"}
                        >
                          <EditIcon />
                        </Link>
                      </span>
                    </p>
                  </div>
                  <div className="Prop_assignmentAddress">
                    <p className="PropertyDetails_Mbl_f14">
                      ID: <strong>5232</strong>{" "}
                    </p>
                    <p className="PropertyDetails_Mbl_f14">
                      Zip Code: <strong>45896</strong>{" "}
                    </p>
                    <p className="PropertyDetails_Mbl_f14">
                      Post date: <strong>4 July 24</strong>{" "}
                    </p>
                  </div>
                </div>
                <div
                  className={`Prop_assignmentAddress ${
                    isMobileView ? "" : "mt-4"
                  }`}
                >
                  <p className="PropertyDetails_Mbl_f14 text-start">
                    Developer: <strong>Sunfield Homes Ltd</strong>{" "}
                  </p>
                </div>

                <div
                  className={`d-flex flex-wrap property_information_DetailsIcon ${
                    isMobileView ? "mt-4" : "mt-3"
                  }`}
                >
                  <p>
                    <BedIcon /> <span>1 Bed</span>
                  </p>
                  <p>
                    <BathIcon color={"#282828"} /> <span>1-3 Bath</span>
                  </p>
                  <p>
                    <BuildingIcon /> <span>Condo</span>
                  </p>
                  <p>
                    <SqftIcon /> <span>720 sqft</span>
                  </p>
                  <p>
                    <StoreyIcon /> <span>Storey</span>
                  </p>
                  <p>
                    <PerSqftIcon /> <span>$1,650/SQFT</span>
                  </p>
                </div>

                <div className="Property_detailsWeb_hide w-100 mt-3 prop_details_lgShow mx-0">
                  <img
                    src="/images/mapDirection.png"
                    alt="MapLocationImg"
                    loading="lazy"
                    className="Find_nearByMobileMapImg mt-3"
                    onClick={openGoogleMaps}
                  />
                  <div className="d-flex flex-wrap w-100 gap-2 mt-3">
                    <button
                      className="Mbl_notifyBtn Mbl_notifyBtn_bluetbtn"
                      onClick={handleCommuteModalClick}
                    >
                      <span
                        className="DetailsIcon_greyBg"
                        style={{
                          background: "var(--Primary-Natural-blue, #007BFF)",
                        }}
                      >
                        <PropertyCommuteIcon color={"#fff"} />
                      </span>
                      <span>Add a commute</span>
                    </button>
                    <button
                      onClick={handleWatchNeighborHoodClick}
                      className="Mbl_notifyBtn Propmbl_f11"
                    >
                      <span className={"DetailsIcon_greyBg"}>
                        <WatchingNeighbourIcon color={"#4A4A4A"} size={"24"} />
                      </span>
                      Watching Neighbourhood
                    </button>
                    <button
                      disabled={isNotifyLoading}
                      onClick={handleNotifyWhenSoldClick}
                      className="Mbl_notifyBtn"
                    >
                      <span
                        className={"DetailsIcon_greyBg DetailsIcon_LightBg"}
                      >
                        <WatchSoldIcon color={"#007BFF"} size={"24"} />
                      </span>
                      Notify when Sold{" "}
                    </button>
                  </div>
                </div>

                <div className="mt-5">
                  <div id="marketActivity">
                    <MarketActivity isMobileView={isMobileView} />
                  </div>
                  <div id="highlights" className="Prop_details_CompMargin">
                    <PropertyHighlights isMobileView={isMobileView} />
                  </div>
                  <div id="propertyDetails" className="Prop_details_CompMargin">
                    <BasicDetails isMobileView={isMobileView} />
                  </div>
                  <div id="keyFacts" className="Prop_details_CompMargin">
                    <KeyFacts isMobileView={isMobileView} />
                  </div>
                  <div id="rooms" className="Prop_details_CompMargin">
                    <Rooms isMobileView={isMobileView} />
                  </div>
                  <div
                    id="featureAndFinishes"
                    className="Prop_details_CompMargin"
                  >
                    <FeatureAndFinishes isMobileView={isMobileView} />
                  </div>
                  <div id="amenities" className="Prop_details_CompMargin">
                    <Amenities isMobileView={isMobileView} />
                  </div>
                  <div
                    id="includeInMaintanceFee"
                    className="Prop_details_CompMargin"
                  >
                    <MaintenanceFee isMobileView={isMobileView} />
                  </div>
                  <div id="reasonToBuy" className="Prop_details_CompMargin">
                    <WhyBuyProperty isMobileView={isMobileView} />
                  </div>
                  <div id="incentives" className="Prop_details_CompMargin">
                    <Incentives isMobileView={isMobileView} />
                  </div>
                  <div id="calculator" className="Prop_details_CompMargin">
                    <MortgageCalculator isMobileView={isMobileView} />
                  </div>
                  <div id="homeValue" className="PropDetails_BorderNoneMargin">
                    <HomeValue isMobileView={isMobileView} />
                  </div>
                  <div id="valueAndTrends" className="Prop_details_CompMargin">
                    <ValuesAndTrends isMobileView={isMobileView} />
                  </div>
                  <div id="exploreProperty" className="Prop_details_CompMargin">
                    <ExploreProperty isMobileView={isMobileView} />
                  </div>
                  <div
                  id="exploreProperty"
                  className="PropDetails_BorderNoneMargin"
                >
                  <Transits isMobileView={isMobileView} />
                </div>
                <div
                  id="exploreProperty"
                  className="PropDetails_BorderNoneMargin"
                >
                  <AreaFactor isMobileView={isMobileView} />
                </div>
                <div
                  id="exploreProperty"
                  className="PropDetails_BorderNoneMargin"
                >
                  <EnvironmentFactor isMobileView={isMobileView} />
                </div>
                  <div
                    id="demographics"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <Demographics isMobileView={isMobileView} />
                  </div>
                  <div
                    id="householdComposition"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <HouseHoldComposition isMobileView={isMobileView} />
                  </div>
                  <div
                    id="propertyTypeDistribution"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <PropertyTypeDistribution isMobileView={isMobileView} />
                  </div>
                  <div
                    id="areaStatistics"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <AreaStatistics isMobileView={isMobileView} />
                  </div>
                  <div
                    id="featuredListing"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <FeaturedListing isMobileView={isMobileView} />
                  </div>
                  <div
                    id="compareListing"
                    className="PropDetails_BorderNoneMargin"
                  >
                    <ListingComparable
                      setIsSignUpModalOpen={setIsSignUpModalOpen}
                      setSignUpModalHeading={setSignUpModalHeading}
                      isUserlogin={isUserlogin}
                      isMobileView={isMobileView}
                    />
                  </div>
                  {/* <div id='getMoreInfo' className='mt-5'>
                       <GetMoreInfo />
                   </div> */}
                  <div
                    id="propertyFaqs"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <PropertyFaqs />
                  </div>
                  <div
                    id="queryCard"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <QueryCard handleInfoModalClick={handleInfoModalClick} />
                  </div>
                  <div
                    id="propertyComment"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <PropertyComment />
                  </div>
                  <div
                    id="aboutProperty"
                    className="Prop_details_CompMargin Details_mbl_margin"
                  >
                    <AboutProperty isMobileView={isMobileView} />
                  </div>
                  <div id="popularSearches" className="Prop_details_CompMargin">
                    <PopularSearches isMobileView={isMobileView} />
                  </div>
                  <div id="nearByCities" className="Prop_details_CompMargin">
                    <NearbyCities isMobileView={isMobileView} />
                  </div>
                </div>
              </div>

              {!isMobileView && (
                <div className="col-lg-4 col-12 PropDetailsMob_lg_hide">
                  <div>
                    <FindNearby
                      handleCommuteModalClick={handleCommuteModalClick}
                    />
                  </div>

                  <div className="mt-3">
                    <GetAlertCard
                      handleWatchNeighborHoodClick={
                        handleWatchNeighborHoodClick
                      }
                      handleNotifyWhenSoldClick={handleNotifyWhenSoldClick}
                      isNotifyLoading={isNotifyLoading}
                    />
                  </div>

                  <div
                    className={`position-sticky ${
                      isScrollingDown
                        ? "Stick_headerOnly"
                        : "prop_represent_linkStatic"
                    }  `}
                  >
                    <div className="mt-3">
                      <PropertyAgentCard
                        handleInfoModalClick={handleInfoModalClick}
                      />
                    </div>

                    <div>
                      <PropertyNotes
                        setIsSignUpModalOpen={setIsSignUpModalOpen}
                        isUserlogin={isUserlogin}
                        setSignUpModalHeading={setSignUpModalHeading}
                      />
                    </div>

                    <div className="Prop_details_section_box mt-3">
                      <ul>
                        {sections?.map((section, index) => (
                          <li key={index}>
                            <Link
                              spy={true}
                              activeClass="active"
                              offset={-100}
                              smooth={true}
                              duration={500}
                              to={section.id}
                            >
                              {section.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isSignUpModalOpen && (
              <SignUpModal
                open={isSignUpModalOpen}
                handleClose={handleSignUpModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setIsEmailConfirmedModal={setIsEmailConfirmedModal}
                heading={signUpModalHeading}
              />
            )}
            {isInfoModalOpen && (
              <AgentQueryModal
                open={isInfoModalOpen}
                handleClose={handleInfoModalClose}
              />
            )}

            {ratingModalOpen && (
              <RatingModal
                open={ratingModalOpen}
                handleClose={handleRatingModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
                isUserlogin={isUserlogin}
              />
            )}

            {isNeighborhoodModalOpen && (
              <WatchNeigbourHoodModal
                open={isNeighborhoodModalOpen}
                handleClose={handleNeighbourHoodModalClose}
              />
            )}

            {isNotifyWhenSoldModalOpen && (
              <ListingSoldModal
                open={isNotifyWhenSoldModalOpen}
                handleClose={handleNotifyWhenSoldModalClose}
              />
            )}

            {isCommuteModalOpen && (
              <CommuteModal
                open={isCommuteModalOpen}
                setIsCommuteModalOpen={setIsCommuteModalOpen}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
              />
            )}

            {isEmailConfirmedModal && (
              <EmailConfirmationModal
                open={isEmailConfirmedModal}
                handleClose={handleEmailConfirmedModalClose}
              />
            )}

            {imageModalOpen && (
              <ImagesModal
                open={imageModalOpen}
                handleClose={handleImageModalClose}
              />
            )}
          </div>

          <div className="Prop_detailsMobileFixedBtn prop_details_BtnShow Property_detailsBtn_hide">
            <button
              className="propertyDetails_moreInfo PropDetails_gradientBtn"
              onClick={handleInfoModalClick}
            >
              Request for more info
            </button>
            <button
              className="propertyDetails_moreInfo NoteBtn"
              onClick={() => {
                if (isUserlogin) {
                  setRatingModalOpen(true);
                } else {
                  setIsSignUpModalOpen(true);
                  setSignUpModalHeading(
                    "Become a member to access & Write Research Notes."
                  );
                }
              }}
            >
              Research Note
            </button>
          </div>
        </>
      )}

      <div style={{ marginTop: "6rem" }}>
        <Footer />
      </div>
    </>
  );
};

export default AssignmentDetails;
