import "../../../App.css";
import "@fontsource/montserrat";
import "./MapandListing.css";
import Loader from "./Loader";
import MapListingCards from "./MapListingCards";
import MapBox from "../GoogleMaps/MapBox";
import { Drawer } from "@mui/material";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DrawerContent from "./DrawerContent";
import baseURL from "../../../config";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { RotatingLines } from "react-loader-spinner";
import MapIcon from "@mui/icons-material/Map";
import { removePdfData } from "../../../ReduxStore/Slices/PdfDownloadSlice";
import { useDispatch, useSelector } from "react-redux";
import ResultNotFound from "../ResultNotFound/ResulutNotFound";

export default function MapandListing({
  ApiFilter,
  onFilteredData1,
  MixedListing,
  showListing,
  MapListing,
  scrollPage,
  setScrollPage,
  isLoading,
  totalPageCount,
  scrollLoading,
  setScrollLoading,
  sortInitiated,
  setSortInitiated,
  loadMore,
  setLoadMore,
  handleListClick,
  handleMapClick,
  toggleIcon,
  insidePolygon,
  setInsidePolygon,
  categoryLocation,
  centerPopUP,
  resetTrigger,
}) {
  const [isFetching, setIsFetching] = useState(false);
  const [drawerLoading, setDrawerLoading] = useState(false);
  const dispatch = useDispatch();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const filterDataSlice = useSelector((state) => state.filterDataSlice);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleFilteredData = (length) => {
  //   onFilteredData1(length);
  // };

  const handlePageChange = () => {
    if (scrollPage < totalPageCount && !isFetching) {
      setScrollLoading(true);
      setIsFetching(true);
      setTimeout(() => {
        setIsFetching(false);
        setScrollPage((prevPage) => prevPage + 1);
      }, 1000);
    }
  };

  let lastScrollTop = 0;

  useEffect(() => {
    const container = document.getElementById("mapcard");
    const handleScroll = () => {
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const threshold = 10;

        if (sortInitiated && scrollTop > lastScrollTop) {
          // Scroll to the top if sort has been initiated and scrolling down
          container.scrollTop = 0;
          // Reset sort initiation state
          setSortInitiated(false);
        } else if (
          !sortInitiated &&
          scrollTop > lastScrollTop &&
          scrollTop + clientHeight >= scrollHeight - threshold
        ) {
          // Load more data if not sorting and scrolling down near the bottom
          handlePageChange();
        }
        // eslint-disable-next-line
        lastScrollTop = scrollTop;
      }
    };
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handlePageChange, sortInitiated]);

  //listing Load More Button Work
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (scrollPage === totalPageCount) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }, [scrollPage, totalPageCount]);

  const handleMoreDeals = () => {
    if (!loadMore) {
      setLoadMore(true);
      setScrollPage((prevPage) => prevPage + 1);
      handleResize();
    }
  };

  //Drawer open even refresh
  const slug = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const path = location.pathname;
  const slugPresent = slug.id;
  const [draweritem, setDrawerItem] = useState();
  const [itemID, setItemID] = useState(1);
  const { searchData } = location.state || {};
  useEffect(() => {
    if (searchData === undefined) {
      if (slugPresent) {
        axios
          .get(`${baseURL}/api/Assignment/GetAssignment/${slugPresent}`)
          .then((response) => {
            if (response?.data?.data) {
              setDrawerItem(response?.data?.data);
              setOpenDrawer(response?.data?.data?.id);
              setItemID(response?.data?.data?.id);
              setDrawerLoading(false);
            } else {
              toast.error("There is no Data for this Assignment");
              navigate("/");
              setDrawerLoading(false);
            }
          })
          .catch((error) => {
            toast.error("Error in Fetching Listing");
            navigate("/");
            setDrawerLoading(false);
          });
      }
    }
    // eslint-disable-next-line
  }, [slugPresent]);

  useEffect(() => {
    const handlePopstate = () => {
      const currentPath = window.location.pathname;
      if (currentPath === "/") {
        setOpenDrawer(null);
        setItemID(1);
        dispatch(removePdfData());
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [dispatch]);

  const [openDrawer, setOpenDrawer] = useState(null);
  const toggleDrawer = (anchor, itemId) => () => {
    setOpenDrawer(null);
    setItemID(1);
    dispatch(removePdfData());
    navigate("/");
  };

  const isDrawerOpen = (itemId) => {
    // eslint-disable-next-line
    return openDrawer == itemId;
  };

  const handleResizeAndScroll = () => {
    handleResize();
  };

  const cardRef = useRef(null);
  const [showButton, setShowButton] = useState(true);

  const handleResize = () => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.clientHeight;
      setShowButton(window.innerHeight + window.scrollY - 520 < cardHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeAndScroll);
    window.addEventListener("scroll", handleResizeAndScroll);
    handleResizeAndScroll();
    return () => {
      window.removeEventListener("resize", handleResizeAndScroll);
      window.removeEventListener("scroll", handleResizeAndScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showMapButton, setShowMapButton] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const maxScrollHeight = 300;
      if (scrollPosition <= maxScrollHeight) {
        setShowMapButton(true);
      } else {
        setShowMapButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [hoveredSlug, setHoveredSlug] = useState("");

  const handleHoveredListCard = (slug) => {
    setHoveredSlug(slug);
  };
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && mapRef.current.resize) {
      mapRef.current.resize();
    }
  }, [showListing, MapListing]);

  return (
    <div className="row">
      <div className="MapandListing_container">
        <div className="flex-column w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              ref={cardRef}
              className={`${
                showListing
                  ? "onlyListing_cards"
                  : "MapandListingcard_container"
              } ${MapListing && "d-none w-0"}`}
              id="mapcard"
            >
              <div
                className={`${showListing ? "d-flex flex-column w-100" : ""}`}
              >
                {filterDataSlice[0]?.length === 0 ? (
                  <div className={MixedListing && 'No_found_centerDiv'}>
                    <ResultNotFound />
                  </div>
                ) : (
                  <>
                    <MapListingCards
                      isLoading={isLoading}
                      setDrawerLoading={setDrawerLoading}
                      handleHoveredListCard={handleHoveredListCard}
                    />
                    {showMore && showListing ? (
                      <div className="justifycenter aligncenter mt-5">
                        {loadMore ? (
                          <RotatingLines width={50} strokeColor="grey" />
                        ) : (
                          <button
                            className="btn btn-primary font"
                            onClick={handleMoreDeals}
                            disabled={loadMore}
                          >
                            Load more
                          </button>
                        )}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              {isMobileView && showButton && (
                <div className="list_btnscroll" onClick={handleMapClick}>
                  <button className="d-flex">
                    <MapIcon style={{ color: "#fff" }} />
                    <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                      Map
                    </span>
                  </button>
                </div>
              )}
            </div>
            <div
              className={`${
                MapListing ? "onlymap_show" : `MapandListing_map Mobile__Hide`
              } ${showListing && "d-none w-0"}`}
            >
              <MapBox
                setDrawerLoading={setDrawerLoading}
                centerPopUP={centerPopUP}
                hoveredSlug={hoveredSlug}
                toggleIcon={toggleIcon}
                insidePolygon={insidePolygon}
                setInsidePolygon={setInsidePolygon}
                setScrollPage={setScrollPage}
                categoryLocation={categoryLocation}
                mapRef={mapRef}
                isLoading={isLoading}
                resetTrigger={resetTrigger}
              />
              {isMobileView && showMapButton && (
                <div className="list_btnscroll" onClick={handleListClick}>
                  <button className="d-flex">
                    <MapIcon style={{ color: "#fff" }} />
                    <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                      List
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* {showListing && (
          <div className="flex-column w-100">
            <div className="onlyListing_cards" ref={cardRef}>
              <MapListingCards
                isLoading={isLoading}
                setDrawerLoading={setDrawerLoading}
              />
            </div>
            {isMobileView && showButton && (
              <div className="list_btnscroll" onClick={handleMapClick}>
                <button className="d-flex">
                  <MapIcon style={{ color: '#fff' }} />
                  <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                    Map
                  </span>
                </button>
              </div>
            )}
            {showMore ? (
              <div className="justifycenter aligncenter mt-5">
                {loadMore ? (
                  <RotatingLines width={50} strokeColor="grey" />
                ) : (
                  <button
                    className="btn btn-primary font"
                    onClick={handleMoreDeals}
                    disabled={loadMore}
                  >
                    Load more
                  </button>
                )}
              </div>
            ) : null}
          </div>
        )}
        {MapListing && (
          <div className="d-flex justify-content-center align-items-center flex-column w-100">
            <div className="onlymap_show">
              <MapBox
                setDrawerLoading={setDrawerLoading}
                centerPopUP={true}
                toggleIcon={toggleIcon}
                setSouthLatLong={setSouthLatLong}
                setNorthLatLong={setNorthLatLong}
                setWestLatLong={setWestLatLong}
                setEastLatLong={setEastLatLong}
                setScrollPage={setScrollPage}
                categoryLocation={categoryLocation}
              />
              {isMobileView && showMapButton && (
                <div className="list_btnscroll" onClick={handleListClick}>
                  <button className="d-flex">
                    <MapIcon style={{ color: '#fff' }} />
                    <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                      List
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )} */}
      </div>

      {drawerLoading ? (
        <div className="scrollLoader">
          <Loader size="80" />
          <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
        </div>
      ) : (
        <Drawer
          anchor="right"
          open={isDrawerOpen(itemID)}
          onClose={toggleDrawer("right", itemID)}
        >
          <DrawerContent
            item={draweritem}
            anchor={"right"}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      )}
    </div>
  );
}
