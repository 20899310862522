import React from 'react'
import { BarChart, Bar, Tooltip, YAxis, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="Prop_lineChart_custom_tooltip">
                <p className='Prop_lineChart_custom_tooltip_year'>{`Year: ${label}`}</p>
                <p className='Prop_lineChart_custom_tooltip_Des' style={{ color: '#0253a0' }}>{`Condo: $ ${payload[0]?.payload?.AllProperties?.toLocaleString("en-US")}`}</p>
                <p className='Prop_lineChart_custom_tooltip_Des' style={{ color: '#F6941C' }}>{`All Properties: $ ${payload[0]?.payload?.condo?.toLocaleString("en-US")}`}</p>
            </div>
        );
    }

    return null;
};

const CustomLegend = () => (
    <div className="d-flex align-items-center justify-content-center mb-3" style={{ gap: '20px' }}>
        <div className="d-flex align-items-center">
            <span className='composed_chart_legend_cirlce' style={{ backgroundColor: '#0253a0' }}></span>
            <span className="ps-2 composed_chart_legend_text">Condo</span>
        </div>
        <div className="d-flex align-items-center">
            <span className='composed_chart_legend_cirlce' style={{ backgroundColor: '#F6941C' }}></span>
            <span className="ps-2 composed_chart_legend_text">All Properties</span>
        </div>
    </div>
);

const formatYAxis = (tick) => {
    return `$${tick.toLocaleString("en-US")}`;
};


const TwoBarChart = ({ data }) => {
    return (
        <div className='PropDetailsGraph_scroll'>
            <div className='Prop_details_simpleChart_container prop_twoChart mt-2 w-100' style={{ padding: '20px 20px 45px 20px', minWidth: '700px' }}>
                <div className="legend-container">
                    <CustomLegend />
                </div>
                <ResponsiveContainer width="100%" height={410}>
                    <BarChart data={data} className='Prop_details_simpleChart Prop_details_soldChart' barGap={0} {...{
                        overflow: 'visible'
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" angle={90} textAnchor='start' />
                        <YAxis tickFormatter={formatYAxis} />
                        <Tooltip content={<CustomTooltip />} position={{ x: 'auto', y: 'auto' }} top='-8px' />                    <Bar type="monotone" dataKey="condo" stackId="a" fill="#0253A0" activeBar={{ fill: '#0253A0', }} barSize={15} />
                        <Bar type="monotone" dataKey="AllProperties" stackId="b" fill="#F6941C" activeBar={{ fill: '#F6941C', }} barSize={15} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default TwoBarChart
