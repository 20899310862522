import React, { memo } from "react";
import {
  NotificationIcon,
  WatchingNeighbourIcon,
  WatchSoldIcon,
} from "../../../SvgIcons/Icons";
import { RotatingLines } from "react-loader-spinner";

const GetAlertCard = ({
  handleWatchNeighborHoodClick,
  handleNotifyWhenSoldClick,
  isNotifyLoading,
}) => {
  return (
    <div className="Prop_details_section_box Propert_detailRepresentatorCard d-flex" style={{overflow: 'hidden'}}>
      <div
        className="propertyDetail_homeValue_icon"
        style={{ width: "fit-content", height: "fit-content" }}
      >
        <NotificationIcon />
      </div>
      <div className="ps-3">
        <h6>Get Alert</h6>
        <p>
          Receive updates for Condo Apt homes in Waterfront Communities C8 -
          Toronto
        </p>
        <button
          className="mt-3 propertyDetails_moreInfo prop_notifyBtn"
          style={{
            borderBottom: "1px solid var(--Accent-Medium-Gray, #E2E2E2)",
            cursor: "auto",
          }}
        >
          Watch Neighborhood
          <span
            className={"DetailsIcon_greyBg"}
            onClick={handleWatchNeighborHoodClick}
          >
            <WatchingNeighbourIcon color={"#4A4A4A"} size={"24"} />
          </span>
        </button>
        {isNotifyLoading ? (
          <div className="d-flex justify-content-center mt-2">
            <RotatingLines width={40} strokeColor="grey" />
          </div>
        ) : (
          <button
            className="propertyDetails_moreInfo prop_notifyBtn pb-0"
            style={{ cursor: "auto" }}
          >
            Notify when Sold{" "}
            <span
              className={"DetailsIcon_greyBg DetailsIcon_LightBg"}
              onClick={handleNotifyWhenSoldClick}
            >
              <WatchSoldIcon color={"#007BFF"} size={"24"} />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(GetAlertCard);
