import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Box, Modal } from "@mui/material";
import { CloseIcon, SuccesfullySubmitedIcon } from "../../../../SvgIcons/Icons";
import axios from "axios";
import { toast } from "react-toastify";
import { NeedHomeBaseURL } from "../../../../config";
import { useSelector } from "react-redux";

const WatchNeigbourHoodModal = ({ isUserlogin, open, handleClose, data }) => {
  const jwt_token = isUserlogin;
  const userData = useSelector((state) => state.userProfileDataSlice);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewListing, setIsNewListing] = useState(false);
  const [isSoldListing, setIsSoldListing] = useState(false);
  const [isDetailedListing, setIsDetailedListing] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isSuccessfullySubmited, setIsSuccessfullySubmited] = useState(false);

  const handleSubmit = async () => {
    let error = false;
    if (!isNewListing && !isDetailedListing && !isSoldListing) {
      error = true;
    }

    if (!error && isUserlogin && userData) {
      setIsLoading(true);
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const Watchdata = {
        Id: "0",
        ProId: data?.uId, //UId of listing
        Lat: data?.lat,
        Lng: data?.lng,
        NewListing: isNewListing, //If selected then true
        SoldListing: isSoldListing, //If selected then true
        DetailListing: isDetailedListing, //If selected then true
        UserId: userData[0]?.id,
      };
      try {
        const response = await axios.post(
          `${NeedHomeBaseURL}/api/AuthData/AddWatchNeighbour`,
          Watchdata,
          {
            headers,
          }
        );
        if (response?.data?.result === 1) {
          setIsSuccessfullySubmited(true);
          setIsLoading(false);
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="Prop_detail_infoModal Prop_detailsValueModal WatchNeighbourHood_inputs">
        {isSuccessfullySubmited ? (
          <div className="d-flex flex-column align-items-center">
            <div className="propertyDetail_homeValue_icon">
              <SuccesfullySubmitedIcon />
            </div>
            <h6 style={{ fontSize: "20px" }} className="text-center mt-3">
              Successfully Updated
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-1 text-center">
              You will receive an email notification when the listing is add,
              sold, and delisted listing
            </p>

            <div className="mt-2">
              <button onClick={handleClose}>Okay</button>
            </div>
          </div>
        ) : (
          <>
            {/* <div className="propertyDetail_homeValue_icon">
              <WatchNeighbourHoodIcon />
            </div> */}
            <h6 style={{ fontSize: "25px" }} className="mt-2">
              Watch Neighborhood Listings
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-2">
              Please select the listings you are interested in.
            </p>
            <div className="w-100 d-flex align-items-center mt-3">
              <input
                type="checkbox"
                onChange={() => setIsNewListing(!isNewListing)}
              />
              <span className="ps-2">New Listings</span>
            </div>

            <div className="w-100 d-flex align-items-center mt-3">
              <input
                type="checkbox"
                onChange={() => setIsSoldListing(!isSoldListing)}
              />
              <span className="ps-2">Sold Listings</span>
            </div>

            <div className="w-100 d-flex align-items-center mt-3">
              <input
                type="checkbox"
                onChange={() => setIsDetailedListing(!isDetailedListing)}
              />
              <span className="ps-2">Detailed Listings</span>
            </div>

            {isSubmited &&
              !isSoldListing &&
              !isDetailedListing &&
              !isNewListing && (
                <div className="mt-3">
                  <span style={{ color: "red", marginTop: "10px" }}>
                    Kindly select atleast one option
                  </span>
                </div>
              )}

            {isLoading ? (
              <div className="aligncenter justifycenter mt-3">
                <RotatingLines width={50} strokeColor="grey" />
              </div>
            ) : (
              <div className="mt-3">
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    setIsSubmited(true);
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
        <div
          className="position-absolute Property_detailsWeb_hide"
          style={{ top: "20px", right: "15px" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
      </Box>
    </Modal>
  );
};

export default WatchNeigbourHoodModal;
