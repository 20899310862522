// DrawerContent.js
import React from "react";
import Box from "@mui/material/Box";
import { Chip, TextField } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DownloadIcon from "@mui/icons-material/Download";
import MapandListingDrawerMap from "./MapandListingDrawerMap";
import GarageIcon from "@mui/icons-material/Garage";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "./Loader";
import CottageIcon from "@mui/icons-material/Cottage";
import { useState, useEffect } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import MortgageCalculator from "./MortgageCalculator";
import baseURL from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import BathtubIcon from "@mui/icons-material/Bathtub";
import BedIcon from "@mui/icons-material/Bed";
import { FaVectorSquare } from "react-icons/fa";
// import EnhancedEncryptionSharpIcon from '@mui/icons-material/EnhancedEncryptionSharp';
import { FaParking } from "react-icons/fa";
import { BsSunFill } from "react-icons/bs";
import ShareFeature from "../../../Components/Share-Component/ShareFeature";
import { toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  addPdfData,
  removePdfData,
} from "../../../../src/ReduxStore/Slices/PdfDownloadSlice";
import DrawerPdf from "../../../Components/DrawerPdf/DrawerPdf";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  addFavItem,
  removeFavItem,
} from "../../../ReduxStore/Slices/HeartDataSlice";
import { saveAs } from "file-saver";
import jwtDecode from "jwt-decode";
import EscalatorIcon from "@mui/icons-material/Escalator";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { MdOutlineBalcony } from "react-icons/md";
import { GiLockers } from "react-icons/gi";
import { Helmet } from "react-helmet";

const DrawerContent = ({ item, anchor, toggleDrawer }) => {
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favouriteData = useSelector((state) => state.heartDataSlice);
  // eslint-disable-next-line
  const nowAssignmentPrice =
    item?.assignmentPrices?.length > 0
      ? item?.assignmentPrices[item?.assignmentPrices?.length - 1].prices
      : item?.assignmentPrice;
  const NowSqftPrice = Math.ceil(nowAssignmentPrice / item?.interiorSize);
  const WasSqftPrice = Math.ceil(item?.assignmentPrice / item?.interiorSize);
  Math.ceil(item?.assignmentPrice / item?.interiorSize);
  const [loading, setLoading] = useState(true);
  const [downVlauePdf, setDownValuePdf] = useState("");
  const [yearsVlauePdf, setyearsValuePdf] = useState(30);
  const [landTaxPdf, setLandTaxPdf] = useState("");
  const [totalMortgagePdf, setTotalMortgagePdf] = useState("");
  const [interstPdf, setInterestPdf] = useState("");
  const [monthlyMortgagePdf, setMonthlyMortgagePdf] = useState("");
  const [purchasePricePdf, setPurchasePricePdf] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [whatsAppsubmitLoader, setWhatsAppsubmitLoader] = useState(false);
  // eslint-disable-next-line
  const [isSubmited, setIsSubmited] = useState(false);
  const [value, setValue] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [offer, setOffer] = useState(
    `I want to book an appointment to discuss ${item.projectName}, ${item.address} - ${item.city}`
  );
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [offerError, setOfferError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  // eslint-disable-next-line
  const [formError, setFormError] = useState("");

  // ===========================================TracKing Work==============================================
  const userData = useSelector((state) => state.profileDataSlice[0]?.data);
  useEffect(() => {
    const fetchDataFromLocalStorage = () => {
      const existingData =
        JSON.parse(localStorage.getItem("bulkTrackingData")) || [];
      return existingData;
    };

    const saveDataToLocalStorage = (data) => {
      localStorage.setItem("bulkTrackingData", JSON.stringify(data));
    };

    const trackVariable = localStorage.getItem("trackVariable") || "0";

    if (!jwt_token) {
      localStorage.setItem("trackVariable", "0");
      const existingData = fetchDataFromLocalStorage();
      const newData = {
        id: 0,
        userId: 0,
        projectName: item.projectName,
        slug: item.slug,
      };
      existingData.push(newData);
      saveDataToLocalStorage(existingData);
    } else {
      const existingData = fetchDataFromLocalStorage();
      // Map data to the format required by your API
      const mappedData = existingData.map((dataItem) => ({
        id: dataItem.id,
        userId: userData?.id,
        projectName: dataItem.projectName,
        slug: dataItem.slug,
      }));
      const ActiveTrackingData = {
        Id: 0,
        UserId: userData?.id,
        ProjectName: item.projectName,
        slug: item.slug,
      };

      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };

      const apiUrl =
        trackVariable === "0"
          ? `${baseURL}/api/data/AddActivityTrackedbulk`
          : `${baseURL}/api/data/AddActivityTracked`;

      axios
        .post(apiUrl, trackVariable === "0" ? mappedData : ActiveTrackingData, {
          headers,
        })
        .then((response) => {
          localStorage.setItem("trackVariable", "1");
          saveDataToLocalStorage([]);
        })
        .catch((error) => {
          localStorage.setItem("trackVariable", "1");
          console.error("Error posting data to API", error);
        });
    }
    // eslint-disable-next-line
  }, [item, jwt_token]);

  //handle Favourite Work
  const [cardIde, setCardIde] = useState([]);
  const [FavInProgress, setFavInProgress] = useState({});

  useEffect(() => {
    const cardIds = favouriteData?.favItems?.map((item) => item?.uId);
    setCardIde(cardIds);
  }, [favouriteData]);

  const handleHeartClick = async (uId) => {
    if (!jwt_token) {
      return navigate("/login", {
        state: { returnUrl: window.location.pathname },
      });
    }
    if (FavInProgress[uId]) {
      return;
    }

    setFavInProgress((prevState) => ({
      ...prevState,
      [uId]: true,
    }));

    const isLogedIn = localStorage.getItem("anonymous");
    const isUserAuthorize = jwt_token && isLogedIn === "1";
    let emailConfirmed = false;
    const decoded = jwtDecode(jwt_token);
    const emailConfirmedStr =
      decoded[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous"
      ];
    emailConfirmed = emailConfirmedStr === "True";

    if (isUserAuthorize && emailConfirmed) {
      const isAlreadyFavorited = cardIde.includes(uId);
      if (isAlreadyFavorited) {
        setCardIde((prevIds) => prevIds.filter((itemId) => itemId !== uId));
        DeleteToFav(uId);
      } else {
        setCardIde((prevIds) => [...prevIds, uId]);
        AddTofav(uId);
      }
    } else {
      navigate("/email-confirmation?isEmailConfirmed=false");
    }
  };

  const AddTofav = async (uId) => {
    try {
      const data = {
        Id: "0",
        ProId: uId,
      };

      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const formData = new FormData();
      formData.append("AddFavouriteRequest", JSON.stringify(data));
      const response = await axios.post(
        `${baseURL}/api/User-Data/AddFavourite`,
        data,
        { headers }
      );

      if (response?.data?.result === 1) {
        dispatch(addFavItem({ uId: uId, tableUId: response?.data?.data?.uId }));
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      } else {
        setCardIde((prevIds) => prevIds.filter((itemId) => itemId !== uId));
        toast.error("Try Again!");
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      }
    } catch (error) {
      setCardIde((prevIds) => prevIds.filter((itemId) => itemId !== uId));
      toast.error("Try Again!");
      setFavInProgress((prevState) => ({
        ...prevState,
        [uId]: false,
      }));
    }
  };

  const DeleteToFav = async (uId) => {
    const tableUId = favouriteData?.favItems?.find((item) => item?.uId === uId);
    console.log("UID", tableUId);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const response = await axios.delete(
        `${baseURL}/api/User-Data/DeleteFavourite/${tableUId?.tableUId}`,
        config
      );

      if (response?.data?.result === 1) {
        dispatch(removeFavItem({ uId: uId }));
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      } else {
        toast.error("Delete Again!");
        setCardIde((prevIds) => [...prevIds, uId]);
        setFavInProgress((prevState) => ({
          ...prevState,
          [uId]: false,
        }));
      }
    } catch (error) {
      toast.error("Delete Again!");
      setCardIde((prevIds) => [...prevIds, uId]);
      setFavInProgress((prevState) => ({
        ...prevState,
        [uId]: false,
      }));
    }
  };

  useEffect(() => {
    if (!jwt_token) {
      localStorage.removeItem("fullName");
      localStorage.removeItem("email");
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("offer");
    }
  }, [jwt_token]);

  useEffect(() => {
    if (userData && userData.firstName) {
      localStorage.setItem("fullName", userData.firstName);
    }
    if (userData && userData.email) {
      localStorage.setItem("email", userData.email);
    }
    if (userData && userData.phoneNumber) {
      localStorage.setItem("phoneNumber", userData.phoneNumber);
    }
  }, [userData]);

  useEffect(() => {
    setFullName(localStorage.getItem("fullName"));
    setEmail(localStorage.getItem("email"));
    setValue(localStorage.getItem("phoneNumber"));
  }, []);

  const handleNameChange = (event) => {
    setFullNameError(false);
    setFullName(event.target.value);
  };
  const handleMailChange = (event) => {
    setEmailError(false);
    setEmail(event.target.value);
  };
  const handleOfferChange = (event) => {
    setOfferError(false);
    setOffer(event.target.value);
  };

  const handlePhoneInputChange = (value) => {
    setPhoneError(false);
    setValue(value);
  };

  const currentUrl = window.location.href;

  const handlePropertySubmit = async (item, clickedBy) => {
    let isFieldsfilled = true;
    if (!fullName) {
      setFullNameError(true);
      isFieldsfilled = false;
    }
    if (fullName) {
      setFullNameError(false);
    }

    if (!email) {
      setEmailError(true);
      isFieldsfilled = false;
    }
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError(true);
        isFieldsfilled = false;
      }
    }

    if (!value) {
      setPhoneError(true);
      isFieldsfilled = false;
    }

    if (value) {
      const selectedCountry = "CA";
      if (selectedCountry) {
        const isValid = isValidPhoneNumber(value, selectedCountry);
        if (!isValid) {
          setPhoneError(true);
          isFieldsfilled = false;
        } else {
          setPhoneError(false);
        }
      }
    }

    if (!offer) {
      setOfferError(true);
      isFieldsfilled = false;
    }
    if (offer) {
      setOfferError(false);
    }

    if (!isFieldsfilled) {
      setFormError("All fields are required");
    } else {
      if (clickedBy === "WhatsApp") {
        setWhatsAppsubmitLoader(true);
      } else {
        setSubmitLoader(true);
      }

      const data = {
        FirstName: fullName,
        Email: email,
        Phone: value,
        Offer: offer,
        ListingTitle: item?.projectName,
        ListingSlug: item?.slug,
        clickedBy: clickedBy,
      };
      const jsonData = JSON.stringify(data);
      try {
        const response = await axios.post(
          `${baseURL}/api/ChatApp/CreateConversation`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.data?.result === 1) {
          setSubmitLoader(false);
          setWhatsAppsubmitLoader(false);
          setResponseMessage(response?.data?.message);
          setFullName("");
          setValue("");
          setOffer("");
          setEmail("");
          if (clickedBy === "WhatsApp") {
            window.open(
              "https://api.whatsapp.com/send?phone=16476490400&text=Hello, I am interested in " +
                item?.projectName +
                "",
              "_blank"
            );
          }
        } else {
          setWhatsAppsubmitLoader(false);
          setSubmitLoader(false);
          toast.error(response?.data?.error[0]);
        }
      } catch (error) {
        setSubmitLoader(false);
        setWhatsAppsubmitLoader(false);
        toast.error("Message not submitted");
      }
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  //Pdf Data Work
  useEffect(() => {
    dispatch(removePdfData());
    dispatch(addPdfData(item));
    // eslint-disable-next-line
  }, [item]);

  //For Floor Plan Image
  const floorPlanData = item?.assignmentMedias?.filter(
    (obj) => obj?.mediaCategory === "Floor Plan"
  );

  //For Media except Floor Plan Image
  const MediaCategoryData = item?.assignmentMedias?.filter(
    (obj) => obj?.mediaCategory !== "Floor Plan"
  );

  //for Download the Image
  const downloadImage = async () => {
    const imageId = floorPlanData[0]?.id;

    try {
      const response = await axios.get(
        `${baseURL}/api/data/ImageInByte/${imageId}`
      );
      const base64Data = response?.data?.data;
      if (base64Data) {
        const binaryData = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        ).buffer;
        const imageData = new Blob([binaryData], { type: "image/jpeg" });
        saveAs(imageData, `${item?.projectName} Floor-Plan.jpeg`);
      }
    } catch (error) {
      toast.error("Error downloading image");
    }
  };

  //bed Value Work
  const [bedValue, setBedValue] = useState();

  useEffect(() => {
    if (item?.bed === "0B") {
      setBedValue("Studio");
    } else if (item?.bed === "1B") {
      setBedValue("1 Bed");
    } else if (item?.bed === "1B+D") {
      setBedValue("1 Bed + D");
    } else if (item?.bed === "2B") {
      setBedValue("2 Bed");
    } else if (item?.bed === "2B+D") {
      setBedValue("2 Bed + D");
    } else if (item?.bed === "3B") {
      setBedValue("3 Bed");
    } else if (item?.bed === "3B+D") {
      setBedValue("3 Bed+D");
    } else if (item?.bed === "4B") {
      setBedValue("4 Bed");
    } else if (item?.bed === "4B+D") {
      setBedValue("4 Bed+D");
    } else if (item?.bed === "5B") {
      setBedValue("5 Bed");
    } else if (item?.bed === "5B+D") {
      setBedValue("5 Bed+D");
    } else if (item?.bed === "6B") {
      setBedValue("6 Bed");
    } else if (item?.bed === "6B+D") {
      setBedValue("6 Bed+D");
    }
    // eslint-disable-next-line
  }, []);

  //Mortgage work
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [formatedPurchasePrice, setFormatedPurhasePrice] = useState("");

  const formatPurchaseValue = (value) => {
    return value ? parseFloat(value).toLocaleString("en-US") : "";
  };

  useEffect(() => {
    setPurchasePrice(nowAssignmentPrice);
    setPurchasePricePdf(nowAssignmentPrice);
    const initialValue = nowAssignmentPrice;
    setFormatedPurhasePrice(formatPurchaseValue(initialValue));
    // eslint-disable-next-line
  }, [item?.assignmentPrice]);

  const handlePurchasePriceChange = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/[^0-9.]/g, "");
    const formattedValue = formatPurchaseValue(numericValue);
    setFormatedPurhasePrice(formattedValue);
    setPurchasePrice(numericValue);
    setPurchasePricePdf(numericValue);
  };
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  function formatClosingDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  //Calculation Work
  const totalToSeller = Math.ceil(
    purchasePrice - item?.originalPrice + item?.depositToBuilder
  );
  const depositPercentage = Math.ceil(purchasePrice * 0.05);
  const BalancePayment = Math.ceil(totalToSeller - depositPercentage);

  const ChipStyle = {
    background: "rgb(108, 53, 163)",
    color: "white",
    borderRadius: "5px",
    // fontSize: "20px",
    // padding: "1rem"
  };

  //Seo Work
  const metaseosTags = item?.assignmentSeos[0]?.metaSeos;
  const mainTitleObject = metaseosTags?.find(
    (metaSeo) => metaSeo?.seoTag?.typevalue === "main_Title"
  );
  const mainTitle = mainTitleObject
    ? mainTitleObject?.content
    : "AssignmentSold.ca";
  const titleObject = metaseosTags?.find(
    (metaSeo) => metaSeo?.seoTag?.typevalue === "title"
  );
  const title = titleObject ? titleObject?.content : "AssignmentSold.ca";
  const descriptionObject = metaseosTags?.find(
    (metaSeo) => metaSeo?.seoTag?.typevalue === "description"
  );
  const description = descriptionObject
    ? descriptionObject?.content
    : "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.";
  const KeywordObject = metaseosTags?.find(
    (metaSeo) => metaSeo?.seoTag?.typevalue === "keywords"
  );
  const keywords = KeywordObject
    ? KeywordObject?.content
    : "assignment sold, assignment sale, condo, townhouse";

  return (
    <>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={currentUrl} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={
            MediaCategoryData?.length === 0
              ? "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
              : `${baseURL}/${MediaCategoryData[0]?.fileUrl?.replace(
                  /\\/g,
                  "/"
                )}`
          }
        />
        <meta property="og:url" content={currentUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={
            MediaCategoryData?.length === 0
              ? "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
              : `${baseURL}/${MediaCategoryData[0]?.fileUrl?.replace(
                  /\\/g,
                  "/"
                )}`
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:url" content={currentUrl} />
      </Helmet>

      <Box
        className="DrawerBox"
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 650,
          overflowX: "hidden",
        }}
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        //onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className=" mb-4 p-0  font">
          <div className="m-v-d-text  m-v-item-to-show">
            <div className="row p-2 position-relative">
              <div className="col-lg-1 ps-2 col-1">
                {" "}
                <CloseIcon onClick={toggleDrawer("right", false)} />
              </div>
              <div className="col-lg-11 p-0 details__Text col-11">
                <span className="pl__i">Assignment Details</span>
              </div>
              <div
                className="m-v-item-to-show mbl_heart_positon"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 112,
                  zIndex: 99,
                  width: "fit-content",
                }}
              >
                <div className="p-2 aaligner mbl_heartIconcontainer share_drawerIcon">
                  {cardIde.includes(item?.uId) ? (
                    <AiFillHeart
                      className="heartIcon mbl_heartIcon outlined"
                      onClick={() => handleHeartClick(item?.uId)}
                    />
                  ) : (
                    <AiOutlineHeart
                      className="heartIcon filled"
                      onClick={() => handleHeartClick(item?.uId)}
                    />
                  )}
                </div>
              </div>
              <div
                className="m-v-item-to-show mbl_share_positon"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 62,
                  zIndex: 99,
                  width: "fit-content",
                }}
              >
                <div className="aaligner mbl_ShareIcon share_drawerIcon">
                  <ShareFeature linkSlug={item?.slug} />
                </div>
              </div>
            </div>
          </div>
          <div className="m-v-drawerdesign m-v-item-to-show">
            <div className=" d-flex justify-content-center">
              <div className=" m-v-drawerimages">
                <Carousel className="DrawerCarousel">
                  {MediaCategoryData?.length === 0 ? (
                    <Carousel.Item>
                      <div className="carousel-image-container">
                        <img
                          className="d-block w-100 h-100 object-fit-cover"
                          src={require("../../../images/drawerNo-image.png")}
                          alt={item?.projectName}
                          loading="lazy"
                        />
                      </div>
                      <Carousel.Caption>
                        <p>1/1</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ) : (
                    MediaCategoryData.map((media, index) => (
                      <Carousel.Item key={index}>
                        <div className="carousel-image-container">
                          <img
                            className="d-block w-100 car0__Image object-fit-cover"
                            src={`${baseURL}/${MediaCategoryData[index]?.fileUrl}`}
                            alt={item?.projectName}
                            loading="lazy"
                          />
                        </div>
                        <Carousel.Caption>
                          <p>{`${index + 1}/${MediaCategoryData?.length}`}</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    ))
                  )}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="m-v-carousel-hide" style={{ position: "relative" }}>
            <Carousel className="DrawerCarousel">
              {MediaCategoryData?.length === 0 ? (
                <Carousel.Item>
                  <div className="carousel-image-container">
                    <img
                      className="d-block w-100 h-100 object-fit-cover"
                      src={require("../../../images/drawerNo-image.png")}
                      alt={item?.projectName}
                      loading="lazy"
                    />
                  </div>
                  <Carousel.Caption>
                    <p>1/1</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ) : (
                MediaCategoryData.map((media, index) => (
                  <Carousel.Item key={index}>
                    <div className="carousel-image-container">
                      <img
                        className="d-block w-100 h-100 object-fit-cover"
                        src={`${baseURL}/${MediaCategoryData[index]?.fileUrl}`}
                        alt={item?.projectName}
                        loading="lazy"
                      />
                    </div>
                    <Carousel.Caption>
                      <p>{`${index + 1}/${MediaCategoryData?.length}`}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))
              )}
            </Carousel>
            <div
              className="mv-ic-hid"
              style={{ position: "absolute", top: 6, right: 112, zIndex: 99 }}
            >
              <div className="p-2 aaligner share_drawerIcon">
                {cardIde.includes(item?.uId) ? (
                  <AiFillHeart
                    className="heartIcon outlined"
                    onClick={() => handleHeartClick(item?.uId)}
                  />
                ) : (
                  <AiOutlineHeart
                    className="heartIcon filled"
                    onClick={() => handleHeartClick(item?.uId)}
                  />
                )}
              </div>
            </div>
            <div
              className="mv-ic-hid"
              style={{ position: "absolute", top: 6, right: 62, zIndex: 99 }}
            >
              <div className="aaligner share_drawerIcon">
                <ShareFeature linkSlug={item?.slug} />
              </div>
            </div>
            <div
              className="mv-ic-hid"
              style={{ position: "absolute", top: 6, left: 6, zIndex: 99 }}
            >
              <button
                onClick={toggleDrawer("right", false)}
                className="btn btndecors"
              >
                <ClearIcon />
              </button>
            </div>

            {/* <div style={{ position: "absolute", top: 390, left: 6 , backgroundColor:"#FFFFFF"}}></div> */}
          </div>
          <div className="container  settinger mt-3 ">
            <div className="shadow cardonpic cardajustments ">
              <div className="d-flex justify-content-between">
                {item?.assignmentPrice && (
                  <>
                    <div className="justifyung">
                      {item?.assignmentPrice && (
                        <span>
                          <h4 className="textgreen ">
                            <strong>
                              ${nowAssignmentPrice?.toLocaleString("en-US")}
                            </strong>
                            <span className="dynamicFont14 mx-2 text-dark fw-bold">{`$${NowSqftPrice.toLocaleString(
                              "en-US"
                            )}/SQft`}</span>
                          </h4>
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div className="justifications m-v-item-for-hide font13">
                  <DrawerPdf
                    downVlauePdf={downVlauePdf}
                    yearsVlauePdf={yearsVlauePdf}
                    landTaxPdf={landTaxPdf}
                    totalMortgagePdf={totalMortgagePdf}
                    interstPdf={interstPdf}
                    monthlyMortgagePdf={monthlyMortgagePdf}
                    purchasePricePdf={purchasePricePdf}
                  />{" "}
                  <a href="#ContactSeller">
                    <button className="Borderbutton m-v-item-for-hide butnmargn font13">
                      {" "}
                      <RecentActorsIcon /> Contact Seller{" "}
                    </button>
                  </a>
                  {/* <strong className="m-v-item-to-show">
                  {formatDate(item?.created)}
                </strong> */}
                </div>
              </div>
              {item?.assignmentPrices.length > 0 ? (
                <div className="now_main d-flex flex-col">
                  {" "}
                  <p className="card_price2 was_mb">
                    {/* <span className="Was_hide">Was</span>{" "} */}
                    <span className=" fs-6 cutwas_price">
                      <> ${item?.assignmentPrice?.toLocaleString("en-US")}</>
                    </span>
                    <span className="waspricee_sqft dynamicFont14 mx-2">{`$${WasSqftPrice?.toLocaleString(
                      "en-US"
                    )}/SQft`}</span>
                  </p>
                </div>
              ) : null}

              <div className="d-flex w-100 position-relative">
                <div className="w-50">
                  {item?.type && item?.type !== "NULL" && (
                    <>
                      <div className="row">
                        <div className="col-12 m-1 ">
                          <Chip
                            className="font"
                            label={item.type}
                            style={ChipStyle}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="w-50 ms-auto  pe-2 dynamic_margin_date end-0">
                  {" "}
                  <p className="m-0 text-end dynamicFont14 text-nowrap fw-bold">
                    <strong className=" ">Posted:</strong>{" "}
                    <span className=" text-black">
                      {" "}
                      <strong>{formatDate(item?.created)}</strong>
                    </span>
                  </p>
                  <p className="fw-bold text-end dynamicFont14 text-nowrap  m-0">
                    <strong className="text-danger">Closing:</strong>
                    <span>
                      {" "}
                      <strong className="text-black">
                        {formatClosingDate(item?.occupanycDate)}
                      </strong>
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-100">
                <div className="w-50">
                  <p className="m-1 m-v-bold">
                    <strong className="fw-bold">{item?.projectName}</strong>
                  </p>
                </div>
              </div>

              <div className={item?.type ? "row" : "row mt-4"}>
                <div className="col-12">
                  <p className="m-1">
                    {" "}
                    <>
                      {" "}
                      <span className="paading2 mobile_padding_Top0">
                        <BedIcon style={{ color: "#0253A0" }} />{" "}
                        {item?.bed ? (
                          <strong> {bedValue}</strong>
                        ) : (
                          <strong>Bed</strong>
                        )}
                      </span>{" "}
                    </>
                    -{" "}
                    <>
                      {" "}
                      <span className="paading mobile_padding_Top0">
                        <BathtubIcon style={{ color: "#0253A0" }} />{" "}
                        {item?.bath ? (
                          <strong>{item?.bath} Bath </strong>
                        ) : (
                          <strong>Bath</strong>
                        )}
                      </span>{" "}
                      -{" "}
                    </>
                    <>
                      <span className="paading mobile_padding_Top0">
                        <FaVectorSquare style={{ color: "#0253A0" }} />{" "}
                        {item?.interiorSize ? (
                          <strong>
                            {item?.interiorSize?.toLocaleString("en-US")} SQft{" "}
                          </strong>
                        ) : (
                          <strong>SQft</strong>
                        )}
                      </span>{" "}
                      -{" "}
                    </>
                    {item?.parking > 0 ? (
                      <>
                        <span className="paading mobile_padding_Top0">
                          <FaParking style={{ color: "#0253A0" }} />{" "}
                          <strong>{item?.parking} </strong>{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="paading">
                          <FaParking style={{ color: "#0253A0" }} />{" "}
                          <strong>0 </strong>{" "}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="row pt-2">
                {item?.address && (
                  <>
                    <div className="col-lg-12">
                      <LocationOnIcon
                        className="turningred"
                        style={{ color: "orange" }}
                      />{" "}
                      <span className="m-1  fs-6 ">{`${item?.address}${
                        item?.city ? `, ${item.city}` : ""
                      }`}</span>
                    </div>
                  </>
                )}
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <p className="m-1">
                    {" "}
                    {item?.developer && (
                      <>
                        {" "}
                        <span className="paading2">
                          Developer :{" "}
                          <strong className="fw-bold font14">
                            {item?.developer}
                          </strong>
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <p className="m-1">
                    {" "}
                    {item?.highlight && (
                      <>
                        <span className="font font-italic">
                          "{item?.highlight}"
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="d-flex m-v-pt-1 justify-content-center">
                <div className="m-v-item-to-show cursor-default">
                  <DrawerPdf
                    downVlauePdf={downVlauePdf}
                    yearsVlauePdf={yearsVlauePdf}
                    landTaxPdf={landTaxPdf}
                    totalMortgagePdf={totalMortgagePdf}
                    interstPdf={interstPdf}
                    monthlyMortgagePdf={monthlyMortgagePdf}
                    purchasePricePdf={purchasePricePdf}
                  />
                </div>{" "}
                <a href="#ContactSeller">
                  <button className="Borderbutton m-v-item-to-show butnmargn font13">
                    {" "}
                    <RecentActorsIcon className="m-v-icon-class  font13" />{" "}
                    Contact Seller{" "}
                  </button>
                </a>
              </div>
            </div>
            {/* Second Card in the Search Popup */}
            <div className="row  mt-3 shadow cardajustments  py-3 px-1 ">
              <div className="col-sm-6 col-12 justifyung">
                <h4>
                  {/* Not Found */}
                  <strong> Floor Plan </strong>{" "}
                </h4>
              </div>
              {floorPlanData && floorPlanData.length > 0 && (
                <div className="col-sm-6 col-12 justifications">
                  <DownloadIcon style={{ cursor: "pointer" }} />
                  <span onClick={downloadImage} style={{ cursor: "pointer" }}>
                    {" "}
                    Download Floor Plan
                  </span>
                </div>
              )}

              <>
                <div className=" col-12 ">
                  <button className="textgreen btn btan">
                    <BedIcon style={{ color: "#0253A0" }} />{" "}
                    {item?.bed ? (
                      <strong className="px-1">{bedValue}</strong>
                    ) : (
                      <strong className="px-1">Bed</strong>
                    )}
                  </button>
                </div>
              </>

              <div className="row">
                <div className="col-12">
                  <p className="m-1">
                    {item?.price && (
                      <>
                        <span className="paading2">
                          {" "}
                          <strong className="textgreen">
                            {" "}
                            ${item?.price?.toLocaleString("en-US")}
                          </strong>
                        </span>{" "}
                        -{" "}
                      </>
                    )}
                    <>
                      <span className="paading">
                        <BathtubIcon style={{ color: "#0253A0" }} />{" "}
                        {item?.bath ? (
                          <strong>{item?.bath} Bath </strong>
                        ) : (
                          <strong>Bath </strong>
                        )}
                      </span>{" "}
                      -{" "}
                    </>

                    <span className="paading">
                      {/* {" Not Found"}  */}
                      <strong>
                        <FaVectorSquare style={{ color: "#0253A0" }} />{" "}
                        {item?.interiorSize ? (
                          <>
                            {item?.interiorSize?.toLocaleString("en-US")}
                            FT&sup2;{" "}
                          </>
                        ) : (
                          <span>Ft&sup2;</span>
                        )}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
              </div>
              {floorPlanData && floorPlanData.length > 0 && (
                <div style={{ height: "80vh" }}>
                  <img
                    className="d-block w-100 h-100"
                    src={`${baseURL}/${floorPlanData[0]?.fileUrl}`}
                    alt={`${item?.projectName} floor plan`}
                    loading="lazy"
                  />
                </div>
              )}
            </div>
            {/* Property Details */}
            <div className="row mt-5 shadow cardajustments">
              <div className="cardlinebordder px-3 py-2">
                <div className="border_Section ">
                  <div className="col-lg-6 col-12 p-2 heading__grey_backgroud justifyung w-100">
                    <h4>
                      <strong className="m-v-blue-color m-v-heading">
                        {" "}
                        Property Details{" "}
                      </strong>{" "}
                    </h4>
                  </div>
                  <div className="d-flex ps-2 w-100 bottompad mt-4 webFont14">
                    <div className="m-v-table-head w-50 flex-wrap ">
                      Assignment ID :{" "}
                    </div>
                    <div
                      className={
                        isMobileView
                          ? "m-v-table-head2"
                          : "m-v-table-head w-50 flex-wrap text-start "
                      }
                    >
                      <strong>{item?.id.toLocaleString("en-US")}</strong>
                    </div>
                  </div>
                  <div className="d-flex ps-2 w-100 bottompad webFont14">
                    <div className="m-v-table-head aligncenter w-50  ">
                      Address :{" "}
                    </div>
                    <div className="m-v-table-head w-50 flex-wrap text-start   ">
                      <strong>{item?.address}</strong>
                    </div>
                  </div>

                  {item?.city && (
                    <>
                      <div className="d-flex ps-2 justify-content-between webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">City :</div>
                        <div className="m-v-table-head w-50">
                          <strong>{item?.city}</strong>
                        </div>
                      </div>
                    </>
                  )}

                  {item?.postalcode && (
                    <>
                      <div className="d-flex ps-2 justify-content-between webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">
                          Postal Code / Zip :
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            {item?.postalcode === "NULL"
                              ? "N/A"
                              : item?.postalcode}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}

                  {item?.mainIntersection && (
                    <>
                      <div className="d-flex ps-2 justify-content-between webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">
                          Main Intersection :
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            {item?.mainIntersection === "NULL"
                              ? "N/A"
                              : item?.mainIntersection}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Assignment Details */}
              <div className="cardlinebordder px-3 y-1">
                <div className="border_Section ">
                  <div className="m-v-table-head heading__grey_backgroud p-2 justifyung mb-4">
                    <h4>
                      <strong className="m-v-heading m-v-blue-color">
                        {" "}
                        Assignment Details{" "}
                      </strong>{" "}
                    </h4>
                  </div>
                  {item?.occupanycDate && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Occupancy Date :
                        </div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <strong>
                            <InsertInvitationIcon
                              style={{ color: "#0253A0", fontSize: "22px" }}
                            />{" "}
                            {formatDate(item?.occupanycDate)}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}
                  {item?.style && (
                    <div className="d-flex ps-2   webFont14 w-100 bottompad">
                      <div className="m-v-table-head w-50">Style:</div>
                      <div
                        className={
                          isMobileView
                            ? "m-v-table-head2 w-50"
                            : "m-v-table-head w-50"
                        }
                      >
                        <strong>
                          <CottageIcon
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          {item?.style ?? "N/A"}
                        </strong>
                      </div>
                    </div>
                  )}
                  {item?.interiorSize ? (
                    <>
                      {item?.interiorSize && (
                        <>
                          <div className="d-flex ps-2   webFont14 w-100 bottompad">
                            <div className="m-v-table-head w-50 aligncenter">
                              Interior Size :{" "}
                            </div>
                            <div
                              className={
                                isMobileView
                                  ? "m-v-table-head w-50"
                                  : "m-v-table-head w-50"
                              }
                            >
                              <strong>
                                <FaVectorSquare
                                  style={{ color: "#0253A0", fontSize: "18px" }}
                                />{" "}
                                {item?.interiorSize}
                                -Sqft
                              </strong>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                  {item?.exteriorSize ? (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Exterior Size :{" "}
                        </div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <strong>
                            {/* <img
                              src={BalconyIcon}
                              alt="Locker Icon"
                              style={{ width: "20px", height: "20px" }}
                            />{" "} */}
                            <MdOutlineBalcony
                              style={{ color: "#0253A0", fontSize: "20px" }}
                            />{" "}
                            {item?.exteriorSize}-Sqft
                          </strong>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="d-flex ps-2   webFont14 w-100 bottompad">
                    {item?.floorRange ? (
                      <>
                        <div className="m-v-table-head w-50">Floor-Range:</div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <strong>
                            <EscalatorIcon
                              style={{ color: "#0253A0", fontSize: "20px" }}
                            />{" "}
                            {item?.floorRange}
                          </strong>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="d-flex ps-2   webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50">Exposure:</div>
                    <div
                      className={
                        isMobileView
                          ? "m-v-table-head w-50"
                          : "m-v-table-head w-50"
                      }
                    >
                      <BsSunFill
                        style={{ color: "#f6941c", fontSize: "22px" }}
                      />{" "}
                      <strong>{item?.exposure}</strong>
                    </div>
                  </div>
                  {/* <div className="row bottompad">
                  Not Found
                  <div className="col-lg-6 col-12">Balcony:</div>
                  <div className="col-lg-6 col-12">
                    <strong>Open</strong>
                  </div>
                </div> */}
                  {item?.parking ? (
                    <>
                      <div className="d-flex ps-2   webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">Parking :</div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head w-50"
                              : "m-v-table-head w-50"
                          }
                        >
                          <FaParking
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{item?.parking}</strong>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {item?.parkingType && (
                    <>
                      <div className="d-flex ps-2   webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">Parking Type:</div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <FaParking
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{item?.parkingType}</strong>
                        </div>
                      </div>
                    </>
                  )}

                  {item?.garage && (
                    <div className="d-flex ps-2   webFont14 w-100 bottompad">
                      <div className="m-v-table-head w-50">Garage:</div>
                      <div
                        className={
                          isMobileView
                            ? "m-v-table-head w-50"
                            : "m-v-table-head w-50"
                        }
                      >
                        <GarageIcon style={{ color: "#0253A0" }} />{" "}
                        <strong>{item?.garage}</strong>
                      </div>
                    </div>
                  )}

                  {item?.locker ? (
                    <>
                      <div className="d-flex ps-2   webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">Locker:</div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head w-50"
                              : "m-v-table-head w-50"
                          }
                        >
                          {/* <img
                            src={LockerIcon}
                            style={{ width: "20px", height: "20px" }}
                            alt=""
                          />{" "} */}
                          <GiLockers
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{item?.locker}</strong>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {item?.lotWidth && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">Lot Width :</div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <OpenWithIcon
                            style={{ color: "#0253A0", fontSize: "20px" }}
                          />{" "}
                          <strong>{item?.lotWidth}-feet</strong>
                        </div>
                      </div>
                    </>
                  )}

                  {item?.colorselectionchosen && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Color Selection Chosen :
                        </div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <strong>
                            {item?.colorselectionchosen === false
                              ? "No"
                              : "Yes"}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}

                  {item?.leaseDuringOccupancy && (
                    <>
                      <div className="ps-2 d-flex   webFont14 w-100  bottompad">
                        <div className="m-v-table-head w-50">
                          Lease During Occupancy :
                        </div>
                        <div
                          className={
                            isMobileView
                              ? "m-v-table-head2"
                              : "m-v-table-head w-50"
                          }
                        >
                          <strong>
                            {item?.leaseDuringOccupancy === false
                              ? "No"
                              : "Yes"}
                          </strong>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="d-flex   webFont14 w-100 bottompad">
                  <div className="m-v-table-head w-50">Highlight:</div>
                  <div
                    className={
                      isMobileView ? "m-v-table-head23" : "m-v-table-head w-50"
                    }
                  >
                    <strong>{item?.highlight}</strong>
                  </div>
                </div> */}
                </div>
              </div>
              {/* Map */}

              <div className="cardlinebordder px-3">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  {item?.latitude && item?.longitude ? (
                    <MapandListingDrawerMap
                      latitude={item?.latitude}
                      longitude={item?.longitude}
                    />
                  ) : null}
                </div>
              </div>
              {/* Pricing Details */}
              <div className="cardlinebordder px-3 py-1">
                <div className="border_Section">
                  <div className=" heading__grey_backgroud justifyung p-2">
                    <h4 className="m-0">
                      <strong className="m-v-blue-color m-v-heading ">
                        {" "}
                        Pricing Details{" "}
                      </strong>{" "}
                    </h4>
                  </div>
                  <div className="ps-2 d-flex justify-content-between  webFont14 w-100 mt-4">
                    <div className="m-v-table-head w-50">
                      Seller Asking price:
                    </div>
                    <div
                      className={
                        isMobileView
                          ? "m-v-table-head w-50"
                          : "m-v-table-head w-50"
                      }
                    >
                      <strong>
                        ${" "}
                        {item?.assignmentPrice?.toLocaleString("en-US") ??
                          "N/A"}
                      </strong>
                    </div>
                  </div>

                  <div className="ps-2">
                    {item?.originalPrice && (
                      <>
                        <div className="d-flex justify-content-between webFont14 mt-3 bottompad">
                          <div className="m-v-table-head w-50">
                            Original Purchase Price :
                          </div>
                          <div className="m-v-table-head w-50">
                            <strong>
                              $ {item?.originalPrice?.toLocaleString("en-US")}
                            </strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="ps-2">
                    {item?.depositToBuilder && (
                      <>
                        <div className="d-flex justify-content-between webFont14 bottompad">
                          <div className="m-v-table-head w-50 aligncenter">
                            Original Purchase Deposit :
                          </div>
                          <div className="m-v-table-head w-50">
                            <strong>
                              ${" "}
                              {item?.depositToBuilder?.toLocaleString("en-US")}
                            </strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className=" ps-2 d-flex justify-content-between webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50 aligncenter">
                      {/* Not Found */}
                      Bidding Price :
                    </div>
                    <div className="m-v-table-head w-50 pe-2">
                      <input
                        type="text"
                        value={
                          formatedPurchasePrice === ""
                            ? ""
                            : `$${formatedPurchasePrice}`
                        }
                        onChange={handlePurchasePriceChange}
                        className="mortgage__input "
                      />
                    </div>
                  </div>
                  <div className="ps-2 d-flex justify-content-between webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50">
                      Total payment by buyer to seller
                    </div>
                    <div className="m-v-table-head w-50">
                      <strong>
                        $ {totalToSeller?.toLocaleString("en-US")}
                      </strong>
                    </div>
                  </div>
                  <div className="ps-2 d-flex justify-content-between webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50">
                      5% Deposit upon acceptance of assignment agreement
                    </div>
                    <div className="m-v-table-head w-50">
                      <strong>
                        $ {depositPercentage?.toLocaleString("en-US")}
                      </strong>
                    </div>
                  </div>
                  <div className=" ps-2 d-flex justify-content-between webFont14 w-100 bottompad">
                    <div className="m-v-table-head  w-50 ">
                      Balance of the payment for this assignment agreement
                    </div>
                    <div className="m-v-table-head w-50">
                      <strong>
                        $ {BalancePayment?.toLocaleString("en-US")}
                      </strong>
                    </div>
                  </div>
                  <div className="ps-2 d-flex justify-content-between webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50">Occupancy-Due</div>
                    <div className="m-v-table-head w-50">
                      <strong>
                        $ {item?.occupancyDue?.toLocaleString("en-US") ?? "N/A"}
                      </strong>
                    </div>
                  </div>

                  <div className=" ps-2 d-flex justify-content-between webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50">Maintenance Fee</div>
                    <div className="m-v-table-head w-50">
                      <strong>
                        ${" "}
                        {item?.maintainenceFee?.toLocaleString("en-US") ??
                          "N/A"}
                      </strong>
                    </div>
                  </div>
                  <div className="ps-2">
                    {item?.upgradeDepositPaid && (
                      <>
                        {" "}
                        <div className="d-flex  webFont14 w-100 bottompad">
                          <div className="m-v-table-head w-50">
                            Upgrade Deposit Paid :
                          </div>
                          <div
                            className={
                              isMobileView
                                ? "m-v-table-head w-50"
                                : "m-v-table-head w-50"
                            }
                          >
                            <strong>
                              ${" "}
                              {item?.upgradeDepositPaid?.toLocaleString(
                                "en-US"
                              )}
                            </strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="ps-2">
                    {item?.upgradeBalance && (
                      <>
                        {" "}
                        <div className="d-flex  webFont14 w-100 bottompad">
                          <div className="m-v-table-head w-50">
                            Upgrade Balance(Text) :
                          </div>
                          <div
                            className={
                              isMobileView
                                ? "m-v-table-head2 w-50"
                                : "m-v-table-head w-50"
                            }
                          >
                            <strong>
                              {item?.upgradeBalance?.toLocaleString("en-US")}
                            </strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="ps-2">
                    {item?.upgradeCharges ? (
                      <>
                        {" "}
                        <div className="d-flex  webFont14 w-100 bottompad">
                          <div className="m-v-table-head w-50">
                            Upgrade Charges :
                          </div>
                          <div
                            className={
                              isMobileView
                                ? "m-v-table-head w-50"
                                : "m-v-table-head w-50"
                            }
                          >
                            <strong>
                              {item?.upgradeCharges?.toLocaleString("en-US")}
                            </strong>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="ps-2 d-flex   webFont14 w-100 bottompad">
                    <div className="m-v-table-head w-50">
                      Development / Levy Charges :
                    </div>
                    <div className="m-v-table-head w-50">
                      <strong>
                        $ {item?.levyFee?.toLocaleString("en-US") ?? "N/A"}
                      </strong>
                    </div>
                  </div>
                  <div className="ps-2">
                    {item?.commission && (
                      <div className="d-flex justify-content-between webFont14 w-100 bottompad">
                        <div className="m-v-table-head w-50">
                          Cooperating Commision% :
                        </div>
                        <div className="m-v-table-head w-50">
                          <strong>
                            {item?.commission === "0" || item?.commission === 0
                              ? "0"
                              : item?.commission
                              ? `${item?.commission.toLocaleString("en-US")} %`
                              : "0"}
                          </strong>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Floor Plan */}

            {/* Property Details */}
            <div className="row py-3 px-2 mt-5 shadow cardajustments ">
              <div className="col-12 justifyung">
                <h5>
                  {/* Not Found */}
                  <strong> Mortgage Calculator</strong>
                </h5>
              </div>

              {/* Mortgage work */}
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader size={0} />
                </div>
              ) : (
                <MortgageCalculator
                  handlePurchasePriceChange={handlePurchasePriceChange}
                  purchasePrice={purchasePrice}
                  formatedPurchasePrice={formatedPurchasePrice}
                  region={item?.region}
                  setDownValuePdf={setDownValuePdf}
                  setyearsValuePdf={setyearsValuePdf}
                  setLandTaxPdf={setLandTaxPdf}
                  setTotalMortgagePdf={setTotalMortgagePdf}
                  setInterestPdf={setInterestPdf}
                  setMonthlyMortgagePdf={setMonthlyMortgagePdf}
                />
              )}
            </div>
            <div className="row mt-5 shadow cardajustments">
              <div className="p-3">
                <div className="col-12 justifyung " id="ContactSeller">
                  <h5>
                    <strong>Contact Seller</strong>
                  </h5>
                </div>
                <p className="font fieldhead m-0">
                  <strong> First Name</strong>
                </p>
                <TextField
                  size="small"
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    marginBottom: "20px",
                  }}
                  name="fullName"
                  id="outlined-basic"
                  label={<span className="font">Enter You First Name</span>}
                  variant="outlined"
                  type="text"
                  value={fullName}
                  onChange={handleNameChange}
                />
                {fullNameError ? (
                  <>
                    <span className="redError"> This Field is Required*</span>
                  </>
                ) : null}
                <p className="font fieldhead m-0">
                  <strong>Email Address</strong>
                </p>
                <TextField
                  size="small"
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    marginBottom: "20px",
                  }}
                  id="outlined-basic"
                  label={<span className="font">Enter Your Email Address</span>}
                  variant="outlined"
                  name="email"
                  type="text"
                  value={email}
                  onChange={handleMailChange}
                />
                {emailError ? (
                  <>
                    <span className="redError"> This Field is Required*</span>
                  </>
                ) : null}

                <p className="font fieldhead m-0">
                  <strong>Phone Number</strong>
                </p>
                <PhoneInput
                  size="small"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="CA"
                  value={value}
                  onChange={handlePhoneInputChange}
                  name="phoneNumber"
                  id="phoneNumber"
                  className="Phone_input"
                  placeholder="Enter Your Phone Number"
                />
                {phoneError ? (
                  <>
                    <span className="redError"> Invalid PhoneNumber*</span>
                  </>
                ) : null}
                <p className="font fieldhead m-0">
                  <strong>Make an Offer</strong>
                </p>
                <TextField
                  size="small"
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    marginBottom: "20px",
                  }}
                  id="outlined-basic "
                  label={<span className="font">Offer</span>}
                  variant="outlined"
                  name="message"
                  value={offer}
                  onChange={handleOfferChange}
                  type="text"
                  multiline
                  rows={6}
                />
                {offerError ? (
                  <span className="redError"> This Field is Required*</span>
                ) : null}

                <div className="row">
                  <div className="col-sm-6 col-5">
                    {submitLoader ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Loader size={50} />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <button
                          type="submit"
                          className="Submitbutton__drawer w-100"
                          onClick={() => {
                            handlePropertySubmit(item, "Message");
                          }}
                        >
                          Message
                        </button>
                      </>
                    )}
                  </div>
                  <div className="col-sm-6 col-7">
                    {whatsAppsubmitLoader ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Loader size={50} />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <button
                          type="submit"
                          className="Submitbutton__drawer d-flex align-items-center justify-content-center w-100"
                          onClick={() => {
                            handlePropertySubmit(item, "WhatsApp");
                          }}
                        >
                          <img
                            src={require("../../../images/whatsapplogo.png")}
                            width={"20px"}
                            alt="whatsapp logo"
                            className="me-1"
                            loading="lazy"
                          />
                          WhatsApp
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-6 col-5">
                    <a
                      href="tel:+16476490400"
                      style={{ width: "100%", textDecoration: "none" }}
                    >
                      <button className="Submitbutton__drawer w-100">
                        Call
                      </button>
                    </a>
                  </div>
                  <div className="col-sm-6 col-7">
                    <NavLink to="/sell">
                      <button className="Submitbutton__drawer w-100">
                        Sell Similar Property
                      </button>
                    </NavLink>
                  </div>
                </div>
                {responseMessage ? (
                  <div className="mt-3">
                    <span className="text-success"> {responseMessage}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default DrawerContent;
