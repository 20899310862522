import React, { useEffect, useState,memo } from "react";
import PieStatisticsChart from "./PieStatisticsChart";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PropertyTypeDistributionIcon } from "../../../SvgIcons/Icons";

const PropertyTypeDistribution = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <div className="w-100">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
        sx={{ background: "#ffff" }}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <PropertyTypeDistributionIcon />}
            <h2 className="property_Details_heading">
              Property type distribution
            </h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Examining property type distribution offers insights into
                housing trends and community needs, aiding in targeted
                development and planning efforts.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Examining property type distribution offers insights into housing
              trends and community needs, aiding in targeted development and
              planning efforts.
            </p>
          )}
          <div className="mt-3">
            <PieStatisticsChart data={PropertyData?.propertyDistribution} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(PropertyTypeDistribution);
