import React, { useState, memo } from "react";
import { AddNoteIcon, EditIcon } from "../../../../SvgIcons/Icons";
import ReviewsRating from "./RatingReviews";
import RatingValueDialog from "../Modals/RatingValueDialog";

const PropertyNotes = ({
  data,
  isModal,
  onClose,
  setIsSignUpModalOpen,
  isUserlogin,
  setSignUpModalHeading,
}) => {
  const [isNoteDown, setIsNoteDown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const ratings = {
    value1: {
      name: "Price",
      value: "0",
    },
    value2: {
      name: "Floor Plans",
      value: "0",
    },
    value3: {
      name: "Estimated Completed time",
      value: "0",
    },
  };

  const handleCancelBtn = () => {
    if (isModal) {
      onClose();
    } else {
      setIsNoteDown(false);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleNoteDown = () => {
    if (isUserlogin) {
      setIsNoteDown(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading(
        "Become a member to access & Write Research Notes."
      );
    }
  };

  const handleEditClick = () => {
    if (isUserlogin) {
      setIsDialogOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading(
        "Become a member to access & Write Research Notes."
      );
    }
  };

  return (
    <>
      <div className="prop_represent_linkStatic">
        <div
          className={`Prop_details_section_box Propert_detailRepresentatorCard Prop_detailsRatingCard mt-3 ${
            isModal && "RatingMobile_modal"
          }`}
        >
          {isModal && (
            <h5 className="Research_noteHeading mb-2">Research Note</h5>
          )}
          <div className="d-flex justify-content-between">
            <p className="m-0 d-flex align-items-center">
              <span className="pe-1">{ratings?.value1?.name}</span>{" "}
              <span style={{ cursor: "pointer" }} onClick={handleEditClick}>
                <EditIcon />
              </span>{" "}
            </p>
            <ReviewsRating isReadOnly={true} value={ratings?.value1?.value} />
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p className="m-0 d-flex align-items-center">
              <span className="pe-1">{ratings?.value2?.name}</span>{" "}
              <span style={{ cursor: "pointer" }} onClick={handleEditClick}>
                <EditIcon />
              </span>{" "}
            </p>
            <ReviewsRating isReadOnly={true} value={ratings?.value2?.value} />
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p className="m-0 d-flex align-items-center">
              <span className="pe-1" style={{ letterSpacing: ".20px" }}>
                {ratings?.value3?.name}
              </span>{" "}
              <span style={{ cursor: "pointer" }} onClick={handleEditClick}>
                <EditIcon />
              </span>{" "}
            </p>
            <ReviewsRating isReadOnly={true} value={ratings?.value3?.value} />
          </div>

          {!(isNoteDown || isModal) && (
            <button
              className="mt-3 propertyDetails_moreInfo fw-600 DetailsLight_bgBtn d-flex justify-content-between align-items-center gap-2"
              onClick={handleNoteDown}
            >
              <span>Research note for this property</span>
              <AddNoteIcon color={"#007BFF"} />
            </button>
          )}

          {(isNoteDown || isModal) && (
            <div className="PropertyDetails_notes mt-3">
              <textarea placeholder="Jot down your research, observations, and findings related to the properties here."></textarea>
              <div className="d-flex align-items-center gap-2 mt-1">
                <button
                  className="propertyDetails_moreInfo PropCancelNotes_btn"
                  onClick={handleCancelBtn}
                >
                  Cancel
                </button>
                <button className="propertyDetails_moreInfo">Save</button>
              </div>
            </div>
          )}
        </div>
        {isDialogOpen && (
          <RatingValueDialog
            open={isDialogOpen}
            handleClose={handleDialogClose}
            isUserlogin={isUserlogin}
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default memo(PropertyNotes);
