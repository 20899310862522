import React, { useEffect, useState,memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PropertyData } from "../PropertData";
import { QueryHelpIcon } from "../../../SvgIcons/Icons";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const WhyBuyProperty = ({ isMobileView }) => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(isMobileView ? false : true);
  const [showMoreState, setShowMoreState] = useState(() =>
    PropertyData?.WhyBuyProperty?.map(() => true)
  );

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const toggleExpand = (index) => {
    setShowMoreState((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <QueryHelpIcon size={"24"} color={"#282828"} />}
            <h2 className="property_Details_heading">Why Buy Mimico</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Get a clear picture—explore the pros, cons, and key reasons why
                this project stands out as a smart investment.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Get a clear picture—explore the pros, cons, and key reasons why
              this project stands out as a smart investment.
            </p>
          )}
          <Box className="mt-3">
            <AppBar
              position="static"
              sx={{
                boxShadow: "none",
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                textColor="inherit"
                sx={{
                  backgroundColor: "#ffff",
                }}
                className="Property_details_tabs"
                elevation={0}
              >
                {PropertyData?.WhyBuyProperty?.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    sx={{
                      fontSize: tab.fontSize,
                      border: "1px solid #CCCCCC",
                      fontWeight: "700",
                      padding: "15px 30px",
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
            {PropertyData?.WhyBuyProperty?.map((tab, index) => {
              const description = tab.content || "";
              const shouldShowButton = description.split(" ").length > 115;
              const displayedText = !showMoreState[index]
                ? description
                : description.split(" ").slice(0, 112).join(" ");

              return (
                <TabPanel
                  key={index}
                  value={value}
                  index={index}
                  className="PropertyDetail_tabPanel"
                >
                  <div className="position-relative mt-3">
                    <div
                      className={
                        showMoreState[index] && shouldShowButton
                          ? "PropDetails_HideDescription"
                          : ""
                      }
                    >
                      <p
                        className={`PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 ${
                          shouldShowButton && !showMoreState[index]
                            ? "mb-0"
                            : ""
                        }`}
                      >
                        {displayedText}
                      </p>
                    </div>
                  </div>
                  {shouldShowButton && showMoreState[index] && (
                    <button
                      onClick={() => toggleExpand(index)}
                      className="Detail_showMoreBtn"
                    >
                      Show all
                    </button>
                  )}
                </TabPanel>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(WhyBuyProperty);
