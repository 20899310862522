import { AiOutlineSearch } from "react-icons/ai";
import { BiFilter } from "react-icons/bi";
import "../../../App.css";
import { TbBuilding } from "react-icons/tb";
import RangeSlider from "../RangeSlider/RangeSlider";
import SearchField from "../SearchField/SearchField";
import HouseIcon from "@mui/icons-material/House";
import MapandListing from "../MapandListing/MapandListing";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Loader from "../MapandListing/Loader";
import "@fontsource/montserrat";
import { FaSort } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import { BiBookmarkPlus } from "react-icons/bi";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField
} from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import MapIcon from '@mui/icons-material/Map'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccordionPanel from '../Accordion/AccordionPanel'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ButtonList from '../DrawerButtonList/ButtonList'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import React, { useState } from 'react'
import SQFTRangeSlider from '../RangeSlider/SQFTRangeSlider'
import RadiusRangeSlider from '../RangeSlider/RadiusRangeSlider'
import BedButtonList from '../DrawerButtonList/BedButtonList'
import BathroomButtons from './SubComponents/BathroomButtons'
import { useEffect, useRef } from 'react'
import baseURL from '../../../config'
import ParkingButtons from './SubComponents/ParkingButtons'
import BedIcon from '@mui/icons-material/Bed'
import jwt from 'jwt-decode'
import BedOutlinedIcon from '@mui/icons-material/BedOutlined'
import { toast } from 'react-toastify'
import Modal from '@mui/material/Modal'
import { Bathtub, LocalParking } from '@mui/icons-material'
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import { AiOutlineBell } from "react-icons/ai";

import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { addData, removeData } from '../../../ReduxStore/Slices/FilterDataSlice'
import {
  addMapData,
  removeMapData
} from '../../../ReduxStore/Slices/MapBoxDataSlice'
// import ResultNotFound from '../ResultNotFound/ResulutNotFound'
import { useLocation } from 'react-router-dom'
import Geolocation from '../../../Components/GeoLocation/Geolocation'
import { SiGooglephotos } from 'react-icons/si'
import CategoriesTab from "../CatgoriesTab/CategoriesTab";

const SearchStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '14px',

  '@media (max-width: 600px)': {
    top: 'auto',
    bottom: 0,
    transform: 'translate(-50%, 0%)',
    width: '100%',
    borderTopLeftRadius: '50px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '50px'
  }
}

export default function HeaderSection({
  categoryLocation,
  setCategoryLocation,
  categoryBType,
  setCategoryBType
}) {

  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const userData = useSelector((state) => state.profileDataSlice[0]?.data);


  const filterDataSlice = useSelector((state) => state.filterDataSlice);
  const totalRecordLength = filterDataSlice?.reduce(
    (acc, subarray) => acc + subarray.length,
    0
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollPage, setScrollPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [scrollLoading, setScrollLoading] = useState(false);
  const [insidePolygon, setInsidePolygon] = useState('');
  const formatNumber = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const { searchData } = location.state || {}
  const pathName = location.pathname
  const [searchQueryUpdate, setSearchQueryUpdate] = useState(false)
  const [searchCategoryUpdate, setSearchCategoryUpdate] = useState(false)
  const [searchOpen, setSearchOpen] = React.useState(false)
  // States to save the Data from the Search input Field
  const [searchValue, setSearchValue] = useState('') // State to store the search value
  const [showListing, setShowListing] = useState(window.innerWidth <= 800 ? true : false)
  const [MixedListing, setMixedListing] = useState(window.innerWidth <= 800 ? false : true)
  const [MapListing, setMapListing] = useState(false)
  const [centerPopUP, setCenterPopUp] = useState(false)
  const [activeButton, setActiveButton] = useState('mixed')
  const [newInputval, setNewInputVal] = useState('')
  const [searchResDes, setSearchResDes] = useState('')
  const [searchResTitle, setSearchResTitle] = useState('')
  const [toggleIcon, setToggleIcon] = useState(true)
  const [mobileSearch, setMobileSearch] = useState(false)
  const [saveSeachShow, setSaveSeachShow] = useState(false)
  const [sortingHeading, setSortingHeading] = useState('Newest First')

  const handleSearchOpen = () => {
    setSearchOpen(true)
    setSaveSearchName(searchValue)
  }

  const handleSearchClose = () => {
    setSearchOpen(false)
    var modal = document.querySelector('.mobile-savedSearch-container')
    modal.classList.add('mobile-savedSearch-container-closing')

    // Optional: remove the modal from view after the animation completes
    setTimeout(function () {
      modal.style.display = 'none'
      modal.classList.remove('mobile-savedSearch-container-closing')
    }, 500) // 500ms = length of animation
    setSaveSearchName('')
  }
  // eslint-disable-next-line
  const [apilength, setApiLength] = useState('')
  // Bathroom Buttons State
  const [selectedBathButtons, setSelectedBathButtons] = useState([])
  // Building Type State
  const [selectedValues, setSelectedValues] = React.useState([])
  // Beds States
  const [selectedBedValues, setSelectedBedValues] = React.useState([])
  //  City States
  const [selectedCityValues, setSelectedCityValues] = React.useState([])
  // Status States
  const [selectedStatusValues, setSelectedStatusValues] = React.useState('')
  const [selectedParkingValue, setSelectedParkingValue] = React.useState('')
  const [activeButtons, setActiveButtons] = useState([])
  const [activeBathButtons, setActiveBathButtons] = useState([])
  const [searchCheck, setSearchCheck] = useState([])
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800)
  const [isLoading, setIsLoading] = useState(true)
  const [filterLoading, setFilterLoading] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  // Data for Api Filteration
  const [ApiFilter, setApiFilter] = useState([])
  const [saveSearchName, setSaveSearchName] = useState('')
  // const userDataSlice = useSelector((state) => state.userProfileDataSlice);
  // const userData = userDataSlice;
  // const heartRating = useSelector((state) => state.heartDataSlice);
  // const profileData = useSelector(state => state.profileDataSlice)
  // const userImage = userData[0]?.imageUrl;
  const [cityinAcc, setCityinAcc] = useState([])
  const handleSearch = value => {
    setSearchValue(value)
    setSaveSearchName(value)
  }
  // Search States End
  // eslint-disable-next-line
  const [filteredDataLength, setFilteredDataLength] = useState(0)
  const handleFilteredData = length => {
    setFilteredDataLength(length)
  }
  const [state, setState] = React.useState({
    top: false,
    bottom: false,
    right: false
  })
  const handleSelectedBathButtons = buttons => {
    setSelectedBathButtons(buttons)
  }
  const [selectedParkingButtons, setSelectedParkingButtons] = useState([])
  const handleSelectedParkingButtons = button => {
    setSelectedParkingButtons(button)
  }

  const handleSelectionChange = selectedValues => {
    setSelectedValues(selectedValues)
  }
  const handleBedSelectionChange = selectedBedValues => {
    setSelectedBedValues(selectedBedValues)
  }

  const isLogedIn = localStorage.getItem('anonymous')

  const isUserAuthorize = jwt_token && isLogedIn === '1'

  useEffect(() => {
    setSelectedValues(selectedValues)
  }, [selectedValues])
  useEffect(() => {
    setSelectedBedValues(selectedBedValues)
  }, [selectedBedValues])
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (isMobileView) {
      if(MapListing){
        setMixedListing(false)
        setShowListing(false)
        setMapListing(true)
        setActiveButton('map')
      }else{
        setMixedListing(false)
        setShowListing(true)
        setMapListing(false)
        setActiveButton('list')
      }
    } else {
      if(showListing){
        setMixedListing(false)
        setShowListing(true)
        setMapListing(false)
        setActiveButton('list')
      }
      if(MapListing){
        setMixedListing(false)
        setShowListing(false)
        setMapListing(true)
        setActiveButton('map')
      }else{
        setMixedListing(true)
        setShowListing(false)
        setMapListing(false)
        setActiveButton('mixed')
      }
    }
  }, [isMobileView])

  // Checkbox Building Type
  const handleCheckboxChange = value => {
    setSelectedValues(prevValues => {
      if (prevValues.includes(value)) {
        return prevValues.filter(val => val !== value)
      } else {
        return [...prevValues, value]
      }
    })
  }

  const handleBedCheckboxChange = value => {
    setSelectedBedValues(prevValues => {
      if (prevValues.includes(value)) {
        return prevValues.filter(val => val !== value)
      } else {
        return [...prevValues, value]
      }
    })
  }

  // Check if a checkbox value is selected and mark it as checked
  const isCheckboxSelected = value => selectedValues.includes(value)
  const isBedCheckboxSelected = value => selectedBedValues.includes(value)

  // Range slider values in the parent component
  const [rangeSliderValues, setRangeSliderValues] = useState({
    value1: 0,
    value2: 4600000
  })

  const handleRangeSliderChange = (value1, value2) => {
    setRangeSliderValues({ value1, value2 })
  }
  // Range slider values in the parent component
  const [rangeSQFTSliderValues, setSQFTRangeRadSliderValues] = useState({
    value1: 0,
    value2: 5000
  })
  // Range slider values in the parent component
  const [rangeRadiusSliderValues, setRadiusRangeRadSliderValues] = useState({
    value1: 1,
    value2: 20
  })

  const handleSQFTRangeSliderChange = (value1, value2) => {
    setSQFTRangeRadSliderValues({ value1, value2 })
  }
  const handleRadiusRangeSliderChange = (value1, value2) => {
    setRadiusRangeRadSliderValues({ value1, value2 })
  }

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)
  }

  const handleMapClick = () => {
    setMapListing(true)
    setCenterPopUp(true)
    setActiveButton('map')
    setMixedListing(false)
    setShowListing(false)
    if (isMobileView) {
      scrollToTop()
    }
  }

  const handleMixedClick = () => {
    setMixedListing(true)
    setActiveButton('mixed')

    setShowListing(false)
    setMapListing(false)
    setCenterPopUp(false)
  }
  const handleListClick = () => {
    setShowListing(true)

    setActiveButton('list')
    setMixedListing(false)
    setMapListing(false)
    setCenterPopUp(false)
  }
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'Keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const transformedBTypeData = Array.from(selectedValues, value => {
    let parameter

    if (value === 'All') {
      parameter = 'All'
    } else if (value === 'TownHouse') {
      parameter = 'TownHouse'
    } else if (value === 'Condo Town') {
      parameter = 'Condo Town'
    } else if (value === 'Condo') {
      parameter = 'Condo'
    } else if (value === 'Semi-Detached') {
      parameter = 'Semi-Detached'
    } else if (value === 'Detached') {
      parameter = 'Detached'
    }
    return { parameter }
  })

  const transformedCityData = selectedCityValues.map((val, index) => {
    return val
  })

  const transformedBedData = Array.from(selectedBedValues, value => {
    let parameter

    if (value === 'All') {
      parameter = 'All'
    } else if (value === 'Studio') {
      parameter = '0B'
    } else if (value === '1 Bed') {
      parameter = '1B'
    } else if (value === '1 Bed + Den') {
      parameter = '1B+D'
    } else if (value === '2 Bed') {
      parameter = '2B'
    } else if (value === '2 Bed + Den') {
      parameter = '2B+D'
    } else if (value === '3 Bed') {
      parameter = '3B'
    } else if (value === '3 Bed + Den') {
      parameter = '3B+D'
    } else if (value === '4 Bed') {
      parameter = '4B'
    } else if (value === '4 Bed + Den') {
      parameter = '4B+D'
    } else if (value === '5 Bed') {
      parameter = '5B'
    } else if (value === '5 Bed + Den') {
      parameter = '5B+D'
    } else if (value === '6 Bed+') {
      parameter = '6B+'
    }
    return { parameter }
  })
  const transformedBathData = Array.from(selectedBathButtons, value => {
    let parameter

    if (value === '1') {
      parameter = '1'
    } else if (value === '1.5') {
      parameter = '1.5'
    } else if (value === '2') {
      parameter = '2'
    } else if (value === '2.5') {
      parameter = '2.5'
    } else if (value === '3') {
      parameter = '3'
    } else if (value === '3.5') {
      parameter = '3.5'
    } else if (value === '4') {
      parameter = '4'
    } else if (value === '4+') {
      parameter = '4+'
    }
    return { parameter }
  })
  const transformedParkingData = Array.from(selectedParkingButtons, value => {
    let parameter

    if (value === '1') {
      parameter = '1'
    } else if (value === '2') {
      parameter = '2'
    } else if (value === '3') {
      parameter = '3'
    } else if (value === '4') {
      parameter = '4'
    } else if (value === '5') {
      parameter = '5'
    }
    return { parameter }
  })
  useEffect(() => { }, [ApiFilter])
  // Reset Filter Work

  const [resetTrigger, setResetTrigger] = useState(false)
  const handleResetFilters = async () => {
    setIsLoading(true)
    dispatch(removeData())
    setSearchValue('')
    setSaveSearchName('')
    setNewInputVal('')
    setSearchResDes('')
    setSearchResTitle('')
    setSQFTRangeRadSliderValues({
      value1: 0,
      value2: 5000
    })
    setRangeSliderValues({
      value1: 0,
      value2: 4600000
    })
    setRadiusRangeRadSliderValues({
      value1: 1,
      value2: 20
    })
    setSelectedValues([])
    setSelectedBedValues([])
    setSelectedParkingButtons([])
    setSelectedBathButtons([])
    setActiveButtons([])
    setActiveBathButtons([])
    setCityinAcc([])
    setSelectedStatusValues('')
    setSelectedParkingValue('')
    setSearchCheck([])
    setSelectedCityValues([])
    setApiFilter([])
    setScrollPage(1)
    setResetTrigger(true)
    setToggleIcon(true)
    setCategoryLocation('')
    setInsidePolygon('')
    setSaveSeachShow(false)
    if (pathName) {
      navigate('/')
    }
  }
  useEffect(() => {
    const handleResize = () => {
      // Check window width, set mobileSearch to false if it's not in mobile view
      if (window.innerWidth > 768) {
        setMobileSearch(false)
      } else {
        // setMobileSearch(true);
      }
    }

    // Call handleResize initially and add event listener for window resize
    handleResize()
    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (resetTrigger) {
      setResetTrigger(false)
      fetchData()
    }
    // eslint-disable-next-line
  }, [resetTrigger, setScrollPage])

  // fetch the Data to show records
  const fetchData = async () => {
    const data = {
      MinPrice: rangeSliderValues.value1,
      MaxPrice:
        rangeSliderValues.value2 > 4500000
          ? 1000000000
          : rangeSliderValues.value2,
      MinSqft: rangeSQFTSliderValues.value1.toString(),
      MaxSqft:
        rangeSQFTSliderValues.value2 > 4500
          ? 1000000
          : rangeSQFTSliderValues.value2.toString(),
      // MinRadius: rangeRadiusSliderValues.value1,
      // MaxRadius: rangeRadiusSliderValues.value2,
      Radius: rangeRadiusSliderValues.value2,
      SortBy: sortingOption,
      Title: searchResDes,
      TitleType: searchResTitle,
      Status: selectedStatusValues,
      Parking: selectedParkingValue === 'Yes',
      page: scrollPage,
      RestrictWithInCity: searchResTitle === 'Location' ? 1 : 0,
      Searchparameters_Types: transformedBTypeData,
      Searchparameters_Beds: transformedBedData,
      Searchparameters_Baths: transformedBathData,
      Searchparameters_Parkings: transformedParkingData,
      Searchparameters_Citys: transformedCityData,
      Insidepolygon : insidePolygon,
    }
    const formData = new FormData()
    formData.append('FilterDataRequest', JSON.stringify(data))
    // const geo = await Geolocation();
    await axios
      .post(`${baseURL}/api/Assignment/GetFilteredData`, formData, {})
      .then(response => {
        if (response?.data?.result === 1) {
          setIsLoading(false)
          setFilterLoading(false)
          setScrollLoading(false)
          setLoadMore(false)
          setTotalRecordCount(response?.data?.data?.count)
          setTotalPageCount(Math.ceil(response?.data?.data?.count / 20))
          if (scrollPage === 1) {
            dispatch(removeData())
            dispatch(removeMapData())
            dispatch(addData(response?.data?.data?.assignments))
            dispatch(addMapData(response?.data?.data?.filteredGeolocationDTO))
          } else {
            dispatch(addData(response?.data?.data?.assignments))
          }
        } else {
          dispatch(removeData())
          setIsLoading(false)
          setFilterLoading(false)
          console.error('Data Not Sent!')
        }
      })
      .catch(error => {
        console.error('There was a problem sending Data, Please Try Again!')
        setIsLoading(false)
        setFilterLoading(false)
      })
  }

  const [, setShowButton] = useState(true)

  // Add a useEffect to check the height and update the state
  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line
      const windowHeight = window.innerHeight
      const scrollPosition = window.scrollY
      const maxScrollHeight = 620

      // Check if the scroll position is within the specified range
      if (scrollPosition <= maxScrollHeight) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!searchData || searchData === 'undefined') {
      fetchData()
    }
    // eslint-disable-next-line
  }, [scrollPage])

  // Search Query handle
  useEffect(() => {
    if (searchData && searchData !== 'undefined') {
      const saveQueryBedParameters = searchData?.searchparameters_Beds?.map(
        // eslint-disable-next-line
        item => {
          const bedCount = item?.parameter
          if (bedCount === 'All') {
            return 'All'
          } else if (bedCount === '0B') {
            return 'Studio'
          } else if (bedCount === '1B') {
            return '1 Bed'
          } else if (bedCount === '1B+D') {
            return '1 Bed + Den'
          } else if (bedCount === '2B') {
            return '2 Bed'
          } else if (bedCount === '2B+D') {
            return '2 Bed + Den'
          } else if (bedCount === '3B') {
            return '3 Bed'
          } else if (bedCount === '3B+D') {
            return '3 Bed + Den'
          } else if (bedCount === '4B') {
            return '4 Bed'
          } else if (bedCount === '4B+D') {
            return '4 Bed + Den'
          } else if (bedCount === '5B') {
            return '5 Bed'
          } else if (bedCount === '5B+D') {
            return '5 Bed + Den'
          } else if (bedCount === '6B+') {
            return '6 Bed+'
          }
        }
      )

      const saveQueryBathParameters = searchData?.searchparameters_Baths?.map(
        item => item.parameter
      )
      const saveQueryBTypes = searchData?.searchparameters_Types?.map(
        item => item.parameter
      )
      const saveQueryCities = searchData?.searchparameters_Citys?.map(
        item => item.parameter
      )
      const saveQueryParking = searchData?.searchparameters_Parkings?.map(
        item => item.parameter
      )
      setSearchResTitle(searchData?.titleType)
      setSearchValue(searchData?.title)
      setSearchResDes(searchData?.title)
      setNewInputVal(searchData?.title)
      setToggleIcon(false)
      setSQFTRangeRadSliderValues({
        value1: searchData?.minSqft, // Set the new value for value1
        value2: searchData?.maxSqft
      })
      setRangeSliderValues({
        value1: searchData?.minPrice,
        value2: searchData?.maxPrice
      })
      setRadiusRangeRadSliderValues({
        value1: 1,
        value2: searchData?.radius || 20
      })
      setSelectedValues(saveQueryBTypes)
      setSelectedBedValues(saveQueryBedParameters)
      setSelectedParkingButtons(saveQueryParking)
      setSelectedBathButtons(saveQueryBathParameters)
      setActiveButtons(saveQueryParking)
      setActiveBathButtons(saveQueryBathParameters)
      setCityinAcc(saveQueryCities)
      setSelectedStatusValues(searchData?.status)
      setSelectedParkingValue(searchData?.parking ? 'Yes' : 'No')
      //   setSearchCheck([]);
      const selectedCitytosend = saveQueryCities.map(city => ({
        parameter: city
      }))
      setSelectedCityValues(selectedCitytosend)
      setSearchQueryUpdate(true)
      setCategoryLocation('')
    }
    // eslint-disable-next-line
  }, [searchData])

  useEffect(() => {
    if (searchQueryUpdate) {
      fetchData()
      // setSearchQueryUpdate(false);
    }
    // eslint-disable-next-line
  }, [searchQueryUpdate, scrollPage])


  const handleFilterForm = async () => {
    setFilterLoading(true)
    setScrollPage(1)
    setIsLoading(true)
    setCategoryLocation('')
    dispatch(removeData())
    fetchData()
    setSaveSeachShow(true)
  }

  //Sorting Work
  const [sortingOption, setSortingOption] = useState('NE')
  const [sortInitiated, setSortInitiated] = useState(false)
  const sortingRender = useRef(true)

  const handleSortingChange = value => {
    setSortingOption(value)
    setSortInitiated(true)
  }

  useEffect(() => {
    if (!sortingRender.current && sortInitiated) {
      setScrollPage(1)
      setIsLoading(true)
      fetchData()
      if (sortingOption === 'NE') {
        setSortingHeading('Newest First')
      } else if (sortingOption === 'PLH') {
        setSortingHeading('At Low Price')
      } else if (sortingOption === 'PHL') {
        setSortingHeading('At High Price')
      } else if (sortingOption === 'SLH') {
        setSortingHeading('At Low SQFT')
      } else if (sortingOption === 'SHL') {
        setSortingHeading('At High SQFT')
      } else if (sortingOption === 'CLF') {
        setSortingHeading('Closest First')
      } else if (sortingOption === 'PSHL') {
        setSortingHeading('At High per sqft')
      } else if (sortingOption === 'PSLH') {
        setSortingHeading('At Low per sqft')
      }
    }
    sortingRender.current = false
    // eslint-disable-next-line
  }, [sortingOption, sortInitiated])

  //Sorting Heading Work

  //Search Location Work
  const fetchlocation = async () => {
    try {
      const geo = await Geolocation()
      setToggleIcon(false)
      setSearchResTitle('Location')
      setSearchValue(geo?.address)
      setNewInputVal(geo?.address)
      setSearchResDes(geo?.address)
    } catch (error) {
      alert(
        'There is no location support on this device or it is disabled. To enable go to Settings > Privacy and security > Site Settings > Location'
      )
    }
  }

  const handleSearchLocation = async () => {
    fetchlocation()
  }

  //Search By Category Work
  useEffect(() => {
    if (categoryLocation === undefined || categoryLocation === '') {
      return
    }
    setSearchResTitle('Location')
    setScrollPage(1)
    setToggleIcon(false)
    setSearchValue(categoryLocation)
    setSearchResDes(categoryLocation)
    setNewInputVal(categoryLocation)
    setSelectedValues([categoryBType])
    setSearchCategoryUpdate(true)
    setSortInitiated(true)
    setInsidePolygon('')
  }, [categoryLocation, categoryBType])

  useEffect(() => {
    if (searchCategoryUpdate) {
      fetchData()
      setSearchCategoryUpdate(false)
    }
    // eslint-disable-next-line
  }, [searchCategoryUpdate])
  //Search By Category Work End

  useEffect(() => {
    // if (insidePolygon ===) {
      setIsLoading(true)
      fetchData()
    // }
    // eslint-disable-next-line
  }, [insidePolygon])


  const handleSearchValuesClick = () => {
    setSearchValue('')
    setSaveSearchName('')
    setNewInputVal('')
    setToggleIcon(true)
    setSearchResTitle('')
    setSearchResDes('')
  }

  const handleStatusValuesClick = () => {
    setSelectedStatusValues('')
  }
  const handleRangeSliderValuesCross = () => {
    setRangeSliderValues({
      value1: 0,
      value2: 4600000
    })
  }
  const handleRangeSQFTCross = () => {
    setSQFTRangeRadSliderValues({
      value1: 0, // Set the new value for value1
      value2: 5000
    })
  }
  const handleRangeRadCross = () => {
    setRadiusRangeRadSliderValues({
      value1: 1, // Set the new value for value1
      value2: 20
    })
  }
  const handleSelectedValuesCross = index => {
    setSelectedValues(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues.splice(index, 1)
      return updatedValues
    })
  }
  const handleSelectedBathValuesCross = index => {
    setSelectedBathButtons(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues.splice(index, 1)
      return updatedValues
    })
    setActiveBathButtons(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues.splice(index, 1)
      return updatedValues
    })
  }
  const handleSelectedBedValuesCross = index => {
    setSelectedBedValues(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues.splice(index, 1)
      return updatedValues
    })
  }
  const handleSelectedParkValuesCross = index => {
    setSelectedParkingButtons(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues.splice(index, 1)
      return updatedValues
    })
    setActiveButtons(prevValues => {
      const updatedValues = [...prevValues]
      updatedValues.splice(index, 1)
      return updatedValues
    })
  }

  const handleSelectedCityValuesCross = (index) => {
    setCityinAcc((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
    setSelectedCityValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
    setSearchCheck((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };
  useEffect(() => {
    if (userData && userData.firstName) {
      localStorage.setItem("fullName", userData.firstName);
    }
    if (userData && userData.email) {
      localStorage.setItem("email", userData.email);
    }
    if (userData && userData.phoneNumber) {
      localStorage.setItem("phoneNumber", userData.phoneNumber);
    }
  }, [userData]);

  const handleSaveSearchChange = (event) => {
    setSaveSearchName(event.target.value);
  };

  const savesearchfun = async e => {
    e.preventDefault()
    let obj = {
      SearchName: saveSearchName,
      // Frequency: selectedRadioValue,
      Frequency: "Monthly",
      SearchParameters: {
        MinPrice: rangeSliderValues.value1,
        MaxPrice: rangeSliderValues.value2,
        MinSqft: rangeSQFTSliderValues.value1.toString(),
        MaxSqft: rangeSQFTSliderValues.value2.toString(),
        Radius: rangeRadiusSliderValues.value2,
        Title: searchResDes,
        TitleType: searchResTitle,
        Status: selectedStatusValues,
        Parking: selectedParkingValue === 'Yes',
        Searchparameters_Types: transformedBTypeData,
        Searchparameters_Beds: transformedBedData,
        Searchparameters_Baths: transformedBathData,
        Searchparameters_Parkings: transformedParkingData,
        Searchparameters_Citys: transformedCityData
      }
    }
    let emailConfirmed = false
    if (jwt_token) {
      const decoded = jwt(jwt_token)
      const emailConfirmedStr =
        decoded[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous'
        ]
      emailConfirmed = emailConfirmedStr === 'True'
    }

    if (isUserAuthorize) {
      if (emailConfirmed) {
        const formData = new FormData()
        formData.append('SaveSearchRequest', JSON.stringify(obj))
        const headers = {
          Authorization: `Bearer ${jwt_token}`
        }
        await axios
          .post(`${baseURL}/api/User-Data/AddSearch`, formData, { headers })
          .then(response => {
            if (response.data.result === 1) {
              toast.success('Successfully Saved')
              // cmme
            } else {
              console.error('Data Not Sent!')
            }
            handleSearchClose()
          })
          .catch(error => {
            console.error('There was a problem sending Data, Please Try Again!')
            console.error(error)
            handleSearchClose()
          })
      } else {
        navigate('/email-confirmation?isEmailConfirmed=false')
      }
    } else {
      navigate('/login')
    }
  }


  //Get Cities filter Work
  const [citiesFilterData, setCitiesFilterData] = useState([])

  const fetchCitiesFilterData = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/data/GetCity`);
      if (response?.data?.result === 1) {
        let dataArray = response?.data?.data;
        if (dataArray) {
          let AllCityObject = { cityName: 'All' };
          let newDataArray = [AllCityObject, ...dataArray];
          setCitiesFilterData(newDataArray)
        }
      }
    } catch (error) {
      console.log(error)
    }
  };


  useEffect(() => {
    fetchCitiesFilterData()
  }, [])


  // Filter Drawer
  const list = (anchor, selectedValues) => (
    <Box
      className={mobileSearch ? 'mob__drawerwidth' : 'drawerwidth'}
      // sx={{
      //   width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
      // }}
      role='presentation'
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className={
          mobileSearch
            ? 'greencolor textwhite drawerdisp pt-3 pb-1 px-1  font'
            : ' greencolor textwhite drawerdisp pt-3 pb-1 px-1  font'
        }
      >
        <h4 className='filtermargin'>Filters</h4>
        <button onClick={toggleDrawer(anchor, false)} className='btn'>
          <CloseIcon className='closee' />
        </button>
      </div>
      <div className='container'>
        <div className='prop_hide'>
          <p className='m-0 m-v-Property__Heading'>Find Property</p>
        </div>
        <Divider />
        <List className='m-0 p-0'>
          <div className='p-2'>
            <p className='font pt-1 m-1 '>
              <strong className='filter_heading '>Building Type:</strong>
            </p>
            <ButtonList
              selectedValues={selectedValues}
              handleSelectionChange={handleSelectionChange}
            />
          </div>
        </List>
        <List>
          <div className='px-3 pt-1 pb-2'>
            <p className='font filter_para'>
              <strong className='filter_heading'>Price Range:</strong>
            </p>
            <RangeSlider
              value1={rangeSliderValues.value1}
              value2={rangeSliderValues.value2}
              onChange={handleRangeSliderChange}
            />
          </div>
          <Divider />
          <div className='px-3 py-2'>
            <p className='filter_para font'>
              <strong className='filter_heading'>Size Sqft:</strong>
            </p>
            <SQFTRangeSlider
              value1={rangeSQFTSliderValues.value1}
              value2={rangeSQFTSliderValues.value2}
              onChange={handleSQFTRangeSliderChange}
            />
          </div>
          <Divider />
          <div className='px-3 py-2'>
            <p className='font filter_para'>
              <strong className='filter_heading'>Radius:</strong>
            </p>
            <div className='mt-2'>
              <RadiusRangeSlider
                value1={rangeRadiusSliderValues.value1}
                value2={rangeRadiusSliderValues.value2}
                onChange={handleRadiusRangeSliderChange}
              />
            </div>
          </div>
          {/* <div className='px-3 herooi'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={restrictVariable === 1}
                  className='pt-2 px-2'
                  onChange={handleRestrictChange}
                />
              }
              label='Restrict only with-in City Limits'
            />
          </div> */}
          <div className='bookingbuttons'>
            <Divider />
            <p className='font filter_buttonPara pt-1  m-0'>
              <strong className='filter_heading'>Bedrooms </strong>
            </p>
            <div className='btndiv'>
              <BedButtonList
                selectedBedValues={selectedBedValues}
                handleBedSelectionChange={handleBedSelectionChange}
              />
            </div>
          </div>
          <div className='bookingbuttons'>
            <Divider />
            <p className='font filter_buttonPara pt-1 m-0'>
              <strong className='filter_heading'>Bathrooms</strong>
            </p>
            <div className='bathbtns'>
              <BathroomButtons
                selectedBathButtons={handleSelectedBathButtons}
                activeBathButtons={activeBathButtons}
                setActiveBathButtons={setActiveBathButtons}
              />
            </div>
          </div>
          <div className='bookingbuttons'>
            <Divider />
            <p className='font filter_buttonPara pt-1 m-0'>
              <strong className='filter_heading'>Parking</strong>
            </p>
            <div className='parkingbtns'>
              <ParkingButtons
                selectedParkingButtons={handleSelectedParkingButtons}
                activeButtons={activeButtons}
                setActiveButtons={setActiveButtons}
              />
            </div>
          </div>
        </List>
        <Divider />
      </div>
      <div>
        <AccordionPanel
          selectedCityValues={selectedCityValues}
          setSelectedCityValues={setSelectedCityValues}
          selectedStatusValues={selectedStatusValues}
          setSelectedStatusValues={setSelectedStatusValues}
          setSelectedParkingValue={setSelectedParkingValue}
          selectedParkingValue={selectedParkingValue}
          searchCheck={searchCheck}
          setSearchCheck={setSearchCheck}
          cityinAcc={cityinAcc}
          setCityinAcc={setCityinAcc}
        />
      </div>
      <div>
        <div onClick={toggleDrawer(anchor, false)}>
          <button
            onClick={() => {
              handleFilterForm()
            }}
            className='btn font btngreencolor sizings'
          >
            Apply Filters
          </button>
        </div>
        <button
          onClick={handleResetFilters}
          className='btn font btngreycolor sizings'
        >
          Reset
        </button>
      </div>
    </Box>
  )
  // Search Filter Drawer

  return (
    <>
      <Modal
        open={searchOpen}
        onClose={handleSearchClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={SearchStyle} className='mobile-savedSearch-container '>
          {isMobileView ? (
            <div className='row font'>
              <div className='justifycenter'>
                <div
                  onClick={handleSearchClose}
                  className='justifycenter topBorder'
                ></div>
              </div>
              <div className='col-lg-6 font NMANL'>
                Never Miss a New Listing
              </div>
              <p>
                We will notify you when new listings align with your keyword
                search.
              </p>

              <div className='pt-1'></div>
              <form onSubmit={savesearchfun}>
                <div className='mb-4'>
                  <p className='font fieldhead weight_900 m-0'>
                    Name Your Search:
                  </p>
                  <TextField
                    size='small'
                    sx={{
                      width: '100%',
                      borderRadius: '10px'
                    }}
                    id='outlined-basic '
                    variant='outlined'
                    value={saveSearchName}
                    onChange={handleSaveSearchChange}
                    required
                  />
                </div>
                {/* <div>
                <p className="font fieldhead weight_900 m-0">Email</p>
                <TextField
                  size="small"
                  sx={{
                    width: "100%",
                    borderRadius: "4px",
                    marginBottom: "20px",
                  }}
                  id="outlined-basic "
                  variant="outlined"
                  value={saveSearchEmail}
                  onChange={handleSaveSearchMailChange}
                />
              </div>
              <div className="hs-8 aligncenter">

                <IoNotificationsOutline className="mail__Font text-grey" />
                <p className="hs-ef pt-2 weight_900 ">
                  <strong> Email Interval:</strong>
                </p>
              </div>
              <div className="pa___Left">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedRadioValue} //Set the selected value based on the state variable
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="Instant"
                    control={<Radio />}
                    label="Instant"
                  />
                  <FormControlLabel
                    value="Daily"
                    control={<Radio />}
                    label="Daily"
                  />
                  <FormControlLabel
                    value="Never"
                    control={<Radio />}
                    label="Never"
                  />
                </RadioGroup>
              </div> */}
                <div className='d-flex justify-content-between mt-3'>
                  <div className='col-5'>
                    <button
                      className='btn w-100 hse-cs1'
                      onClick={handleSearchClose}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className='col-5'>
                    <button className='btn w-100 hs-cs2e' type='submit'>
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className='row font'>
              <div className='col-lg-6 font hs-6'>Save This Search</div>
              <div
                onClick={handleSearchClose}
                className='col-lg-6 justifyright'
              >
                <CloseIcon />
              </div>
              <div className='pt-3'></div>
              <form onSubmit={savesearchfun}>
                <div>
                  <p className='font fieldhead m-0'>Enter Search Name:</p>
                  <TextField
                    sx={{
                      width: '100%',
                      borderRadius: '4px',
                      marginBottom: '20px'
                    }}
                    id='outlined-basic '
                    variant='outlined'
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    value={saveSearchName}
                    onChange={handleSaveSearchChange}
                    required
                  />
                </div>
                {/* <div className="hs-8">
                <p className="hs-ef pt-3">
                  <strong>Email Frequency:</strong>
                </p>
                <div className="p-3"></div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedRadioValue} //Set the selected value based on the state variable
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="Instant"
                    control={<Radio />}
                    label="Instant"
                  />
                  <FormControlLabel
                    value="Daily"
                    control={<Radio />}
                    label="Daily"
                  />
                  <FormControlLabel
                    value="Never"
                    control={<Radio />}
                    label="Never"
                  />
                </RadioGroup>
              </div> */}
                <div className='bt-2 justifyright'>
                  <button className='btn hs-cs1' onClick={handleSearchClose}>
                    Cancel
                  </button>
                  <div className='p-1'></div>
                  <button className='btn hs-cs2' type='submit'>
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </Box>
      </Modal>
      <div className='mt-3 assignmentSearch mobile_margin_Top4 mobile_Search_bg'>
        <div className='assignmentSearch  '>
          <div className='assignments  font'>
            <p className='assign'>Assignments</p>
          </div>
          {/* New Header Section */}
          <div className='container head-padding '>
            <div
              className={
                mobileSearch
                  ? 'd-none row hs-bor1 justify-content-around'
                  : 'row hs-bor1 justify-content-around'
              }
            >
              {/* SEarch Field */}
              <div
                className={
                  isMobileView
                    ? 'col-md-4 searchbor w-100'
                    : 'col-md-4  searchbor tab-width'
                }
              >
                <SearchField
                  onSearch={handleSearch}
                  setSearchValue={setSearchValue}
                  setNewInputVal={setNewInputVal}
                  newInputval={newInputval}
                  setSearchResTitle={setSearchResTitle}
                  setSearchResDes={setSearchResDes}
                  setToggleIcon={setToggleIcon}
                  toggleIcon={toggleIcon}
                  handleSearchLocation={handleSearchLocation}
                  handleFilterForm={handleFilterForm}
                />
              </div>
              {/* Range SLider */}
              <div className='col-md-3 m-v-pad-h t-vh-s'>
                <div className='row slider-hide'>
                  <div className='col-lg-6'>
                    <RangeSlider
                      value1={rangeSliderValues.value1}
                      value2={rangeSliderValues.value2}
                      onChange={handleRangeSliderChange}
                    />
                  </div>
                  <div className='col-lg-6 '>
                    <SQFTRangeSlider
                      value1={rangeSQFTSliderValues.value1}
                      value2={rangeSQFTSliderValues.value2}
                      onChange={handleSQFTRangeSliderChange}
                    />
                  </div>
                </div>
              </div>
              {/* Button Groups */}
              <div className='col-md-5 p-0 d-flex buttons-hide tab-search-width align-items-center justify-content-center'>
                <div className='row'>
                  <div className='col-lg-11 p-0 t-vh-s'>
                    <div className='row'>
                      <div className='col-lg-5 pl-08'>
                        {' '}
                        <Button
                          aria-expanded='false'
                          id='dropdownMenuButton1'
                          data-bs-toggle='dropdown'
                          variant='outlined'
                          endIcon={
                            <KeyboardArrowDownIcon className='button_arrow' />
                          }
                          sx={{
                            fontWeight: '500',
                            fontSize: '0.7rem',
                            color: '#121212',
                            width: '106%',
                            fontFamily: 'Montserrat',
                            textTransform: 'capitalize',
                            border: '0.5px solid #B0B0B0',
                            borderRadius: '0.5rem',
                            lineHeight: '1rem',
                            padding: '0.4875rem 0.2875rem',
                            gap: '0.325rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiButton-endIcon': {
                              margin: 0
                            }
                          }}
                        >
                          <TbBuilding
                            className='icoonfont '
                            style={{
                              color: '#B0B0B0',
                              width: '1.5rem',
                              height: '1.5rem'
                            }}
                          />{' '}
                          <span className='fw-bold text-nowrap'>
                            {' '}
                            Building Type
                          </span>
                        </Button>
                        <ul
                          className='dropdown-menu menuwidth1 p-2'
                          aria-labelledby='dropdownMenuButton1'
                        >
                          {/* <li className="">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isCheckboxSelected("All")}
                                onChange={(event) =>
                                  handleCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className="font">All</span>}
                            value="All"
                          />
                        </li> */}
                          <li className=''>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheckboxSelected('Condo')}
                                  onChange={event =>
                                    handleCheckboxChange(event.target.value)
                                  }
                                />
                              }
                              label={<span className='font'>Condo</span>}
                              value='Condo'
                            />
                          </li>
                          <li className=''>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheckboxSelected('Condo Town')}
                                  onChange={event =>
                                    handleCheckboxChange(event.target.value)
                                  }
                                />
                              }
                              label={<span className='font'>Condo Town</span>}
                              value='Condo Town'
                            />
                          </li>
                          <li className=''>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheckboxSelected('TownHouse')}
                                  onChange={event =>
                                    handleCheckboxChange(event.target.value)
                                  }
                                />
                              }
                              label={<span className='font'>TownHouse</span>}
                              value='TownHouse'
                            />
                          </li>
                          <li className=''>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheckboxSelected('Semi-Detached')}
                                  onChange={event =>
                                    handleCheckboxChange(event.target.value)
                                  }
                                />
                              }
                              label={
                                <span className='font'>Semi-Detached</span>
                              }
                              value='Semi-Detached'
                            />
                          </li>
                          <li className=''>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheckboxSelected('Detached')}
                                  onChange={event =>
                                    handleCheckboxChange(event.target.value)
                                  }
                                />
                              }
                              label={<span className='font'>Detached</span>}
                              value='Detached'
                            />
                          </li>
                        </ul>
                      </div>
                      <div className='col-lg-4 pl-09'>
                        {' '}
                        <div className=''>
                          <Button
                            aria-expanded='false'
                            id='dropdownMenuButton2'
                            data-bs-toggle='dropdown'
                            variant='outlined'
                            endIcon={
                              <KeyboardArrowDownIcon className='button_arrow' />
                            }
                            sx={{
                              fontWeight: '500',
                              fontSize: '0.7rem',
                              color: '#121212',
                              width: '106%',
                              fontFamily: 'Montserrat',
                              textTransform: 'capitalize',
                              border: '0.5px solid #B0B0B0',
                              borderRadius: '0.5rem',
                              lineHeight: '1rem',
                              padding: '0.4875rem 0.2875rem',
                              gap: '0.325rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              '& .MuiButton-endIcon': {
                                margin: 0
                              }
                            }}
                          >
                            <BedOutlinedIcon
                              className='icoonfont'
                              style={{
                                color: '#B0B0B0',
                                width: '1.5rem',
                                height: '1.5rem'
                              }}
                            />{' '}
                            <span className='text-nowrap fw-bold'>Beds</span>
                          </Button>
                          <ul
                            className='dropdown-menu menuwidth p-2'
                            aria-labelledby='dropdownMenuButton1'
                          >
                            {/* <li className="">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isBedCheckboxSelected("All")}
                                      onChange={(event) =>
                                        handleBedCheckboxChange(
                                          event.target.value
                                        )
                                      }
                                    />
                                  }
                                  label={<span className="font">All</span>}
                                  value="All"
                                />
                              </li> */}
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('Studio')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>Studio</span>}
                                value='Studio'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('1 Bed')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>1 Bed</span>}
                                value='1 Bed'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected(
                                      '1 Bed + Den'
                                    )}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={
                                  <span className='font'>1 Bed + Den</span>
                                }
                                value='1 Bed + Den'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('2 Bed')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>2 Bed</span>}
                                value='2 Bed'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected(
                                      '2 Bed + Den'
                                    )}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={
                                  <span className='font'>2 Bed + Den</span>
                                }
                                value='2 Bed + Den'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('3 Bed')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>3 Bed</span>}
                                value='3 Bed'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected(
                                      '3 Bed + Den'
                                    )}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={
                                  <span className='font'>3 Bed + Den</span>
                                }
                                value='3 Bed + Den'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('4 Bed')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>4 Bed</span>}
                                value='4 Bed'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected(
                                      '4 Bed + Den'
                                    )}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={
                                  <span className='font'>4 Bed + Den</span>
                                }
                                value='4 Bed + Den'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('5 Bed')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>5 Bed</span>}
                                value='5 Bed'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected(
                                      '5 Bed + Den'
                                    )}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={
                                  <span className='font'>5 Bed + Den</span>
                                }
                                value='5 Bed + Den'
                              />
                            </li>
                            <li className=''>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBedCheckboxSelected('6 Bed+')}
                                    onChange={event =>
                                      handleBedCheckboxChange(
                                        event.target.value
                                      )
                                    }
                                  />
                                }
                                label={<span className='font'>6 Bed+</span>}
                                value='6 Bed+'
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-lg-3 p-0'>
                        {' '}
                        <div>
                          {['right'].map(anchor => (
                            <React.Fragment key={anchor}>
                              <Button
                                onClick={toggleDrawer(anchor, true)}
                                variant='outlined'
                                sx={{
                                  fontWeight: '200',
                                  fontSize: '0.7rem',
                                  width: '80%',
                                  color: '#121212',
                                  fontFamily: 'Montserrat',
                                  textTransform: 'capitalize',
                                  lineHeight: '1rem',
                                  border: '0.5px solid #B0B0B0',
                                  borderRadius: '0.5rem',
                                  padding: '0.4875rem 0.2875rem',
                                  gap: '0.325rem',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <BiFilter
                                  className='icoonfont '
                                  style={{
                                    color: '#B0B0B0',
                                    width: '1.5rem',
                                    height: '1.5rem'
                                  }}
                                />{' '}
                                <span className='fw-bold'>Filters</span>
                              </Button>
                              <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                sx={{
                                  '& .MuiDrawer-paper': {
                                    borderTopLeftRadius: '30px'
                                  }
                                }}
                              >
                                {list(anchor, selectedValues)}
                              </Drawer>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Blue Background Search Button */}
                  <div className='col-lg-1 p-0'>
                    <button
                      className=' btn-click m-0'
                      type='submit'
                      onClick={handleFilterForm}
                      disabled={filterLoading} // Disable the button while loading
                    >
                      {filterLoading ? (
                        <Loader size='40' /> // Show the loader when loading
                      ) : (
                        <div className='searchbtn'>
                          <AiOutlineSearch />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-mode-filters p-2'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='row left__0'>
                  <div className='col-md-6'>
                    {' '}
                    <RangeSlider
                      value1={rangeSliderValues.value1}
                      value2={rangeSliderValues.value2}
                      onChange={handleRangeSliderChange}
                    />
                  </div>
                  <div className='col-md-6'>
                    {' '}
                    <SQFTRangeSlider
                      value1={rangeSQFTSliderValues.value1}
                      value2={rangeSQFTSliderValues.value2}
                      onChange={handleSQFTRangeSliderChange}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-7'>
                {' '}
                <div className='row'>
                  <div className='col-md-5'>
                    <Button
                      aria-expanded='false'
                      id='dropdownMenuButton1'
                      className='w-100'
                      data-bs-toggle='dropdown'
                      variant='outlined'
                      endIcon={
                        <KeyboardArrowDownIcon className='button_arrow' />
                      }
                      sx={{
                        fontWeight: '500',
                        fontSize: '1rem',
                        color: '#121212',
                        width: '100%',
                        fontFamily: 'Montserrat',
                        textTransform: 'capitalize',
                        border: '0.5px solid #B0B0B0',
                        borderRadius: '0.5rem',
                        lineHeight: '1rem',
                        padding: '0.4875rem 0.2875rem',
                        gap: '0.325rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .MuiButton-endIcon': {
                          margin: 0
                        }
                      }}
                    >
                      <TbBuilding
                        className='icoonfont'
                        style={{
                          color: '#B0B0B0',
                          width: '1.5rem',
                          height: '1.5rem'
                        }}
                      />{' '}
                      <span>Building Type</span>
                    </Button>
                    <ul
                      className='dropdown-menu menuwidth1 p-2'
                      aria-labelledby='dropdownMenuButton1'
                    >
                      {/* <li className="">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isCheckboxSelected("All")}
                                onChange={(event) =>
                                  handleCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className="font">All</span>}
                            value="All"
                          />
                        </li> */}
                      <li className=''>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckboxSelected('Condo')}
                              onChange={event =>
                                handleCheckboxChange(event.target.value)
                              }
                            />
                          }
                          label={<span className='font'>Condo</span>}
                          value='Condo'
                        />
                      </li>
                      <li className=''>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckboxSelected('Condo Town')}
                              onChange={event =>
                                handleCheckboxChange(event.target.value)
                              }
                            />
                          }
                          label={<span className='font'>Condo Town</span>}
                          value='Condo Town'
                        />
                      </li>
                      <li className=''>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckboxSelected('TownHouse')}
                              onChange={event =>
                                handleCheckboxChange(event.target.value)
                              }
                            />
                          }
                          label={<span className='font'>TownHouse</span>}
                          value='TownHouse'
                        />
                      </li>
                      <li className=''>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckboxSelected('Semi-Detached')}
                              onChange={event =>
                                handleCheckboxChange(event.target.value)
                              }
                            />
                          }
                          label={<span className='font'>Semi-Detached</span>}
                          value='Semi-Detached'
                        />
                      </li>
                      <li className=''>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckboxSelected('Detached')}
                              onChange={event =>
                                handleCheckboxChange(event.target.value)
                              }
                            />
                          }
                          label={<span className='font'>Detached</span>}
                          value='Detached'
                        />
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-4'>
                    <div className=''>
                      <Button
                        aria-expanded='false'
                        id='dropdownMenuButton2'
                        className='w-100'
                        data-bs-toggle='dropdown'
                        variant='outlined'
                        endIcon={
                          <KeyboardArrowDownIcon className='button_arrow' />
                        }
                        sx={{
                          fontWeight: '500',
                          fontSize: '1rem',
                          color: '#121212',
                          fontFamily: 'Montserrat',
                          textTransform: 'capitalize',
                          border: '0.5px solid #B0B0B0',
                          borderRadius: '0.5rem',
                          lineHeight: '1rem',
                          padding: '0.4875rem 0.2875rem',
                          gap: '0.325rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          '& .MuiButton-endIcon': {
                            margin: 0
                          }
                        }}
                      >
                        <BedOutlinedIcon
                          className='icoonfont'
                          style={{
                            color: '#B0B0B0',
                            width: '1.5rem',
                            height: '1.5rem'
                          }}
                        />{' '}
                        <span>Beds</span>
                      </Button>
                      <ul
                        className='dropdown-menu menuwidth p-2'
                        aria-labelledby='dropdownMenuButton1'
                      >
                        {/* <li className="">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isBedCheckboxSelected("All")}
                                      onChange={(event) =>
                                        handleBedCheckboxChange(
                                          event.target.value
                                        )
                                      }
                                    />
                                  }
                                  label={<span className="font">All</span>}
                                  value="All"
                                />
                              </li> */}
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('Studio')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>Studio</span>}
                            value='Studio'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('1 Bed')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>1 Bed</span>}
                            value='1 Bed'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('1 Bed + Den')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>1 Bed + Den</span>}
                            value='1 Bed + Den'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('2 Bed')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>2 Bed</span>}
                            value='2 Bed'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('2 Bed + Den')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>2 Bed + Den</span>}
                            value='2 Bed + Den'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('3 Bed')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>3 Bed</span>}
                            value='3 Bed'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('3 Bed + Den')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>3 Bed + Den</span>}
                            value='3 Bed + Den'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('4 Bed')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>4 Bed</span>}
                            value='4 Bed'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('4 Bed + Den')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>4 Bed + Den</span>}
                            value='4 Bed + Den'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('5 Bed')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>5 Bed</span>}
                            value='5 Bed'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('5 Bed + Den')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>5 Bed + Den</span>}
                            value='5 Bed + Den'
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isBedCheckboxSelected('6 Bed+')}
                                onChange={event =>
                                  handleBedCheckboxChange(event.target.value)
                                }
                              />
                            }
                            label={<span className='font'>6 Bed+</span>}
                            value='6 Bed+'
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div>
                      {['right'].map(anchor => (
                        <React.Fragment key={anchor}>
                          <Button
                            onClick={toggleDrawer(anchor, true)}
                            className='w-100'
                            variant='outlined'
                            sx={{
                              fontWeight: '500',
                              fontSize: '1rem',
                              color: '#121212',
                              fontFamily: 'Montserrat',
                              textTransform: 'capitalize',
                              lineHeight: '1rem',
                              border: '0.5px solid #B0B0B0',
                              borderRadius: '0.5rem',
                              padding: '0.4875rem 0.2875rem',
                              gap: '0.325rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <BiFilter
                              className='icoonfont'
                              style={{
                                color: '#B0B0B0',
                                width: '1.5rem',
                                height: '1.5rem'
                              }}
                            />{' '}
                            <span>Filters</span>
                          </Button>
                          <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            sx={{
                              '& .MuiDrawer-paper': {
                                borderTopLeftRadius: '30px'
                              }
                            }}
                          >
                            {list(anchor, selectedValues)}
                          </Drawer>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container hs-d'>
          <div className='hs-md'>
            {(searchValue && newInputval) ||
              rangeSliderValues.value1 !== 0 ||
              rangeSliderValues.value2 !== 4600000 ||
              rangeSQFTSliderValues.value1 !== 0 ||
              rangeSQFTSliderValues.value2 !== 5000 ||
              rangeRadiusSliderValues.value1 !== 1 ||
              rangeRadiusSliderValues.value2 !== 20 ||
              selectedValues.length !== 0 ||
              selectedBathButtons.length !== 0 ||
              selectedParkingButtons.length !== 0 ||
              selectedStatusValues !== '' ||
              cityinAcc.length !== 0 ||
              selectedParkingValue !== '' ||
              selectedBedValues.length !== 0 ? (
              <div
                className={mobileSearch ? 'd-none row  hs-ud' : ' row  hs-ud'}
              >
                <div className='col-lg-8 hs-pl2'>
                  {searchValue && (
                    <>
                      <p className='hs-11'>
                        {/* {searchResTitle === "Location" ? (
                        <LocationOnIcon className="hs-li" />
                      ) : (
                        <FaTools className="hs-li" />
                      )}{" "} */}{' '}
                        {(searchResTitle === 'listing' ||
                          searchResTitle === 'Address') && (
                            <SiGooglephotos className='hs-li' fontSize='20px' />
                          )}
                        {searchResTitle === 'Location' && (
                          <LocationOnIcon className='hs-li' />
                        )}
                        {searchResTitle === 'Developer' && (
                          <FaTools className='hs-li' />
                        )}
                        {searchValue}
                        <span>
                          <CloseIcon
                            onClick={handleSearchValuesClick}
                            className='hs-2'
                          />
                        </span>
                      </p>
                      {/* {searchResTitle === 'Location' ? (
                        <div className='hs-11'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{ padding: '0px !important' }}
                                checked={restrictVariable === 1}
                                onChange={handleRestrictChange}
                              />
                            }
                            label='Restrict within City'
                          />
                        </div>
                      ) : null} */}
                    </>
                  )}
                  {/* {selectedParkingValue && (
                    <>
                      <p className="hs-11">
                        <LocalParking className="hs-li" />{" "}
                        {selectedParkingValue}
                        <span>
                          <CloseIcon
                            onClick={handleParkValuesClick}
                            className="hs-2"
                          />
                        </span>
                      </p>
                    </>
                  )} */}
                  {selectedStatusValues && (
                    <>
                      <p className='hs-11'>
                        {selectedStatusValues}
                        <span>
                          <CloseIcon
                            onClick={handleStatusValuesClick}
                            className='hs-2'
                          />
                        </span>
                      </p>
                    </>
                  )}
                  {(rangeSliderValues.value1 !== 0 ||
                    rangeSliderValues.value2 !== 4600000) && (
                      <>
                        <p className='hs-11'>
                          <AttachMoneyIcon className='hs-li' />
                          {formatNumber(rangeSliderValues.value1)}{' '}
                          <span>
                            to{' '}
                            {rangeSliderValues.value2 > 4500000
                              ? ' Unlimited'
                              : ` ${formatNumber(rangeSliderValues.value2)}`}
                            <CloseIcon
                              onClick={handleRangeSliderValuesCross}
                              className='hs-2'
                            />
                          </span>
                        </p>
                      </>
                    )}

                  {(rangeSQFTSliderValues.value1 !== 0 ||
                    rangeSQFTSliderValues.value2 !== 5000) && (
                      <>
                        <p className='hs-11'>
                          {formatNumber(rangeSQFTSliderValues.value1)}
                          <span> </span>
                          <span>
                            to{' '}
                            {rangeSQFTSliderValues.value2 > 4500
                              ? ' Unlimited Sqft'
                              : ` ${formatNumber(
                                rangeSQFTSliderValues.value2
                              )} Sqft`}
                            <CloseIcon
                              onClick={handleRangeSQFTCross}
                              className='hs-2'
                            />
                          </span>
                        </p>
                      </>
                    )}
                  {(rangeRadiusSliderValues.value1 !== 1 ||
                    rangeRadiusSliderValues.value2 !== 20) && (
                      <>
                        <p className='hs-11'>
                          {rangeRadiusSliderValues.value1}
                          <span> </span>
                          <span>
                            to {`${rangeRadiusSliderValues.value2}`}
                            KM
                            <CloseIcon
                              onClick={handleRangeRadCross}
                              className='hs-2'
                            />
                          </span>
                        </p>
                      </>
                    )}
                  {selectedValues.length !== 0 && (
                    <>
                      {selectedValues?.map((valu, index) => (
                        <p key={index} className='hs-11'>
                          <HouseIcon className='hs-li' /> {valu}
                          <span>
                            <CloseIcon
                              onClick={() => handleSelectedValuesCross(index)}
                              className='hs-2'
                            />
                          </span>
                        </p>
                      ))}
                    </>
                  )}
                  {selectedBathButtons.length !== 0 && (
                    <>
                      {selectedBathButtons.map((valu, index) => (
                        <p key={index} className='hs-11'>
                          <Bathtub className='hs-li' /> {valu}
                          <span>
                            <CloseIcon
                              onClick={() =>
                                handleSelectedBathValuesCross(index)
                              }
                              className='hs-2'
                            />
                          </span>
                        </p>
                      ))}
                    </>
                  )}
                  {selectedBedValues.length !== 0 && (
                    <>
                      {selectedBedValues.map((value, index) => (
                        <p key={index} className='hs-11'>
                          <BedIcon className='hs-li' /> {value}
                          <span>
                            <CloseIcon
                              onClick={() =>
                                handleSelectedBedValuesCross(index)
                              }
                              className='hs-2'
                            />
                          </span>
                        </p>
                      ))}
                    </>
                  )}
                  {selectedParkingButtons.length !== 0 && (
                    <>
                      {selectedParkingButtons.map((value, index) => (
                        <p key={index} className='hs-11'>
                          <LocalParking className='hs-li' /> {value}
                          <span>
                            <CloseIcon
                              onClick={() =>
                                handleSelectedParkValuesCross(index)
                              }
                              className='hs-2'
                            />
                          </span>
                        </p>
                      ))}
                    </>
                  )}
                  {cityinAcc.length !== 0 && (
                    <>
                      {cityinAcc.map((value, index) => (
                        <p key={index} className='hs-11'>
                          <LocationOnIcon className='hs-li' /> {value}
                          <span className=''>
                            <CloseIcon
                              onClick={() =>
                                handleSelectedCityValuesCross(index)
                              }
                              className='hs-2 text-white'
                            />
                          </span>
                        </p>
                      ))}
                    </>
                  )}
                </div>
                <div className='col-lg-4 justifyright m-v-s-h'>
                  <button onClick={handleSearchOpen} className='btn hs-3 px-1'>
                    Save This Search
                  </button>
                  <div className='p-1'></div>
                  <button
                    onClick={handleResetFilters}
                    className='btn hs-4 px-1'
                  >
                    Reset Filters
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className='mobile-sqft-show '>
          <div className={mobileSearch ? 'd-none' : ' '}>
            <RangeSlider
              value1={rangeSliderValues.value1}
              value2={rangeSliderValues.value2}
              onChange={handleRangeSliderChange}
            />
          </div>
        </div>
      </div>

      {citiesFilterData && (
        <div className="my-3 w-100">
          <CategoriesTab citiesFilterData={citiesFilterData} />
        </div>
      )}

      {/* {filterDataSlice[0]?.length === 0 ? (
        <ResultNotFound onClick={handleResetFilters} />
      ) : ( */}
        <>
          <div className='d-flex w-100 align-items-center low-pr-m-v pt-3'>
            <div className='font' style={{ width: '35%' }}>
              {!MapListing && (
                <div className='lprice font'>
                  {sortingHeading}
                  {totalRecordCount !== undefined && (
                    <p className='results textgreen mb-0'>
                      {`(Results ${totalRecordLength}   of
                 ${totalRecordCount})`}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className='d-flex justify-content-end align-items-center pe-4' style={{ width: 'calc(100% - 35%)' }}>
              <div className='d-flex justify-content-end align-items-center' style={{ gap: '15px' }}>
                {activeButton !== 'map' && (
                  <div className='m-0 '>
                    <div className='drop-down'>
                      <button
                        className='btn btngreencolor sizing'
                        type='button'
                        id='dropdownMenuButton2'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        <FaSort /> Sorting
                      </button>

                      <ul
                        className='dropdown-menu menuwidth1 p-2'
                        aria-labelledby='dropdownMenuButton1'
                      >
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'PLH'}
                                onChange={() => handleSortingChange('PLH')}
                              />
                            }
                            label={
                              <span className='font'>Price (Low to High)</span>
                            }
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'PHL'}
                                onChange={() => handleSortingChange('PHL')}
                              />
                            }
                            label={
                              <span className='font'>Price (High to Low)</span>
                            }
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'PSLH'}
                                onChange={() => handleSortingChange('PSLH')}
                              />
                            }
                            label={
                              <span className='font'>
                                Price per sqft(Low to High)
                              </span>
                            }
                          />
                        </li>{' '}
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'PSHL'}
                                onChange={() => handleSortingChange('PSHL')}
                              />
                            }
                            label={
                              <span className='font'>
                                Price per sqft(High to Low)
                              </span>
                            }
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'SLH'}
                                onChange={() => handleSortingChange('SLH')}
                              />
                            }
                            label={
                              <span className='font'>
                                Square Feet (Low to High)
                              </span>
                            }
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'SHL'}
                                onChange={() => handleSortingChange('SHL')}
                              />
                            }
                            label={
                              <span className='font'>
                                Square Feet (High to Low)
                              </span>
                            }
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'CLF'}
                                onChange={() => handleSortingChange('CLF')}
                              />
                            }
                            label={<span className='font'>Closest First</span>}
                          />
                        </li>
                        <li className=''>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sortingOption === 'NE'}
                                onChange={() => handleSortingChange('NE')}
                              />
                            }
                            label={<span className='font'>Newest First</span>}
                          />
                        </li>
                        {/* <li className="">
                       <FormControlLabel
                         control={<Checkbox />}
                         label={
                           <span className="font">Pre-Launching Price</span>
                         }
                       />
                     </li> */}
                      </ul>
                    </div>
                  </div>
                )}

                <div className=''>
                  <IconButton
                    className='p-0'
                    color='secondary'
                    aria-label='Mixed'
                    onClick={handleMixedClick}
                  >
                    <div
                      className={
                        activeButton === 'mixed'
                          ? 'hearingIcon'
                          : 'duphearingIcon'
                      }
                    >
                      <CloseFullscreenIcon
                        className={
                          activeButton === 'mixed'
                            ? 'hearingIcon1'
                            : 'duphearingIcon1'
                        }
                      />
                      <p
                        className={
                          activeButton === 'mixed'
                            ? 'hearingIcon2'
                            : 'duphearingIcon2'
                        }
                      >
                        Mixed
                      </p>
                    </div>
                  </IconButton>
                </div>
                <div className=''>
                  <IconButton
                    className='p-0'
                    color='secondary'
                    aria-label='List'
                    onClick={handleListClick}
                  >
                    <div
                      className={
                        activeButton === 'list'
                          ? 'hearingIcon'
                          : 'duphearingIcon'
                      }
                    >
                      <ListIcon
                        className={
                          activeButton === 'list'
                            ? 'hearingIcon1'
                            : 'duphearingIcon1'
                        }
                      />
                      <p
                        className={
                          activeButton === 'list'
                            ? 'hearingIcon2'
                            : 'duphearingIcon2'
                        }
                      >
                        List
                      </p>
                    </div>
                  </IconButton>
                </div>
                <div className=''>
                  <IconButton
                    className='p-0'
                    color='secondary'
                    aria-label='Map'
                    onClick={handleMapClick}
                  >
                    <div
                      className={
                        activeButton === 'map'
                          ? 'hearingIcon'
                          : 'duphearingIcon'
                      }
                    >
                      <MapIcon
                        className={
                          activeButton === 'map'
                            ? 'hearingIcon1'
                            : 'duphearingIcon1'
                        }
                      />
                      <p
                        className={
                          activeButton === 'map'
                            ? 'hearingIcon2'
                            : 'duphearingIcon2'
                        }
                      >
                        Map
                      </p>
                    </div>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mv-more-search mt-3">
              <div
                className={
                  mobileSearch
                    ? "d-none d-flex justify-content-between "
                    : "d-flex justify-content-between  "
                }
              >
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <button
                      style={{ borderColor: "#0253a0" }}
                      className="btn hs-3 bg-white "
                      onClick={toggleDrawer(anchor, true)}
                      variant=" "
                    >
                      <span className="text-primary">Filters</span>
                      <BiFilter
                        className="icoonfont"
                        style={{
                          color: "#0253a0",
                          width: "1.5rem",
                          height: "1.5rem",
                          paddingBottom: "2px",
                        }}
                      />{" "}
                    </button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      sx={{
                        "& .MuiDrawer-paper": {
                          borderTopLeftRadius: "30px",
                        },
                      }}
                    >
                      {list(anchor, selectedValues)}
                    </Drawer>
                  </React.Fragment>
                ))}

                <div className="p-1"></div>
                <button
                  type="submit"
                  onClick={() => {
                    handleFilterForm();
                    setMobileSearch(true);
                  }}
                  // onClick={handleFilterForm}
                  className="btn hs-3"
                >
                  Search
                </button>
              </div>
            </div> */}
          <div className={isMobileView ? 'd-block' : 'd-none'}>
            <div className='container  d-flex justify-content-around'>
              {activeButton !== 'map' && (
                <div>
                  <button
                    className='m-vfilterBtn m_vsortbtn'
                    type='button'
                    id='dropdownMenuButton2'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <FaSort /> Sort
                  </button>

                  <ul
                    className='dropdown-menu menuwidth1 p-2'
                    aria-labelledby='dropdownMenuButton1'
                  >
                    <li className=''>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sortingOption === 'PLH'}
                            onChange={() => handleSortingChange('PLH')}
                          />
                        }
                        label={<span className='font'>Price (Low to High)</span>}
                      />
                    </li>
                    <li className=''>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sortingOption === 'PHL'}
                            onChange={() => handleSortingChange('PHL')}
                          />
                        }
                        label={<span className='font'>Price (High to Low)</span>}
                      />
                    </li>
                    <li className=''>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sortingOption === 'SLH'}
                            onChange={() => handleSortingChange('SLH')}
                          />
                        }
                        label={
                          <span className='font'>Square Feet (Low to High)</span>
                        }
                      />
                    </li>
                    <li className=''>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sortingOption === 'SHL'}
                            onChange={() => handleSortingChange('SHL')}
                          />
                        }
                        label={
                          <span className='font'>Square Feet (High to Low)</span>
                        }
                      />
                    </li>
                    <li className=''>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sortingOption === 'CLF'}
                            onChange={() => handleSortingChange('CLF')}
                          />
                        }
                        label={<span className='font'>Closest First</span>}
                      />
                    </li>
                    <li className=''>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sortingOption === 'NE'}
                            onChange={() => handleSortingChange('NE')}
                          />
                        }
                        label={<span className='font'>Newest First</span>}
                      />
                    </li>
                  </ul>
                </div>
              )}
              {['right'].map(anchor => (
                <React.Fragment key={anchor}>
                  <button
                    className='m-vfilterBtn'
                    onClick={toggleDrawer(anchor, true)}
                    variant='outlined'
                  >
                    <BiFilter
                      className='icoonfont'
                      style={{
                        color: '#ffff',
                        fontSize: '24px'
                      }}
                    />{' '}
                    <span>Filters</span>
                  </button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    sx={{
                      '& .MuiDrawer-paper': {
                        borderTopLeftRadius: '30px'
                      }
                    }}
                  >
                    {list(anchor, selectedValues)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <div className={`${MapListing ? 'justify-content-center' : 'justify-content-between'} mt-3 ps-2 d-flex`}>
              <div className={`${MapListing ? 'save_searchOnmap' : ''}`}>
                <p className='results mobileFont17 m-0 p-0 px-2'>
                  {sortingHeading}
                </p>
                {totalRecordCount !== undefined && (
                  <p
                    className='results  mobileFont17 mobileFont_16 m-0 px-2'
                    style={{ color: '#0253a0' }}
                  >
                    {`(Results ${totalRecordLength}   of
                ${totalRecordCount})`}
                  </p>
                )}
              </div>

              {saveSeachShow && (
                <div className='m-0 px-4 d-flex justify-content-end align-items-end'>
                  <button className='m-v__Buttons' onClick={handleSearchOpen}>
                    <span className='m-0 p-0'>Save Search</span>{' '}
                    <BiBookmarkPlus
                      style={{
                        fontWeight: 'bolder',
                        fontSize: '18px',
                        color: '#0253a0'
                      }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className={isMobileView ? '' : 'lpsorting'}></div>
          <div className='mapandcard mb-5'>
            <MapandListing
              scrollPage={scrollPage}
              setScrollPage={setScrollPage}
              totalPageCount={totalPageCount}
              isLoading={isLoading}
              scrollLoading={scrollLoading}
              setScrollLoading={setScrollLoading}
              ApiFilter={ApiFilter}
              onFilteredData1={handleFilteredData}
              showListing={showListing}
              loadMore={loadMore}
              setLoadMore={setLoadMore}
              sortInitiated={sortInitiated}
              setSortInitiated={setSortInitiated}
              MapListing={MapListing}
              MixedListing={MixedListing}
              handleMapClick={handleMapClick}
              handleListClick={handleListClick}
              toggleIcon={toggleIcon}
              insidePolygon={insidePolygon}
              setInsidePolygon={setInsidePolygon}
              categoryLocation={categoryLocation}
              centerPopUP={centerPopUP}
              resetTrigger={resetTrigger}
            />
          </div>
        </>
      
      {scrollLoading && (
        <div className='scrollLoader'>
          <Loader size='80' />
          <p style={{ color: '#F8A23E', fontWeight: 600 }}>Loading....</p>
        </div>
      )}

    </>
  )
}
