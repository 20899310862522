import "./FinanceOption.css";
import Footer from "../Footer/Footer";
import { IoCall } from "react-icons/io5";
import FaqAccordion from "../SellProperty/Faq";
import { HashLink as Link } from 'react-router-hash-link';
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

const FinanceOption = () => {
  return (
    <>

      <HelmetLayout pageName={'finance-details'} canonicalLink={'finance-details'} />

      <div className="finace_font">
        {/* ----------------- Finance Main Headings Section--------------------- */}
        <div className="d-flex text-white w-100 p-4 finance_container">
          <div className="container finance_container">
            <h2 className="Finance_Heading">
              Finance your AssignmentSold package Several payment options
            </h2>

            <div>
              <p className="Finance_para">
                "Experience our services at an all-time affordable rate! When you
                choose from our range of packages, each designed with flexible
                durations and levels of support, you also gain access to a diverse
                set of payment options.
              </p>
              <p className="Finance_para">
                What's more, when you sell with AssignmentSold, you enjoy the
                backing of a seasoned professional team and extraordinary
                visibility – all without the burden of a commission fee!"
              </p>
            </div>
            <div className="mt-3">
              <Link smooth to='/sell#pricing'>
                <button className="financepackage_btn">See our packages</button>
              </Link>
            </div>
          </div>
        </div>
        {/* ----------------- Finance Image and Title Section--------------------- */}

        <div className="finaciet_img mt-5">
          <img src="/images/Financepage/Financiet.png" alt="financiet" loading="lazy"/>
        </div>
        <div className="finaciet_des mt-5">
          <p>
            With our trusted partner Financeit, you have the option of paying at
            your own pace by taking advantage of our financing offer.
          </p>
        </div>

        {/* ----------------- Finance Image and Terms Section--------------------- */}

        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6 col-12">
              <div className="w-100">
                <img
                  className="w-100"
                  src="images/Financepage/FinanceTerms.png"
                  alt="financiet terms"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-6 col-12 financiat_terms">
              <h4>Terms</h4>
              <ol>
                <li>
                  Gold and Silver packages offer a 6-month deferred payment option
                  with no interest or additional fees.
                </li>
                <li>
                  Choose from equal monthly payment plans spanning 12, 24, or 36
                  months.
                </li>
                <li>
                  Enjoy the freedom of full or partial repayment at any time,
                  without incurring penalties.
                </li>
                <li>
                  Rest easy with secure financing, protected by top-notch 256-bit
                  SSL encryption (AES) technology.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="Finance_getFinancing">
          <h2>How to get financing?</h2>
          <p>"Check your eligibility without any impact on your credit score.</p>
          <p>
            With an acceptance rate of over 86%, our clients often find the
            process hassle-free and convenient."
          </p>
        </div>

        {/* ----------------- Finance Cards Section--------------------- */}

        <div className="Finance_Cards mt-5">
          <div className="FinanceCallCard">
            <img className="call_Img" src="images/Financepage/mdi_face-agent.png" alt="Support Agent" loading="lazy"/>
            <h6>Talk to a AssignmentSold advisor</h6>
            <p>
              Get help checking your eligibility and inquire about our packages
              and financing plan.
            </p>
            <button className="FinanceCallCard_btn">
              {" "}
              <IoCall /> +1 (647) 909-489
            </button>
          </div>
          <div className="FinanceCallCard">
            <img className="call_Img" src="images/Financepage/FinanceLaptop.png" alt="Apply Online" loading="lazy"/>
            <h6>Apply online with our partner</h6>
            <p>
              "Instantly find out if you're eligible. You can select your package
              or financing plan at a later time. One of our advisors will get in
              touch with you to provide all the necessary information."
            </p>
            <button className="FinanceCallCard_btn"> Ask for Financing</button>
          </div>
        </div>

        {/* ----------------- Finance FAQs Section--------------------- */}

        <div className="Finance_faq mt-5">
          <h2 className="text-center">FAQs</h2>
          <div className="mt-5 d-flex justify-content-center align-items-center flex-column">
            <FaqAccordion />
          </div>
        </div>

        {/* ----------------- Finance Footer Section--------------------- */}

        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default FinanceOption;
