import { AllPhotoIcons } from "../../../SvgIcons/Icons";
import SignUpModal from "../../Modals/SignUpModal";
import { memo } from "react";

const PropertyImages = ({ isUserlogin, setImageModalOpen, isMobileView }) => {
  return (
    <>
      {/* {isUserlogin ? (
        <div className="Prop_details_galleryContiner">
          <div
            className="PropDetails_galleryMainImg Prop_thubnail_img position-relative property_details_mainImage"
            onClick={() => setImageModalOpen(true)}
          >
            <img
              src="https://beta.needhome.ca/assets/images/bridge.jpeg"
              alt="PropertyImg"
              loading="lazy"
            />
          </div>

          <div className="PropDetails_gallerySubImgContainer PropDetailsMob_lg_hide">
            <div
              className="PropDetails_gallerySubImg"
              onClick={() => setImageModalOpen(true)}
            >
              <img
                src="https://beta.needhome.ca/assets/images/bridge.jpeg"
                alt="PropertyImg"
                loading="lazy"
              />
            </div>
            <div className="PropDetails_gallerySubImg2Container">
              <div
                className="PropDetails_gallerySubimg2"
                onClick={() => setImageModalOpen(true)}
              >
                <img
                  src="https://beta.needhome.ca/assets/images/bridge.jpeg"
                  alt="PropertyImg"
                  loading="lazy"
                />
              </div>
              <div
                className="PropDetails_gallerySubimg2"
                onClick={() => setImageModalOpen(true)}
              >
                <img
                  src="https://beta.needhome.ca/assets/images/bridge.jpeg"
                  alt="PropertyImg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <button
            className="PropDetails_allImgBtn d-flex align-items-center"
            onClick={() => setImageModalOpen(true)}
          >
            <AllPhotoIcons /> <span>{`View all 6 Photos`}</span>
          </button>
        </div>
      ) : (
        <div className="w-100 position-relative h-100">
          {isMobileView ? (
            <img
              src="/images/blurMobileImage.png"
              alt="Blur-Listing"
              className="w-100"
            />
          ) : (
            <img
              src="/images/blurImage.png"
              alt="Blur-Listing"
              className="w-100 h-100"
              style={{minHeight: '280px'}}
            />
          )}
          <div className="position-absolute d-flex align-items-center justify-content-center top-0 h-100 w-100">
            <button
              className="propertyDetails_moreInfo"
              style={{
                height: "fit-content",
                padding: "10px 16px",
                fontSize: isMobileView ? "16px" : "25px",
              }}
            >
              Create an account
            </button>
          </div>
        </div>
      )} */}

      <div className="Prop_details_galleryContiner">
        <div
          className="PropDetails_galleryMainImg Prop_thubnail_img position-relative property_details_mainImage"
          onClick={() => setImageModalOpen(true)}
        >
          <img
            src="https://beta.needhome.ca/assets/images/bridge.jpeg"
            alt="PropertyImg"
            loading="lazy"
          />
        </div>

        <div className="PropDetails_gallerySubImgContainer PropDetailsMob_lg_hide">
          <div
            className="PropDetails_gallerySubImg"
            onClick={() => setImageModalOpen(true)}
          >
            <img
              src="https://beta.needhome.ca/assets/images/bridge.jpeg"
              alt="PropertyImg"
              loading="lazy"
            />
          </div>
          <div className="PropDetails_gallerySubImg2Container">
            <div
              className="PropDetails_gallerySubimg2"
              onClick={() => setImageModalOpen(true)}
            >
              <img
                src="https://beta.needhome.ca/assets/images/bridge.jpeg"
                alt="PropertyImg"
                loading="lazy"
              />
            </div>
            <div
              className="PropDetails_gallerySubimg2"
              onClick={() => setImageModalOpen(true)}
            >
              <img
                src="https://beta.needhome.ca/assets/images/bridge.jpeg"
                alt="PropertyImg"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <button
          className="PropDetails_allImgBtn d-flex align-items-center"
          onClick={() => setImageModalOpen(true)}
        >
          <AllPhotoIcons /> <span>{`View all 6 Photos`}</span>
        </button>
      </div>
    </>
  );
};

export default memo(PropertyImages);
