import { Routes, Route } from "react-router-dom";
import WebsitesRoutes from "./WebsitesRouts";
import DashboardRoutes from "./DashboardRoutes";
import NotFoundPage from "../Components/404Page/NotfoundPage";
import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AppRoutes = () => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location?.pathname);
  const routeChanged =
    location.pathname !== prevPathname &&
    !location.pathname.startsWith("/assignment-sale/");

  useLayoutEffect(() => {
    if (routeChanged) {
      if (location.pathname === "/sell" && location.hash === "#pricing") {
        window.scrollTo(0, 0);
      } else {
        setTimeout(() => {
          document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
        }, 1);
      }
      setPrevPathname(location.pathname);
    }
  }, [location.pathname, location.hash, routeChanged]);

  return (
    <>
      <Routes>
        <Route path="/dashboard/*" element={<DashboardRoutes />} />
        <Route path="*" element={<WebsitesRoutes />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
