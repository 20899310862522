import React, { useState, useEffect,memo } from "react";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { TaxHistoryIcon } from "../../../SvgIcons/Icons";

const TaxHistory = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <TaxHistoryIcon />}
          <h2 className="property_Details_heading">Tax History</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Understand the financial landscape with detailed tax history for a
              clearer picture of property costs.
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Understand the financial landscape with detailed tax history for a
            clearer picture of property costs.
          </p>
        )}
        <div
          className="w-100 mt-3 PropDetails_HideScrollBar position-relative"
          style={{ overflowX: "auto" }}
        >
          <table className="Property_floorPlan_table Property_taxHistory_table w-100">
            <thead className="w-100">
              <tr className="w-100">
                <th style={{ width: "20%" }}>Year</th>
                <th style={{ width: "20%" }}>Tax Paid</th>
                <th style={{ width: "20%" }}>Tax Assessment</th>
                <th style={{ width: "20%" }}>Land</th>
                <th style={{ width: "20%" }}>Improvement</th>
              </tr>
            </thead>
            {PropertyData?.taxHistory?.map((item, index) => (
              <tbody key={index}>
                <tr
                  style={{
                    borderBottom:
                      index === PropertyData?.taxHistory.length - 1
                        ? "none"
                        : "1px solid #EAECF0",
                  }}
                >
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.taxHistory.length - 1
                          ? "0"
                          : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">
                      {item?.year}
                    </span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.taxHistory.length - 1
                          ? "0"
                          : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">
                      {`$${item?.taxPaid?.toLocaleString("en-US")}`}
                    </span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.taxHistory.length - 1
                          ? "0"
                          : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">{`$${item?.taxAssessment?.toLocaleString(
                      "en-US"
                    )}`}</span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.taxHistory.length - 1
                          ? "0"
                          : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">{`$${item?.land?.toLocaleString(
                      "en-US"
                    )}`}</span>
                  </td>
                  <td
                    style={{
                      paddingBottom:
                        index === PropertyData.taxHistory.length - 1
                          ? "0"
                          : "10px",
                    }}
                  >
                    <span className="property_floorPlan_price fw-medium">
                      {`$${item?.improvement?.toLocaleString("en-US")}`}
                    </span>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(TaxHistory);
