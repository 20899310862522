import React from 'react'

import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './CarouselButon'
import useEmblaCarousel from 'embla-carousel-react'
import CardMain from '../../../../../HomePage/SubComponents/MapandListing/CardMain'

const EmblaCarousel = (props) => {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((item , index) => (
           <div className='embla__slide' key={index}>
            <CardMain item={item}/>
            </div>
          ))}
        </div>
      </div>

          <PrevButton  onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
    </section>
  )
}

export default EmblaCarousel
