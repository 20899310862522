import React from "react";
import { Rating } from "react-simple-star-rating";
import { RatingStarFillIcon, RatingStarOutlineIcon } from "../../../../SvgIcons/Icons";

const ReviewsRating = ({
  value,
  ratingChanged,
  size,
  color,
  mainClassName,
  isReadOnly
}) => {

  const handleRating = (rate) => {
    ratingChanged(rate); 
  };

  return (
    <Rating
      iconsCount={5}
      initialValue={value}
      allowFraction={false}
      onClick={(e)=>handleRating(e)}
      fillIcon={<RatingStarFillIcon size={size ?? '24'} color={color ?? '#4A4A4A'} />}
      emptyIcon={<RatingStarOutlineIcon size={size ?? '24'} color={color ?? '#676767'} />}
      fillClassName={mainClassName}
      emptyClassName={mainClassName}
      readonly={isReadOnly}
    />
  );
};

export default ReviewsRating;
