import React from "react";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./ListingComparable/Carousel/CarouselButon";
import useEmblaCarousel from "embla-carousel-react";

const GalleryCarousel = () => {
  const OPTIONS = {};
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const slides = [
    "https://beta.needhome.ca/assets/images/bridge.jpeg",
    "https://beta.needhome.ca/assets/images/bridge.jpeg",
    "https://images.homes.com/listings/211/7151321093-811746181/5507-23rd-ave-s-seattle-wa-8.jpg?t=p",
    "https://beta.needhome.ca/assets/images/bridge.jpeg",
    "https://beta.needhome.ca/assets/images/bridge.jpeg",
    "https://images.homes.com/listings/114/9361321093-811746181/5507-23rd-ave-s-seattle-wa-22.jpg",
    "https://images.homes.com/listings/114/5712321093-811746181/5507-23rd-ave-s-seattle-wa-24.jpg",
  ];

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((item, index) => (
            <div className="gallery_embla__slide" key={index}>
              <img src={item} alt="PropertyImg" loading="lazy" />
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
};

export default GalleryCarousel;
