import React, { useState, useEffect } from "react";
import {
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  ArrowDownIcon,
  ScrollArrowIcon,
  ScrollArrowUpIcon,
} from "../../../../../SvgIcons/Icons";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="Prop_lineChart_custom_tooltip">
        <p className="Prop_lineChart_custom_tooltip_year">{`Year: ${label}`}</p>
        <p className="Prop_lineChart_custom_tooltip_price">{`$ ${payload[0]?.payload?.price?.toLocaleString(
          "en-US"
        )}`}</p>
      </div>
    );
  }

  return null;
};

const SimpleLineChart = ({ data }) => {
  const [compare, setCompare] = useState(data[0]?.year || "");
  const [statistics, setStatistics] = useState(data[data?.length - 1]);

  useEffect(() => {
    setCompare(data[0]?.year);
    setStatistics(data[data?.length - 1]);
  }, [data]);

  const handleCompare = (event) => {
    setCompare(event.target.value);
  };

  const filteredData = data.filter((item) => item.year >= compare);
  const previousYearData = data.find(
    (item) => item.year === String(Number(statistics?.year) - 1)
  );
  const priceDifference =
    statistics && previousYearData
      ? statistics.price - previousYearData.price
      : 0;
  const pricePercentageChange = previousYearData
    ? ((priceDifference / previousYearData.price) * 100).toFixed(2)
    : 0;

  const handleMouseMove = (e) => {
    if (e.isTooltipActive) {
      const activeTooltipData = e.activePayload?.[0]?.payload;
      if (activeTooltipData) {
        setStatistics(activeTooltipData);
      }
    }
  };

  return (
    <div className="PropDetailsGraph_scroll">
      <div
        className="Prop_details_simpleChart_container mt-2 w-100"
        style={{ minWidth: "700px" }}
      >
        <div className="d-flex justify-content-between mb-4">
          <div className="d-flex align-items-end">
            <h6 className="ps-4 prop_price_show">
              {`$${statistics?.price?.toLocaleString("en-US")}`}
              <span>/sqft</span>
            </h6>
            <p
              className={`${
                priceDifference >= 0
                  ? "prop_show_upgrade"
                  : "prop_show_downgrade"
              } m-0 ps-4 d-flex align-items-center`}
            >
              {priceDifference >= 0 ? (
                <ScrollArrowUpIcon />
              ) : (
                <ScrollArrowIcon color={"red"} size={16} />
              )}{" "}
              {`${Math.abs(pricePercentageChange)}% (${
                statistics?.sales
              } sales)`}
            </p>
          </div>
          <div style={{ width: "217px" }}>
            <Select
              labelId="Select"
              id="terms"
              value={compare}
              onChange={handleCompare}
              sx={{
                borderRadius: "10px",
                padding: "9px 12px",
                width: "100%",
              }}
              IconComponent={ArrowDownIcon}
            >
              {data.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.year}
                >{`Compare from ${item.year}`}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={390}>
          <AreaChart
            data={filteredData}
            className="Prop_details_simpleChart"
            onMouseMove={handleMouseMove}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis
              tickFormatter={(value, index) =>
                index === 0 ? "" : `$${value?.toLocaleString("en-Us")}`
              }
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="price"
              stroke="#0253A0"
              fill="#E2F1FF"
              strokeWidth="2px"
              dot={false}
              activeDot={{ r: 8 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SimpleLineChart;
