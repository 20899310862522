import React from 'react';
import { Line, Bar, Tooltip, YAxis, XAxis, CartesianGrid, ResponsiveContainer, ComposedChart } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0]?.payload;
    return (
      <div className="Prop_lineChart_custom_tooltip">
        <p className='Prop_lineChart_custom_tooltip_year text-center'>{`${label}`}</p>
        <p className='Prop_lineChart_custom_tooltip_Des' style={{ color: '#008733' }}>{`Community popularity: ${data?.popularity}`}</p>
        <p className='Prop_lineChart_custom_tooltip_Des' style={{ color: '#F6941C' }}>{`Sold count: ${data?.sold}`}</p>
      </div>
    );
  }

  return null;
};


const CommunityComposedChart = ({ data }) => {

  const getColorByValue = (value) => {
    if (value > 500) {
      return 'red'; // Hot
    } else if (value > 100) {
      return '#008733'; // Medium
    } else {
      return '#F6941C'; // Cold
    }
  };

  const getGradientOffsets = (data) => {
    return data.map((entry, index) => {
      const offset = (index / (data.length - 1)) * 100;
      return { offset: `${offset}%`, color: getColorByValue(entry.popularity) };
    });
  };

  const gradientOffsets = getGradientOffsets(data);

  // Custom legend items with colors and labels
  const CustomLegend = () => (
    <div className="d-flex align-items-center justify-content-center mb-3" style={{ gap: '20px' }}>
      <div className="d-flex align-items-center">
        <span className='composed_chart_legend_cirlce' style={{ backgroundColor: 'red' }}></span>
        <span className="ps-2 composed_chart_legend_text">Hot</span>
      </div>
      <div className="d-flex align-items-center">
        <span className='composed_chart_legend_cirlce' style={{ backgroundColor: '#008733' }}></span>
        <span className="ps-2 composed_chart_legend_text">Medium</span>
      </div>
      <div className="d-flex align-items-center">
        <span style={{ backgroundColor: '#F6941C' }} className='composed_chart_legend_cirlce'></span>
        <span className="ps-2 composed_chart_legend_text">Cold</span>
      </div>
      <div className="d-flex align-items-center">
        <span style={{ backgroundColor: '#0253A0' }} className='composed_chart_legend_cirlce'></span>
        <span className="ps-2 composed_chart_legend_text">Sold</span>
      </div>
    </div>
  );

  return (
    <div className='PropDetailsGraph_scroll'>
      <div className='Prop_details_simpleChart_container mt-2 w-100' style={{ paddingBottom: '45px', minWidth: '700px' }}>
        <div className="legend-container">
          <CustomLegend />
        </div>
        <ResponsiveContainer width="100%" height={390}>
          <ComposedChart data={data} className='Prop_details_simpleChart Prop_details_soldChart' {...{
            overflow: 'visible'
          }}>
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
                {gradientOffsets.map((stop, index) => (
                  <stop key={index} offset={stop.offset} stopColor={stop.color} />
                ))}
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" angle={90} textAnchor='start' />
            <YAxis />
            <Tooltip content={<CustomTooltip />} position={{ x: 'auto', y: 'auto' }} />
            {/* <Legend verticalAlign='top' iconType='circle' /> */}
            <Bar type="monotone" dataKey="sold" name="Sold" fill="#0253a0" activeBar={{ fill: '#0253a0' }} barSize={23} />
            <Line
              type="monotone"
              dataKey="popularity"
              stroke="url(#colorGradient)"
              strokeWidth="2px"
              dot={false}
              activeDot={{ r: 8, fill: '#fff', stroke: '#F6941C', strokeWidth: 3 }}
              name="Popularity"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CommunityComposedChart;
