import { useEffect, useState,memo } from "react";
import {
  AirConditionIcon,
  ParkingIcon,
  BuildingInsuranceIcon,
  CommonElementIcon,
  HydroIcon,
  HeatIcon,
  WaterIcon,
  FinancialDetailIcon,
} from "../../../SvgIcons/Icons";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const MaintenanceFee = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const renderIcons = {
    Water: <WaterIcon />,
    Heat: <HeatIcon />,
    Hydro: <HydroIcon />,
    "Air Conditioning": <AirConditionIcon />,
    "Common Element": <CommonElementIcon />,
    "Building Insurance": <BuildingInsuranceIcon />,
    Parking: <ParkingIcon />,
  };

  return (
    <div className="">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion PropertyDetails_MobileAccordion Accordion_withBlueBackground py-2"
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusIcon" />
            ) : (
              <AddIcon className="Property_plusIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && (
              <FinancialDetailIcon size={"24"} color={"#282828"} />
            )}
            <h2 className="property_Details_heading">Maintenance Fee</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                All essentials covered—enjoy hassle-free living with our
                comprehensive maintenance fee.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              All essentials covered—enjoy hassle-free living with our
              comprehensive maintenance fee.
            </p>
          )}
          <div className="w-100 row mt-3 mbl_marginTop0">
            {PropertyData?.IncludeInMaintanceFee?.map((item, i) => {
              return (
                <div className="col-md-4 col-12 py-2" key={i}>
                  <p className="property_amenities">
                    {renderIcons[item]}
                    <span className="ps-3 PropertyDetails_Mbl_f14">{item}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(MaintenanceFee);
