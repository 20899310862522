import React from 'react'
import SoldDistributionChart from './SoldDistributionChart';

const SoldDistribution = () => { 

    const data = [
        { "year": "2016-10", "price": 4800, "sales": 600 },
        { "year": "2017", "price": 3000, "sales": 100 },
        { "year": "2018", "price": 2000, "sales": 400 },
        { "year": "2019", "price": 2780, "sales": 200 },
        { "year": "2020-06", "price": 1890, "sales": 300 },
        { "year": "2021-09", "price": 2390, "sales": 300 },
        { "year": "2022", "price": 3490, "sales": 50 }
    ];

    return (
        <div className='w-100'>
               <h2 className='property_Details_heading'>Sold Price distribution (recent 6 months)</h2>
            <p className='PropertyDetails_Buy_reason PropertyDetails_Mbl_f14'>Overview of sold price for Condo Apt in Waterfront Communities C8, Toronto in the last 180 days</p>

            <div>
                <SoldDistributionChart data={data}/>
                </div>
        </div>
    )
}

export default SoldDistribution
