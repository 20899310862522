import React, { useState, memo } from "react";
import { IncentivesIcon, TicWithkBorderIcon } from "../../../SvgIcons/Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Incentives = ({ data, isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const incentivesToShow = data?.incentiveDetail?.replace(/\n/g, "<br />");

  return (
    <div className="">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion PropertyDetails_MobileAccordion DetailsAccordion_withborder py-2"
      >
        <AccordionSummary
          expandIcon={
            isMobileView &&
            (expanded ? (
              <RemoveIcon className="Property_minusIcon" />
            ) : (
              <AddIcon className="Property_plusIcon" />
            ))
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <IncentivesIcon />}
            <h2 className="property_Details_heading">Incentives</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 mt-1">
            Unlock exclusive deals and incentives that make your investment even
            more rewarding.
          </p>
          {data?.incentiveDetail ? (
            <p
              className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 mb-0"
              dangerouslySetInnerHTML={{ __html: incentivesToShow }}
            />
          ) : null}

          {data?.preIncentiveDetails?.length > 0 && (
            <div className="w-100 mt-4 mbl_marginTop0 property_incetives_columns">
              {data?.preIncentiveDetails?.map((item, i) => {
                return (
                  <div className="py-2" key={i}>
                    <p className="property_amenities d-flex alig-items-start">
                      <span>
                        <TicWithkBorderIcon />
                      </span>
                      <span className="ps-3 pt-1 PropertyDetails_Mbl_f14">
                        {item}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(Incentives);
