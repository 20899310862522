import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import BlogCard from '../../../Components/Blogs/SubComponents/BlogsCards/BlogCard';
import axios from 'axios';
import baseURL from '../../../config';
import './LatestBlogs.css'
import { useNavigate } from 'react-router-dom';


const LatestBlogs = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/blog/featured`);
            setData(response?.data?.data)
            setLoading(false)
        } catch (error) {
            toast.error('Error Fetching Blogs');
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='latestBlog_container'>

            <div className='w-100'>
                <h4>Latest home Posts</h4>
                <p className='latestBlog_tagline'>Unlock the Full Spectrum of Services and Support for a Seamless Selling Experience</p>

                    <div className='d-flex flex-wrap All_latestBlog_cards'>
                        {(loading ? Array.from(new Array(4)) : data?.slice(0, 4))?.map((item, index) => {
                            return <BlogCard item={item} key={index} loading={loading} />
                        })}
                </div>

                <div className='w-100 text-center'>
                <button onClick={()=>navigate('/blogs/Buying')}>See All Post</button>
                </div>

            </div>

    
        </div>
    )
}

export default LatestBlogs
