import Marquees from "./SubComponents/Marquee/Marquees";
import CallAgent from "../Components/ContactAgent/CallAgent";
import HeaderSection from "./SubComponents/HeaderSection/HeaderSection";
import CategorySearching from "./SubComponents/CategorySearching/CategorySearching";
import FullWidthTabs from "./SubComponents/Tabpanel/FullWidthTabs";
import Footer from "../Components/Footer/Footer";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import OurPromiseFromBuyer from "./SubComponents/OurPromiseForBuyer/OurPromiseForBuyer";
import LatestBlogs from "./SubComponents/LatestBlogs/LatestBlogs";
import HelmetLayout from "../Utils/HelmetLayout/Helmet";
import { ToastContainer } from "react-toastify";

const Home = () => {
  const [categoryLocation, setCategoryLocation] = useState();
  const [categoryBType, setCategoryBType] = useState();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>

      <HelmetLayout pageName={'Home-page'} />

      <div style={{ position: "sticky", top: 0, zIndex: 1100 }}>
        {/* <Navbar navBarRef={navBarRef}/> */}
      </div>
      {/* <div className="container heading_logo">
        <Heading />
      </div> */}
      <Marquees />

      <div>
        <div className={isMobileView ? "container p-1 " : "container p-3"}>
          <div>
            <CallAgent />
          </div>
          <HeaderSection
            categoryLocation={categoryLocation}
            setCategoryLocation={setCategoryLocation}
            categoryBType={categoryBType}
            setCategoryBType={setCategoryBType}
          />
        </div>

        <div className="categorysection greencolor">
          <CategorySearching
            setCategoryLocation={setCategoryLocation}
            setCategoryBType={setCategoryBType}
          />
        </div>

        <div className="container">
          {isMobileView ? (
            <div className="justifycenter">
              <div>
                <img
                  className="Tab__ICon"
                  src={require("../Components/Images/icon.png")}
                  alt="AssignmentSold Logo"
                  loading="lazy"
                />
              </div>
            </div>
          ) : (
            <div className="text-center mb-5 mt-5 coloring ">
              <h2 className="diff font">
                The<span className="textgreen"> AssignmentSold.ca</span>{" "}
                Difference
              </h2>
              <p className="diff1">
                Forward thinking real estate trusted for over 25 years
              </p>
              <p className="diff1">Here’s how it works</p>
            </div>
          )}

          <div>
            <FullWidthTabs />
          </div>
        </div>

        {/* ------- AssignmetnSoldPromise Section ---------- */}
        <div>
          <OurPromiseFromBuyer />
        </div>

        {/* ------- AssignmetnSoldPromise Section ---------- */}
        <div>
          <LatestBlogs />
        </div>


        {/* ------- SellProperty  Section ---------- */}

        <section className="d-flex m-v-footer text-white container-fluid justify-content-between greencolor p-4">
          <div className="me-5 ">
            <h2 className="mar ft-11 font">
              Do You want to Sell Your Property?
            </h2>
          </div>
          <div className="me-5 ">
            <NavLink to="/sell" className="textcolor">
              <button className="btn font fbton ">
                List Your Property Now
              </button>
            </NavLink>
          </div>
        </section>


        <div style={{ marginTop: '4rem' }}>
          <Footer />
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default Home;
