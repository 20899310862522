import React, { useState,memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Schools from "./Schools";
import { PropertyData } from "../../PropertData";
import Hospitals from "./Hospitals";
import Grocery from "./Grocery";
import BarsAndResturant from "./BarsAndResturant";
import Transists from "./Transists";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ExplorePropertyIcon } from "../../../../SvgIcons/Icons";

const ExploreProperty = ({ isMobileView }) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const tabLabels = [
    "Schools",
    "Hospitals",
    "Grocery",
    "Bars and Restaurants",
    "Transists",
  ];

  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion"
      sx={{ background: "#fff" }}
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <ExplorePropertyIcon />}
          <h2 className="property_Details_heading">Explore Mimico</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Overview of sold price for Condo Apt in Waterfront Communities C8,
              Toronto in the last 180 days
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Overview of sold price for Condo Apt in Waterfront Communities C8,
            Toronto in the last 180 days
          </p>
        )}

        <Box className="mt-3">
          <AppBar
            position="static"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="inherit"
              sx={{
                backgroundColor: "#fff",
              }}
              className="Property_details_tabs"
              elevation={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabLabels?.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab}`}
                  sx={{
                    border: "1px solid #CCCCCC",
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className="PropertyDetail_tabPanel">
            <Schools data={PropertyData?.ExploreProperty[0]?.schools} />
          </TabPanel>
          <TabPanel value={value} index={1} className="PropertyDetail_tabPanel">
            <Hospitals data={PropertyData?.ExploreProperty[0]?.hospitals} />
          </TabPanel>
          <TabPanel value={value} index={2} className="PropertyDetail_tabPanel">
            <Grocery data={PropertyData?.ExploreProperty[0]?.groccery} />
          </TabPanel>
          <TabPanel value={value} index={3} className="PropertyDetail_tabPanel">
            <BarsAndResturant
              data={PropertyData?.ExploreProperty[0]?.BarAndResturant}
            />
          </TabPanel>
          <TabPanel value={value} index={4} className="PropertyDetail_tabPanel">
            <Transists data={PropertyData?.ExploreProperty[0]?.transists} />
          </TabPanel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ExploreProperty);
