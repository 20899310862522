import React, { useEffect, useState } from 'react'
import './BlogDetails.css';
import Footer from '../../../Footer/Footer';
import Skeleton from '@mui/material/Skeleton';
import axios from "axios";
import baseURL from '../../../../config';
import {useNavigate } from 'react-router-dom';
// import { BiLike } from "react-icons/bi";
// import { BiMessageAdd } from "react-icons/bi";
// import { TbShare3 } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import RelatedArticlesCard from './RelatedArticlesCard';
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from 'react-helmet';

const BlogDetails = () => {

  const originurls = window.location.pathname
  const pathParts = originurls.split('/')
  const blogSlug = pathParts[pathParts.length - 1]
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [relatedBlogsData, setRelatedBlogsData] = useState([])
  const [loading, setLoading] = useState(true)
  const [mainTitle, setMainTitle] = useState('AssignmentSold.ca')
  const [title, setTitle] = useState('AssignmentSold.ca')
  const [description, setDescription] = useState('AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.')
  const [keywords, setKeywords] = useState('assignment sold, assignment sale, condo, townhouse')

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/blog/${blogSlug}`);
      if(response?.data?.data?.blog){
        setData(response?.data?.data?.blog);
      const filteredRelatedData = response?.data?.data?.blogs.filter(blog => blog.slug !== blogSlug);
      setRelatedBlogsData(filteredRelatedData);
      setLoading(false);
      }else{
          navigate('/blogs/Selling');
      }
    }
    catch (error) {
      toast.error('Error fetching data');
    };
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
    // eslint-disable-next-line
  }, [blogSlug])


  //meta seo work
  useEffect(() => {
    if (data.length !== 0) {
      const metaseosTags = data?.blogSeos[0]?.metaSeos
      const mainTitleObject = metaseosTags?.find(metaSeo => metaSeo?.seoTag?.typevalue === "main_Title");
      setMainTitle(mainTitleObject ? mainTitleObject?.content : 'AssignmentSold.ca')
      const titleObject = metaseosTags?.find(metaSeo => metaSeo?.seoTag?.typevalue === "title");
      setTitle(titleObject ? titleObject?.content : 'AssignmentSold.ca')
      const descriptionObject = metaseosTags?.find(metaSeo => metaSeo?.seoTag?.typevalue === "description");
      setDescription(descriptionObject ? descriptionObject?.content : 'AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.')
      const KeywordObject = metaseosTags?.find(metaSeo => metaSeo?.seoTag?.typevalue === "keywords");
      setKeywords(KeywordObject ? KeywordObject?.content : "assignment sold, assignment sale, condo, townhouse")
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={data?.blogMedias?.length > 0 ? `${baseURL}/${data?.blogMedias[0]?.fileUrl?.replace(/\\/g, '/')}` : 'https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png'} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={data?.blogMedias?.length > 0 ? `${baseURL}/${data?.blogMedias[0]?.fileUrl?.replace(/\\/g, '/')}` : 'https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png'} />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:url" content={window.location.href} />
      </Helmet>

      <div className='container mt-4'>
        <div className='row'>
          {/* Blog details Section */}
          <div className='col-md-8'>
            <div className='mb-3 d-flex align-items-center' style={{ width: 'fit-content', cursor: 'pointer' }} onClick={() => navigate(`/blogs/${data?.blogCategory?.slug}`)}>
              <IoIosArrowBack style={{ fontSize: '20px', color: '#676767' }} />
              <span style={{ fontFamily: 'Montserrat', color: '#676767', fontSize: '20px', lineHeight: '140%', marginLeft: '3px' }}>{data?.blogCategory?.name}</span>
            </div>
            <div className='Blog_detailsImg'>
              {loading ?
                <Skeleton animation="wave" variant="rounded" height={250} /> :
                <>
                  {data?.blogMedias?.length > 0 ?
                    <img src={`${baseURL}/${data?.blogMedias[0]?.fileUrl}`} alt={data?.title} loading="lazy"/>
                    :
                    <img
                      src={require("../../../../images/no-image.png")}
                      alt={data?.title}
                      loading="lazy"
                    />
                  }
                </>
              }
            </div>

            {/* <div className='d-flex justify-content-between mt-3'>
              <div className='d-flex'>
                <div className='blog_details_viewsChip'>
                  <BiLike style={{ color: '#7e7e7e', fontSize: '30px' }} />
                  <span>7,457</span>
                </div>
                <div className='blog_details_viewsChip Articlechip_margin ms-3'>
                  <BiMessageAdd style={{ color: '#7e7e7e', fontSize: '30px' }} />
                  <span>325</span>
                </div>
                <div className='blog_details_viewsChip Articlechip_margin ms-3'>
                  <TbShare3 style={{ color: '#7e7e7e', fontSize: '30px' }} />
                  <span>88</span>
                </div>
              </div>
              <div>
                <div className='blog_details_viewsChip ms-3'>
                  <img src='/images/Blogs/archive-add.png' alt='archiveIcon' width={'29px'} />
                  <span>88</span>
                </div>
              </div>
            </div> */}

            <div className='mt-4 blog_detailsTitle'>
              {loading ?
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1.5rem' }} /> :
                <h2>{data?.title}</h2>
              }
            </div>

            {loading ?
              <div className='mt-4'>
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />
              </div> :
              <div className='mt-4 blog_detailsDescription' dangerouslySetInnerHTML={{ __html: data?.des }} />
            }


          </div>

          <div className='col-md-4 ralted_articel_container'>
            <h4 className='realtedArticles__Heading'>{loading ? `Related Topics` : relatedBlogsData?.length > 0 && `Related Topics`}</h4>
            {(loading ? Array.from(new Array(4)) : relatedBlogsData)?.map((item, index) => {
              return <RelatedArticlesCard key={index} data={item} loading={loading} />;
            })}
          </div>

        </div>
      </div>


      <div className='mt-5'>
        <Footer />
      </div>

      <ToastContainer />
    </>
  )
}

export default BlogDetails
