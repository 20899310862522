import React, { useEffect, useState,memo } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DemographicsIcon } from "../../../SvgIcons/Icons";

const Demographics = ({ isMobileView }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="w-100">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
        sx={{ background: "#ffff" }}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <DemographicsIcon />}
            <h2 className="property_Details_heading">Demographics</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Demographic insights reveal key social and economic traits
                essential for effective planning and resource management.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Demographic insights reveal key social and economic traits
              essential for effective planning and resource management.
            </p>
          )}
          <h6 className="mt-3 mbl_marginTop0 population_heading">Population</h6>

          <div className="Demographics_container mt-3">
            <div style={{ width: "30%", minWidth: "165px" }}>
              <PieChart width={150} height={150}>
                <Pie
                  data={PropertyData?.demographics}
                  dataKey="value"
                  cx={70}
                  cy={65}
                  outerRadius={70}
                  innerRadius={50}
                  startAngle={0}
                  endAngle={360}
                  paddingAngle={1}
                >
                  {PropertyData?.demographics.map((entry, index) => (
                    <Cell
                      key={entry.name}
                      fill={entry.color}
                      fillOpacity={
                        activeIndex === null || activeIndex === index ? 1 : 0.2
                      }
                    />
                  ))}
                </Pie>
                <text
                  x={76}
                  y={60}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  className="pie_middle_text"
                >
                  Total
                </text>
                <text
                  x={76}
                  y={82}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  className="pie_middle_text pie_middle_total_text"
                >
                  12,345
                </text>
              </PieChart>
            </div>
            <div className="Chart_listItems">
              <ul className="propDetails_chartsStatistics">
                {PropertyData?.demographics.map((entry, index) => (
                  <li
                    key={entry.name}
                    style={{
                      paddingBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div
                      className="Chart_circle_icon"
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: entry.color,
                        marginRight: "10px",
                        borderRadius: "100%",
                      }}
                    ></div>
                    <span
                      className="PropertyDetails_Mbl_f14"
                      style={{ color: "#282828" }}
                    >
                      {entry.value}%{" "}
                      <span className="fw-bold">{`(${entry.name})`}</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(Demographics);
