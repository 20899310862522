import React from "react";
import { Box, Modal } from "@mui/material";
import { MessageIcon } from "../../SvgIcons/Icons";

const EmailConfirmationModal = ({ open, handleClose }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="Prop_detail_infoModal Prop_detailsValueModal WatchNeighbourHood_inputs">
          <div className="d-flex flex-column align-items-center">
            <div className="propertyDetail_homeValue_icon">
              <MessageIcon />
            </div>
            <h6 style={{ fontSize: "20px" }} className="text-center mt-3">
            Verify account
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-1 text-center">
            Check your email to verify your account.
            </p>

            <div className="mt-2">
                <button
                onClick={handleClose}
                >
                  Okay
                </button>
              </div>
          </div>
      </Box>
    </Modal>
  );
};

export default EmailConfirmationModal;
