import React from 'react'
import { Box, Modal } from '@mui/material'
import { CloseIcon } from '../../../../SvgIcons/Icons'
import GalleryCarousel from '../GalleryCarousel'

const ImagesModal = ({open,handleClose}) => {
  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <Box   className='Prop_detail_GalleryModal'>
       <div className='d-flex justify-content-end'>
        <span style={{cursor:'pointer'}} onClick={handleClose}><CloseIcon color={'#FFF'} size={'30px'}/></span>
       </div>
       <div>
        <GalleryCarousel />
       </div>
    </Box>
</Modal >
  )
}

export default ImagesModal
