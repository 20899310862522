import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import "./CategoryWiseAssignment.css";
import "../../App.css";
import baseURL from "../../config";
import { useDispatch } from "react-redux";
import DrawerContent from "../../HomePage/SubComponents/MapandListing/DrawerContent";
import { Divider, Drawer, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { removePdfData } from "../../ReduxStore/Slices/PdfDownloadSlice";
import Loader from "../../HomePage/SubComponents/MapandListing/Loader";
import CardMain from "../../HomePage/SubComponents/MapandListing/CardMain";
import CategoriesTab from "../../HomePage/SubComponents/CatgoriesTab/CategoriesTab";
import Marquees from "../../HomePage/SubComponents/Marquee/Marquees";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import { BiFilter } from "react-icons/bi";
import { FaSort } from "react-icons/fa";
import Button from "@mui/material/Button";

import { Checkbox, FormControlLabel } from "@mui/material";
import ButtonList from "../../HomePage/SubComponents/DrawerButtonList/ButtonList";
import RangeSlider from "../../HomePage/SubComponents/RangeSlider/RangeSlider";
import SQFTRangeSlider from "../../HomePage/SubComponents/RangeSlider/SQFTRangeSlider";
import BedButtonList from "../../HomePage/SubComponents/DrawerButtonList/BedButtonList";
import ParkingButtons from "../../HomePage/SubComponents/HeaderSection/SubComponents/ParkingButtons";
import AccordionPanel from "../../HomePage/SubComponents/Accordion/AccordionPanel";
import BathroomButtons from "../../HomePage/SubComponents/HeaderSection/SubComponents/BathroomButtons";
import { Helmet } from "react-helmet";
import CardMainSkeleton from "../../HomePage/SubComponents/MapandListing/CardMainSkeleton";

const CategoryWiseAssignment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cityDescription, setCityDescription] = useState("");
  const [metaSeoTags, setMetaSeoTags] = useState("");
  const [descriptionLoading, setDescriptionLoading] = useState(true);
  const [drawerLoading, setDrawerLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [sortingOption, setSortingOption] = useState("NE");
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState("");
  const [showTotalRecordCount, setShowTotalRecordCount] = useState("");
  const [contentPage, setContentPage] = useState(1);
  const [filters, setFilters] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    bottom: false,
    right: false,
  });
  const [selectedStatusValues, setSelectedStatusValues] = React.useState("");
  const [activeButtons, setActiveButtons] = useState([]);
  const [selectedBathButtons, setSelectedBathButtons] = useState([]);
  const [activeBathButtons, setActiveBathButtons] = useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [selectedBedValues, setSelectedBedValues] = React.useState([]);
  const [selectedParkingButtons, setSelectedParkingButtons] = useState([]);
  const [rangeSliderValues, setRangeSliderValues] = useState({
    value1: 0,
    value2: 4600000,
  });
  const [rangeSQFTSliderValues, setSQFTRangeRadSliderValues] = useState({
    value1: 0,
    value2: 5000,
  });

  const mobileSearch = false;

  // For Fetching the data From the api
  const fetchData = async (page) => {
    const BuildingTypesParams =
      selectedValues?.length > 0
        ? selectedValues?.map((value) => ({ parameter: value }))
        : [];

    const BathParams =
      selectedBathButtons?.length > 0
        ? selectedBathButtons?.map((value) => ({ parameter: value }))
        : [];

    const ParkingParams =
      selectedParkingButtons?.length > 0
        ? selectedParkingButtons?.map((value) => ({ parameter: value }))
        : [];

    const BedParams = Array.from(selectedBedValues, (value) => {
      let parameter;

      if (value === "All") {
        parameter = "All";
      } else if (value === "Studio") {
        parameter = "0B";
      } else if (value === "1 Bed") {
        parameter = "1B";
      } else if (value === "1 Bed + Den") {
        parameter = "1B+D";
      } else if (value === "2 Bed") {
        parameter = "2B";
      } else if (value === "2 Bed + Den") {
        parameter = "2B+D";
      } else if (value === "3 Bed") {
        parameter = "3B";
      } else if (value === "3 Bed + Den") {
        parameter = "3B+D";
      } else if (value === "4 Bed") {
        parameter = "4B";
      } else if (value === "4 Bed + Den") {
        parameter = "4B+D";
      } else if (value === "5 Bed") {
        parameter = "5B";
      } else if (value === "5 Bed + Den") {
        parameter = "5B+D";
      } else if (value === "6 Bed+") {
        parameter = "6B+";
      }
      return { parameter };
    });

    const data = {
      MinPrice: rangeSliderValues.value1,
      MaxPrice:
        rangeSliderValues.value2 > 4500000
          ? 1000000000
          : rangeSliderValues.value2,
      MinSqft: rangeSQFTSliderValues.value1.toString(),
      MaxSqft:
        rangeSQFTSliderValues.value2 > 4500
          ? 1000000
          : rangeSQFTSliderValues.value2.toString(),
      Radius: 0,
      SortBy: sortingOption,
      Title: id,
      TitleType: "Location",
      Status: selectedStatusValues,
      Parking: "false",
      page: page,
      RestrictWithInCity: 1,
      Searchparameters_Types: BuildingTypesParams,
      Searchparameters_Beds: BedParams,
      Searchparameters_Baths: BathParams,
      Searchparameters_Parkings: ParkingParams,
      Searchparameters_Citys: [],
    };
    const formData = new FormData();
    formData.append("FilterDataRequest", JSON.stringify(data));

    try {
      const response = await axios.post(
        `${baseURL}/api/Assignment/GetFilteredData`,
        formData,
        {}
      );
      if (response?.data?.result === 1) {
        const newData = response?.data?.data?.assignments || [];
        setData((prevData) => [...prevData, ...newData]);
        setTotalPageCount(Math.ceil(response?.data?.data?.count / 20));
        setShowTotalRecordCount(response?.data?.data?.count);
        setIsLoading(false);
        setLoadMore(false);
      } else {
        console.error("There is problem in fetching data");
        setIsLoading(false);
        setLoadMore(false);
      }
    } catch (error) {
      console.error("There is problem in fetching data");
      setIsLoading(false);
      setLoadMore(false);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate(`/`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setData([]);
    setContentPage(1);
    setTotalPageCount("");
    fetchData(1);
    // eslint-disable-next-line
  }, [id, filters, sortingOption]);

  const HandleMoreContent = () => {
    setLoadMore(true);
    setContentPage((prevPage) => prevPage + 1);
    fetchData(contentPage + 1);
  };

  useEffect(() => {
    if (contentPage === totalPageCount || totalPageCount === 0) {
      setHasMoreContent(false);
    } else {
      setHasMoreContent(true);
    }
  }, [contentPage, totalPageCount]);

  const handleFilterForm = async () => {
    setFilters(!filters);
  };

  const handleResetFilters = async () => {
    setSQFTRangeRadSliderValues({
      value1: 0,
      value2: 5000,
    });
    setRangeSliderValues({
      value1: 0,
      value2: 4600000,
    });
    setSelectedValues([]);
    setSelectedBedValues([]);
    setSelectedParkingButtons([]);
    setSelectedBathButtons([]);
    setActiveButtons([]);
    setActiveBathButtons([]);
    setSelectedStatusValues("");
    setContentPage(1);
    setFilters(!filters);
  };

  //Get Cities filter Work
  const [citiesFilterData, setCitiesFilterData] = useState([]);

  const fetchCitiesFilterData = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/data/GetCity`);
      if (response?.data?.result === 1) {
        let dataArray = response?.data?.data;
        if (dataArray) {
          let AllCityObject = { cityName: "All" };
          let newDataArray = [AllCityObject, ...dataArray];
          setCitiesFilterData(newDataArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCitiesFilterData();
  }, []);

  //Get Cities Description Work

  const fetchCityDescription = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/GetCityDetail?term=${id}`
      );
      if (response?.data?.result === 1) {
        setCityDescription(response?.data?.data?.cityDescription);
        setMetaSeoTags(response?.data?.data?.citySeo[0]?.metaSeos);
        setDescriptionLoading(false);
      } else {
        setDescriptionLoading(false);
      }
    } catch (error) {
      console.error(error);
      setDescriptionLoading(false);
    }
  };

  useEffect(() => {
    fetchCityDescription(id);
    // eslint-disable-next-line
  }, [id]);

  //Filter button drawer
  const toggleFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "Keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSelectedBathButtons = (buttons) => {
    setSelectedBathButtons(buttons);
  };

  const handleSelectedParkingButtons = (button) => {
    setSelectedParkingButtons(button);
  };

  const handleSelectionChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };
  const handleBedSelectionChange = (selectedBedValues) => {
    setSelectedBedValues(selectedBedValues);
  };

  const handleRangeSliderChange = (value1, value2) => {
    setRangeSliderValues({ value1, value2 });
  };

  const handleSQFTRangeSliderChange = (value1, value2) => {
    setSQFTRangeRadSliderValues({ value1, value2 });
  };

  const list = (anchor, selectedValues) => (
    <Box
      className={mobileSearch ? "mob__drawerwidth" : "drawerwidth"}
      role="presentation"
    >
      <div
        className={
          mobileSearch
            ? "greencolor textwhite drawerdisp pt-3 pb-1 px-1  font"
            : " greencolor textwhite drawerdisp pt-3 pb-1 px-1  font"
        }
      >
        <h4 className="filtermargin">Filters</h4>
        <button onClick={toggleFilterDrawer(anchor, false)} className="btn">
          <CloseIcon className="closee" />
        </button>
      </div>
      <div className="container">
        <div className="prop_hide">
          <p className="m-0 m-v-Property__Heading">Find Property</p>
        </div>
        <Divider />
        <List className="m-0 p-0">
          <div className="p-2">
            <p className="font pt-1 m-1 ">
              <strong className="filter_heading ">Building Type:</strong>
            </p>
            <ButtonList
              selectedValues={selectedValues}
              handleSelectionChange={handleSelectionChange}
            />
          </div>
        </List>
        <List>
          <div className="px-3 pt-1 pb-2">
            <p className="font filter_para">
              <strong className="filter_heading">Price Range:</strong>
            </p>
            <RangeSlider
              value1={rangeSliderValues.value1}
              value2={rangeSliderValues.value2}
              onChange={handleRangeSliderChange}
            />
          </div>
          <Divider />
          <div className="px-3 py-2">
            <p className="filter_para font">
              <strong className="filter_heading">Size Sqft:</strong>
            </p>
            <SQFTRangeSlider
              value1={rangeSQFTSliderValues.value1}
              value2={rangeSQFTSliderValues.value2}
              onChange={handleSQFTRangeSliderChange}
            />
          </div>
          <Divider />
          <div className="bookingbuttons">
            <Divider />
            <p className="font filter_buttonPara pt-1  m-0">
              <strong className="filter_heading">Bedrooms </strong>
            </p>
            <div className="btndiv">
              <BedButtonList
                selectedBedValues={selectedBedValues}
                handleBedSelectionChange={handleBedSelectionChange}
              />
            </div>
          </div>
          <div className="bookingbuttons">
            <Divider />
            <p className="font filter_buttonPara pt-1 m-0">
              <strong className="filter_heading">Bathrooms</strong>
            </p>
            <div className="bathbtns">
              <BathroomButtons
                selectedBathButtons={handleSelectedBathButtons}
                activeBathButtons={activeBathButtons}
                setActiveBathButtons={setActiveBathButtons}
              />
            </div>
          </div>
          <div className="bookingbuttons">
            <Divider />
            <p className="font filter_buttonPara pt-1 m-0">
              <strong className="filter_heading">Parking</strong>
            </p>
            <div className="parkingbtns">
              <ParkingButtons
                selectedParkingButtons={handleSelectedParkingButtons}
                activeButtons={activeButtons}
                setActiveButtons={setActiveButtons}
              />
            </div>
          </div>
        </List>
        <Divider />
      </div>
      <div>
        <AccordionPanel
          selectedStatusValues={selectedStatusValues}
          setSelectedStatusValues={setSelectedStatusValues}
        />
      </div>
      <div>
        <div onClick={toggleFilterDrawer(anchor, false)}>
          <button
            onClick={() => {
              handleFilterForm();
            }}
            className="btn font btngreencolor sizings"
          >
            Apply Filters
          </button>
        </div>
        <button
          onClick={handleResetFilters}
          className="btn font btngreycolor sizings"
        >
          Reset
        </button>
      </div>
    </Box>
  );

  //Drawer open even refresh
  const drawerSlug = params.get("drawerSlug");
  const location = useLocation();
  const slugPresent = drawerSlug;

  const [draweritem, setDrawerItem] = useState();
  const [itemID, setItemID] = useState(1);
  const { searchData } = location.state || {};

  useEffect(() => {
    if (searchData === undefined) {
      if (slugPresent) {
        axios
          .get(`${baseURL}/api/Assignment/GetAssignment/${slugPresent}`)
          .then((response) => {
            if (response?.data?.data) {
              setDrawerItem(response?.data?.data);
              setOpenDrawer(response?.data?.data?.id);
              setItemID(response?.data?.data?.id);
              setDrawerLoading(false);
            } else {
              toast.error("There is no Data for this Assignment");
              navigate(`/assignment-sales/${id}`);
              setDrawerLoading(false);
            }
          })
          .catch((error) => {
            navigate(`/assignment-sales/${id}`);
            console.error("Error in Fetching Listing");
            setDrawerLoading(false);
          });
      }
    }
    // eslint-disable-next-line
  }, [slugPresent]);

  useEffect(() => {
    const handlePopstate = () => {
      const currentPath = window.location.pathname;
      const currenthash = window.location.hash;
      if (currentPath === `/assignment-sales/${id}` && !currenthash) {
        setOpenDrawer(null);
        setItemID(1);
        dispatch(removePdfData());
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  const [openDrawer, setOpenDrawer] = useState(null);
  const toggleDrawer = (anchor, itemId) => () => {
    setOpenDrawer(null);
    setItemID(1);
    dispatch(removePdfData());
    navigate(`/assignment-sales/${id}`);
  };

  const isDrawerOpen = (itemId) => {
    // eslint-disable-next-line
    return openDrawer == itemId;
  };

  //meta seo work

  const [mainTitle, setMainTitle] = useState("AssignmentSold.ca");
  const [title, setTitle] = useState("AssignmentSold.ca");
  const [description, setDescription] = useState(
    "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales."
  );
  const [keywords, setKeywords] = useState(
    "assignment sold, assignment sale, condo, townhouse"
  );

  useEffect(() => {
    if (data.length !== 0) {
      const mainTitleObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "main_Title"
      );
      setMainTitle(
        mainTitleObject ? mainTitleObject?.content : "AssignmentSold.ca"
      );
      const titleObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "title"
      );
      setTitle(titleObject ? titleObject?.content : "AssignmentSold.ca");
      const descriptionObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "description"
      );
      setDescription(
        descriptionObject
          ? descriptionObject?.content
          : "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales."
      );
      const KeywordObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "keywords"
      );
      setKeywords(
        KeywordObject
          ? KeywordObject?.content
          : "assignment sold, assignment sale, condo, townhouse"
      );
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={
            "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={
            "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:url" content={window.location.href} />
      </Helmet>

      <Marquees />

      <div className="container">
        <div className="text-center assignment-salesText">
          <h1>{`Assignments for sale in ${id}`} </h1>
        </div>

        {citiesFilterData && (
          <div className="my-3">
            <CategoriesTab
              citiesFilterData={citiesFilterData}
              loading={isLoading}
              loadMore={loadMore}
            />
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center category_resultAndBtn">
          <p className="results cityCategory_results textgreen mb-0">
            {`Results ${data?.length}  of
                  ${showTotalRecordCount} `}
          </p>
          <div className="city_categoryyBtn_container">
            <div>
              <button
                className="cityCategory_Btn"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaSort
                  style={{
                    color: "#02549e",
                    width: "1rem",
                    height: "1rem",
                  }}
                />{" "}
                Sort
              </button>

              <ul
                className="dropdown-menu menuwidth1 p-2"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sortingOption === "PLH"}
                        onChange={() => setSortingOption("PLH")}
                      />
                    }
                    label={<span className="font">Price (Low to High)</span>}
                  />
                </li>
                <li className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sortingOption === "PHL"}
                        onChange={() => setSortingOption("PHL")}
                      />
                    }
                    label={<span className="font">Price (High to Low)</span>}
                  />
                </li>
                <li className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sortingOption === "SLH"}
                        onChange={() => setSortingOption("SLH")}
                      />
                    }
                    label={
                      <span className="font">Square Feet (Low to High)</span>
                    }
                  />
                </li>
                <li className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sortingOption === "SHL"}
                        onChange={() => setSortingOption("SHL")}
                      />
                    }
                    label={
                      <span className="font">Square Feet (High to Low)</span>
                    }
                  />
                </li>
                <li className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sortingOption === "CLF"}
                        onChange={() => setSortingOption("CLF")}
                      />
                    }
                    label={<span className="font">Closest First</span>}
                  />
                </li>
                <li className="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sortingOption === "NE"}
                        onChange={() => setSortingOption("NE")}
                      />
                    }
                    label={<span className="font">Newest First</span>}
                  />
                </li>
              </ul>
            </div>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  onClick={toggleFilterDrawer(anchor, true)}
                  variant="outlined"
                  className="cityCategory_Btn cityCategoryFilter_Btn"
                >
                  <BiFilter
                    style={{
                      color: "#fff",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  />{" "}
                  <span>Filters</span>
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleFilterDrawer(anchor, false)}
                  sx={{
                    "& .MuiDrawer-paper": {
                      borderTopLeftRadius: "30px",
                    },
                  }}
                >
                  {list(anchor, selectedValues)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex flex-wrap justify-content-center ActiveUserListing_card mt-4 mb-4">
            {Array.from(new Array(8))?.map((_, index) => (
              <CardMainSkeleton key={index} />
            ))}
          </div>
        ) : data?.length === 0 ? (
          <>
            <div className="no__FoundMain font">
              <div className="no__Found__img">
                <img
                  className="not_found_img_mobile"
                  src={require("../../images/noResultFound.png")}
                  alt="Result not found"
                  loading="lazy"
                />
              </div>
              <div className="no__FoundText font-family-regular not_found_Text_mobile">
                <p>No RecordFound</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-center ActiveUserListing_card mt-4 mb-4">
              {data?.map((item, index) => {
                return (
                  <CardMain
                    key={index}
                    item={item}
                    setDrawerLoading={setDrawerLoading}
                    cardPath={`assignment-sales/${id}?drawerSlug=`}
                  />
                );
              })}
            </div>
            {hasMoreContent ? (
              <div className="justifycenter aligncenter mb-4">
                {loadMore ? (
                  <RotatingLines width={50} strokeColor="grey" />
                ) : (
                  <button
                    className="btn btn-primary font"
                    onClick={HandleMoreContent}
                    disabled={loadMore}
                  >
                    Load more
                  </button>
                )}
              </div>
            ) : null}

            {descriptionLoading ? (
              <>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1.5rem" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "0.6rem" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1.5rem" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "0.6rem" }}
                />
              </>
            ) : (
              <>
                {cityDescription && (
                  <div
                    style={{ marginTop: "5rem" }}
                    dangerouslySetInnerHTML={{ __html: cityDescription }}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      <div style={{ marginTop: "6rem" }}>
        <Footer />
      </div>

      {drawerLoading ? (
        <div className="scrollLoader">
          <Loader size="80" />
          <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
        </div>
      ) : (
        <Drawer
          anchor="right"
          open={isDrawerOpen(itemID)}
          onClose={toggleDrawer("right", itemID)}
        >
          <DrawerContent
            item={draweritem}
            anchor={"right"}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      )}
      <ToastContainer />
    </div>
  );
};

export default CategoryWiseAssignment;
