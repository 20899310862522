import React, { useState } from "react";
import { RatingFillIcon } from "../../../../SvgIcons/Icons";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Schools = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const schoolData = data || [];
  const schoolDataToShow = showMore ? schoolData : schoolData.slice(0, 6);

  return (
    <>
      <div className="row mx-0">
        {schoolDataToShow?.map((item, i) => {
          return (
            <div
              className="col-md-6 col-12 mbl_Xpadding0 px-2 explore_propertyCard d-flex mt-4"
              key={i}
            >
              <div>
                <img src={item?.schoolImg} loading="lazy" alt="SchoolImg" />
              </div>
              <div className="ps-2 w-100">
                <h5 className="PropertyDetails_Mbl_f18">{item?.schoolName}</h5>
                <h6 className="mb-1 PropertyDetails_Mbl_f14">{`${item?.schoolType} | ${item?.schoolLevel} | ${item?.district}`}</h6>
                <h6 className="PropertyDetails_Mbl_f16">
                  <span>{item?.distance}</span>
                  {` | ${item?.walkDistance} | ${item?.boudary}`}
                </h6>
              </div>
              <div className="Explore_prop_rating">
                <RatingFillIcon /> <span>{item?.rating}</span>
              </div>
            </div>
          );
        })}
      </div>

      {schoolData?.length > 6 && (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      )}
    </>
  );
};

export default Schools;
