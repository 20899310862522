import React, { useState, useEffect, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  addMonthlyPayment,
  removeMonthlyPayment,
} from "../../../ReduxStore/Slices/MonthlyMortgagePaymentSlice";
import { CalculatorIcon } from "../../../SvgIcons/Icons";
import { useDispatch, useSelector } from "react-redux";

const MortgageCalculator = ({ dataPurchasePrice, isMobileView }) => {
  const dispatch = useDispatch();
  const interstData = useSelector((state) => state.adminSiteDataSlice);
  const [isCalculate, setIsCalculate] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [isCalculator, setIsCalculator] = useState(false);
  const [isCashFlow, setIsCashFlow] = useState(false);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  //Calculator Work
  const [purchasePrice, setPurchasePrice] = useState(dataPurchasePrice);

  const formatPurchaseValue = (value) => {
    return value ? parseFloat(value).toLocaleString("en-US") : "";
  };

  const handlePurchasePriceChange = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/[^0-9.]/g, "");
    setPurchasePrice(numericValue);
  };

  const interestFromAdmin = interstData[0]?.intrestRate;
  const [downValue, setDownValue] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [interestValue, setInterestValue] = useState(0);

  useEffect(() => {
    setInterestRate(interestFromAdmin / 100);
    setInterestValue(interestFromAdmin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestFromAdmin]);

  const handleDownPAyChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    if (numericValue > parseFloat(purchasePrice)) {
      setDownValue(purchasePrice);
    } else {
      setDownValue(numericValue);
    }
  };

  //handle Interest Change
  const handleInterestChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setInterestValue(numericValue);
    setInterestRate(numericValue / 100);
    // interestFromAdmin(numericValue); If there is any mistake unComment it
  };

  // Amortization years Handle
  const [amortizationYears, setAmortizationYears] = useState(30);

  const handleAmortizationChange = (event) => {
    setAmortizationYears(event.target.value);
  };

  //Mortgage Calculations
  useEffect(() => {
    calculateDownPayment(purchasePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasePrice]);

  const calculateLandTransferTax = (purchasePrice) => {
    let fnfAmount = 0;
    const property = interstData?.region;

    if (purchasePrice <= 55000) {
      fnfAmount = purchasePrice * 0.005;
    } else if (purchasePrice <= 250000) {
      fnfAmount = (purchasePrice - 55000) * 0.01 + 275;
    } else if (purchasePrice <= 400000) {
      fnfAmount = (purchasePrice - 250000) * 0.015 + 275 + 1950;
    } else if (purchasePrice <= 2000000) {
      fnfAmount = (purchasePrice - 400000) * 0.02 + 275 + 1950 + 2250;
    } else {
      fnfAmount = (purchasePrice - 2000000) * 0.025 + 275 + 1950 + 2250 + 32000;
    }

    return property === "Toronto" ? fnfAmount * 2 : fnfAmount;
  };

  const calculateFinalAmount = (
    purchasePrice,
    interestRate,
    amortizationYears,
    downValue
  ) => {
    const monthlyInterestRate =
      Math.pow(Math.pow(1 + interestRate / 2, 2), 1 / 12) - 1;
    const totalPayments = amortizationYears * 12;
    const numerator = (purchasePrice - downValue) * monthlyInterestRate; //downpay should be subtract from purchase price
    const denominator = 1 - Math.pow(1 + monthlyInterestRate, -totalPayments);
    const finalAmount = numerator / denominator;
    return finalAmount;
  };

  const calculateDownPayment = (purchasePrice) => {
    let calculatedDownPayment;
    if (purchasePrice <= 500000) {
      calculatedDownPayment = purchasePrice * 0.05;
    } else if (purchasePrice >= 500000 && purchasePrice < 1000000) {
      calculatedDownPayment = (purchasePrice - 500000) * 0.1 + 25000;
    } else if (purchasePrice >= 1000000) {
      calculatedDownPayment = purchasePrice * 0.2;
    }
    setDownValue(calculatedDownPayment);
  };

  const calculateMortgageInsurance = (
    percentageAmount,
    purchasePrice,
    downValue
  ) => {
    const percentageDifference = 100 - percentageAmount;
    let mortgageInsurance;
    if (percentageDifference <= 65) {
      mortgageInsurance = (purchasePrice - downValue) * 0.006;
    } else if (percentageDifference <= 75) {
      mortgageInsurance = (purchasePrice - downValue) * 0.017;
    } else if (percentageDifference <= 80) {
      mortgageInsurance = (purchasePrice - downValue) * 0.024;
    } else if (percentageDifference <= 85) {
      mortgageInsurance = (purchasePrice - downValue) * 0.028;
    } else if (percentageDifference <= 90) {
      mortgageInsurance = (purchasePrice - downValue) * 0.031;
    } else if (percentageDifference <= 95) {
      mortgageInsurance = (purchasePrice - downValue) * 0.04;
    } else {
      mortgageInsurance = (purchasePrice - downValue) * 0.04;
    }

    return mortgageInsurance;
  };

  // const resultForDownPayment = downValue;
  const percentageAmount = (downValue / purchasePrice) * 100;
  const calculatedMortgageInsurance = Math.round(
    calculateMortgageInsurance(percentageAmount, purchasePrice, downValue)
  );
  const calculatedAmount = calculateFinalAmount(
    purchasePrice,
    interestRate,
    amortizationYears,
    downValue
  );
  const monthlyMortagage = Math.round(calculatedAmount);

  const landTransferTax = Math.round(calculateLandTransferTax(purchasePrice));

  const mortgageTotal = Math.round(
    purchasePrice - downValue + calculatedMortgageInsurance
  );

  const roundDownValue = Math.round(downValue);
  const formattedDownPayment = roundDownValue
    ? roundDownValue.toLocaleString("en-US")
    : "";

  const toShowMonthlyMortgage = monthlyMortagage.toLocaleString("en-US");
  const toShowMortgageTotal = mortgageTotal.toLocaleString("en-US");
  const toShowLandTransferTax = landTransferTax.toLocaleString("en-US");

  useEffect(() => {
    dispatch(removeMonthlyPayment());
    dispatch(addMonthlyPayment(toShowMonthlyMortgage));
    // eslint-disable-next-line
  }, [toShowMonthlyMortgage]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <CalculatorIcon />}
          <h2 className="property_Details_heading">Mortgage Calculator</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100 propDetail_mortgageCalc">
          {isCalculate && (
            <div className="mt-3">
              <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between MortgageBtn_container">
                <div className="Mortgage_tabContainer">
                  <button
                    className={`${
                      isCalculator && "Mortgage_selectedTabBtn"
                    } PropertyDetails_Mbl_f14`}
                    onClick={() => {
                      setIsCalculator(true);
                      setIsCashFlow(false);
                    }}
                  >
                    Calculator
                  </button>
                  <button
                    className={`${
                      isCashFlow && "Mortgage_selectedTabBtn"
                    } PropertyDetails_Mbl_f14`}
                    onClick={() => {
                      setIsCalculator(false);
                      setIsCashFlow(true);
                    }}
                  >
                    Cash flow
                  </button>
                </div>
                <button className="Mortage_contactBrokerBtn PropertyDetails_Mbl_f14">
                  Contact Broker
                </button>
              </div>

              {isCalculator && (
                <div className="Prop_detailMortgage_calc mt-3">
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <label>Price</label>
                      <br />
                      <input
                        type="text"
                        value={
                          formatPurchaseValue(purchasePrice) === ""
                            ? ""
                            : `$${formatPurchaseValue(purchasePrice)}`
                        }
                        onChange={handlePurchasePriceChange}
                      />
                    </div>
                    <div className="col-md-4 col-12 mbl_margin">
                      <label>Term</label>
                      <br />
                      <Select
                        labelId="Select"
                        id="terms"
                        value={amortizationYears}
                        onChange={handleAmortizationChange}
                        sx={{
                          borderRadius: "10px",
                          padding: "9px 12px",
                          width: "100%",
                          "& .MuiSvgIcon-root": {
                            fontSize: "28px",
                            color: "#282828",
                          },
                        }}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value={"5"}>5 years</MenuItem>
                        <MenuItem value={"10"}>10 years</MenuItem>
                        <MenuItem value={"15"}>15 years</MenuItem>
                        <MenuItem value={"20"}>20 years</MenuItem>
                        <MenuItem value={"25"}>25 years</MenuItem>
                        <MenuItem value={"30"}>30 years</MenuItem>
                      </Select>
                    </div>
                    <div className="col-md-4 col-12 mbl_margin">
                      <label>Rate</label>
                      <br />
                      <input
                        type="text"
                        value={interestValue}
                        onChange={handleInterestChange}
                      />
                    </div>
                    <div className="mt-3 col-md-4 col-12">
                      <label>Down Payment</label>
                      <br />
                      <input
                        type="text"
                        value={
                          formattedDownPayment === ""
                            ? ""
                            : `$${formattedDownPayment}`
                        }
                        onChange={handleDownPAyChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-3 col-md-4 col-12 d-flex justify-content-between">
                      <h5>Mortgage Payment</h5>
                      <h6>${toShowMonthlyMortgage}</h6>
                    </div>
                  </div>
                </div>
              )}
              {isCashFlow && (
                <div className="Prop_detailMortgage_calc mt-3">
                  <div
                    className={`p-3 d-flex ${isMobileView && "flex-column"}`}
                    style={{
                      backgroundColor: "var(--Primary-Light-blue, #E2F1FF)",
                      borderRadius: "16px",
                    }}
                  >
                    <div className="col-md-4 col-12">
                      <h5>Mortgage Payment</h5>
                      <h6 className="m-0">$4,995,000</h6>
                    </div>
                    <div className="col-md-4 col-12 mbl_margin">
                      <h5>Monthly Payment</h5>
                      <h6 className="m-0">$4,995,000</h6>
                    </div>
                    <div className="col-md-4 col-12 mbl_margin">
                      <h5>Break even down payment</h5>
                      <h6 className="m-0">45%</h6>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-4 col-12">
                      <label>Property tax monthly</label>
                      <br />
                      <input type="text" />
                    </div>
                    <div className="col-md-4 col-12 mbl_margin">
                      <label>Maintenance</label>
                      <br />
                      <input type="text" />
                    </div>
                    <div className="col-md-4 col-12 mbl_margin">
                      <label>Rental income</label>
                      <br />
                      <input type="text" />
                    </div>
                  </div>
                  <div className="mt-3 col-md-4 col-12">
                    <label>Down Payment</label>
                    <br />
                    <input type="text" />
                  </div>
                  <div className="row">
                    <div className="mt-3 col-md-4 col-12 d-flex justify-content-between">
                      <h5>Cash flow</h5>
                      <h6>45%</h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {!isCalculate && (
            <div className={`mt-3 d-flex`}>
              <button
                className="calcNowBtn"
                onClick={() => {
                  setIsCalculate(true);
                  setIsCalculator(true);
                }}
              >
                Calculate now
              </button>
              <button className="ms-2">Contact Broker</button>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(MortgageCalculator);
