import React, { useState, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PropertyData } from "../PropertData";
import EmblaCarousel from "./ListingComparable/Carousel";

const TabPanel = memo((props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
});

const MoreSimilarListing = ({isUserlogin,setIsSignUpModalOpen,setSignUpModalHeading}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const OPTIONS = { slidesToScroll: "auto" };
  const ListingNearbyTabs = [
    "Nearby Listing",
    "Similar Listings",
    "By same Builder",
  ];

  const ListingNearbyTabDataMapping = {
    0: PropertyData.nearByListing || [],
    1: PropertyData.similarListing || [],
    2: PropertyData.builderListings || [],
  };

  return (
    <div className="w-100">
      <div>
        <Box className="mt-3">
          <AppBar position="static" sx={{ boxShadow: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="inherit"
              sx={{ backgroundColor: "#fff" }}
              className="Property_details_tabs"
              elevation={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {ListingNearbyTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab}
                  sx={{
                    border: "1px solid #CCCCCC",
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          {ListingNearbyTabs.map((tab, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className="PropertyDetail_tabPanel position-relative"
            >
              <div className="mt-2">
                {isUserlogin ? (
                  <>
                    {ListingNearbyTabDataMapping[index].length > 0 ? (
                      <EmblaCarousel
                        slides={ListingNearbyTabDataMapping[index]}
                        options={OPTIONS}
                      />
                    ) : (
                      <div>No data available for {tab}</div>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100 top-0 left-0">
                      <button
                        className="PropertyDetails_Mbl_f14 Prop_loginReqBtn"
                        onClick={() => {
                          setIsSignUpModalOpen(true);
                          setSignUpModalHeading(
                            "Join to Unlock Comparable Listings!"
                          );
                        }}
                      >
                        Login Required
                      </button>
                    </div>
                    <div
                      style={{
                        width: "992px",
                        overflow: "hidden",
                        height: "425px",
                      }}
                    >
                      <img
                        src="/images/listingBlur.png"
                        width={"100%"}
                        style={{ height: "100%" }}
                        loading="lazy"
                        alt="blurListings"
                      />
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default MoreSimilarListing;
