import React, { useEffect, useState, useRef,memo } from "react";
import {
  FamilyIcon,
  HouseHoldCompositionIcon,
  MultiPersonIcon,
  SinglePersonIcon,
} from "../../../SvgIcons/Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const HouseHoldComposition = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);
  const [progress, setProgress] = useState({
    singleFamily: 0,
    multiFamily: 0,
    singlePerson: 0,
    multiPerson: 0,
  });

  const compositionRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          setTimeout(() => {
            setProgress({
              singleFamily: 60,
              multiFamily: 70,
              singlePerson: 40,
              multiPerson: 20,
            });
          }, 2000); 
        } else {
          setIsInView(false);
          setProgress({
            singleFamily: 0,
            multiFamily: 0,
            singlePerson: 0,
            multiPerson: 0,
          });
        }
      },
      {
        threshold: 0.1, 
      }
    );
  
    const currentRef = compositionRef.current; 
  
    if (currentRef) {
      observer.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); 
      }
    };
  }, []);
  

  const ProgressParentDiv = (background) => ({
    height: "4px",
    width: "100%",
    backgroundColor: `${background}`,
    borderRadius: "40px",
    overflow: "hidden",
  });

  const ProgressChildDiv = (width, FillBackground) => ({
    height: "100%",
    width: `${width}%`,
    backgroundColor: `${FillBackground}`,
    borderRadius: "40px",
    textAlign: "right",
    transition: "width 1.5s ease-in-out",
  });

  return (
    <div className="w-100" ref={compositionRef}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
        sx={{ background: "#ffff" }}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <HouseHoldCompositionIcon />}
            <h2 className="property_Details_heading">Household Composition</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Analyzing household composition helps identify family structures
                and living arrangements, which is vital for tailoring community
                services and support.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Analyzing household composition helps identify family structures
              and living arrangements, which is vital for tailoring community
              services and support.
            </p>
          )}
          <div className="row w-100 mx-0 mt-3">
            <div className="col-md-3 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
              <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
                <div className="propertyDetail_homeValue_icon">
                  <FamilyIcon color={"#008733"} />
                </div>
                <div className="w-100 mt-2">
                  <h6 className="mt-3 mbl_marginTop0 PropertyDetails_Mbl_f18">
                    Single Family
                  </h6>
                  <div className="d-flex mt-2 align-items-center">
                    <div style={ProgressParentDiv("#D1FADF")}>
                      {isInView && (
                        <div
                          style={ProgressChildDiv(
                            progress?.singleFamily,
                            "#12B76A"
                          )}
                        ></div>
                      )}
                    </div>
                    <p className="m-0 ps-2 propert_detail_progress_text PropertyDetails_Mbl_f14">{`${progress?.singleFamily}%`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 py-2 px-1 market_activityMobileCard">
              <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
                <div
                  className="propertyDetail_homeValue_icon"
                  style={{
                    borderColor: "#DFF4FF",
                    background: "var(--Light-blue, #BDDFFF)",
                  }}
                >
                  <FamilyIcon />
                </div>
                <div className="w-100 mt-2">
                  <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                    Multi Family
                  </h6>
                  <div className="d-flex mt-2 align-items-center">
                    <div style={ProgressParentDiv("#BDDFFF")}>
                      {isInView && (
                        <div
                          style={ProgressChildDiv(
                            progress?.multiFamily,
                            "#0253A0"
                          )}
                        ></div>
                      )}
                    </div>
                    <p className="m-0 ps-2 propert_detail_progress_text PropertyDetails_Mbl_f1">{`${progress?.multiFamily}%`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 py-2 px-1 mbl_Xpadding0">
              <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
                <div
                  className="propertyDetail_homeValue_icon"
                  style={{
                    borderColor: "#F4ECFF",
                    background: "var(--Supporting-color-Light-Purple, #E4D2FF)",
                  }}
                >
                  <SinglePersonIcon />
                </div>
                <div className="w-100 mt-2">
                  <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                    Single Person
                  </h6>
                  <div className="d-flex mt-2 align-items-center">
                    <div style={ProgressParentDiv("#F9F5FF")}>
                      {isInView && (
                        <div
                          style={ProgressChildDiv(
                            progress?.singlePerson,
                            "#653EBE"
                          )}
                        ></div>
                      )}
                    </div>
                    <p className="m-0 ps-2 propert_detail_progress_text PropertyDetails_Mbl_f14">{`${progress?.singlePerson}%`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 py-2 ps-1 pe-0 market_activityMobileCard">
              <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
                <div
                  className="propertyDetail_homeValue_icon"
                  style={{
                    borderColor: "#FFFCDF",
                    background: "var(--Supporting-color-Light-yellow, #FFF7A0)",
                  }}
                >
                  <MultiPersonIcon />
                </div>
                <div className="w-100 mt-2">
                  <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                    Multi Person
                  </h6>
                  <div className="d-flex mt-2 align-items-center">
                    <div style={ProgressParentDiv("#FEF9C3")}>
                      {isInView && (
                        <div
                          style={ProgressChildDiv(
                            progress?.multiPerson,
                            "#713F12"
                          )}
                        ></div>
                      )}
                    </div>
                    <p className="m-0 ps-2 propert_detail_progress_text PropertyDetails_Mbl_f14">{`${progress?.multiPerson}%`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(HouseHoldComposition);
