import React, { useState, useEffect, useRef, useCallback } from "react";
import { FormControl, FormLabel } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { Box, Modal } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  CarIcon,
  CloseIcon,
  CycleIcon,
  LocationIcon,
  TransitIcon,
  WalkingIcon,
} from "../../../../SvgIcons/Icons";
import { toast } from "react-toastify";
import { NeedHomeBaseURL } from "../../../../config";

const CommuteModal = ({
  data,
  open,
  setIsCommuteModalOpen,
  setIsSignUpModal,
  setSignUpModalHeading,
}) => {
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const [fadeOut, setFadeOut] = useState(false);

  const modalRef = useRef(null);

  const closeModal = () => {
    setFadeOut(true);
    setTimeout(() => {
      setIsCommuteModalOpen(false);
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.classList.remove("Prop_detail_infoModal_fadeOut");
    }
  }, [open]);

  const [isAddressFilled, setIsAddressfilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [addressLatitude, setAddressLatitude] = useState("");
  const [addressLongitude, setAddressLongitude] = useState("");
  const [selectedDistanceBy, setSelectedDistanceBy] = useState("driving");
  const [distanceTime, setDistanceTime] = useState("");
  const [apiData, setApiData] = useState();
  const [activeSuggestion, setActiveSuggestion] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [newInputval, setNewInputVal] = useState("");
  const wrapperRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // FetchData for search bar from API Function
  // eslint-disable-next-line
  const debouncedApiCall = useCallback(
    debounce((query) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };
      axios
        .get(
          `${NeedHomeBaseURL}/api/AuthData/AddressResponse/${query}`,
          config
        )
        .then((response) => {
          setApiData(response?.data.data);
          if (response?.data.data?.length > 0) {
            setOpenBox(true);
          } else {
            setOpenBox(false);
          }
        })
        .catch((error) => {
          console.error("error");
        });
    }, 700),
    []
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenBox(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleinputChange = (e) => {
    const inputValue = e.target.value;
    setNewInputVal(inputValue);
    setAddressLatitude("");
    setAddressLongitude("");
    setIsAddressfilled(false);
    if (inputValue.slice(0, 1) !== " " && inputValue.slice(0, 1) !== "") {
      debouncedApiCall(inputValue);
    }
  };

  const handleSuggetionResult = (props) => {
    setNewInputVal(props?.responseDesc);
    handleOptionSelect(props);
    setIsAddressfilled(true);
    setOpenBox(false);
  };

  const searchKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (
        activeSuggestion === null ||
        activeSuggestion === apiData.length - 1
      ) {
        setActiveSuggestion(0);
      } else {
        setActiveSuggestion(activeSuggestion + 1);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (activeSuggestion === null || activeSuggestion === 0) {
        setActiveSuggestion(apiData.length - 1);
      } else {
        setActiveSuggestion(activeSuggestion - 1);
      }
    } else if (e.key === "Enter") {
      setOpenBox(false);
      setNewInputVal(apiData[activeSuggestion]?.responseDesc);
      setIsAddressfilled(true);
      handleOptionSelect(apiData[activeSuggestion]);
    }
  };

  const handleOptionSelect = async (selectedOption) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${selectedOption?.responseDesc}.json?proximity=ip&access_token=pk.eyJ1IjoiY2hpc2h0aS1hZG1pbiIsImEiOiJjbGw1N2FsNTcwYzFsM2ttazRzcnlhanpsIn0.h1YsNrhMozZkeWn6jcYTwg`
      );
      if (response?.data?.features[0]) {
        setAddressLongitude(response.data.features[0].center[0]);
        setAddressLatitude(response.data.features[0].center[1]);
      }
    } catch (error) {
      console.error("Something wrong to fill the fields");
    }
  };

  const fetchDistance = async () => {
    let error = false;
    if (!isAddressFilled) {
      error = true;
    }
    if (jwt_token) {
      if (!error && addressLatitude && addressLongitude) {
        setIsLoading(true);
        const headers = {
          Authorization: `Bearer ${jwt_token}`,
        };
        const commuteData = {
          LatTo: addressLatitude,
          LngTo: addressLongitude,
          LatFrom: data?.lat,
          LngFrom: data?.lng,
          Category: selectedDistanceBy, //driving,driving-traffic,walking,cycling
        };
        try {
          const response = await axios.post(
            `${NeedHomeBaseURL}/api/AuthData/CommutteResponse`,
            commuteData,
            {
              headers,
            }
          );
          if (response?.data?.result === 1) {
            setDistanceTime(response?.data?.data[0]?.time);
            setIsSubmitted(false);
            setIsLoading(false);
          } else {
            toast.error("Something went wrong");
            setIsLoading(false);
            setIsSubmitted(false);
          }
        } catch (error) {
          toast.error(error.message);
          setIsLoading(false);
          setIsSubmitted(false);
        }
      }
    } else {
      setSignUpModalHeading("Join us to navigate Your Commute!");
      setIsSignUpModal(true);
    }
  };

  const handleCategoryChange = (category) => {
    if (!isLoading) {
      setIsSubmitted(true);
      setSelectedDistanceBy(category);
    }
  };

  useEffect(() => {
    if (isAddressFilled) {
      fetchDistance();
    }
    // eslint-disable-next-line
  }, [selectedDistanceBy]);

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={`Prop_detail_infoModal SignUp_modal ${
          fadeOut ? "Prop_detail_infoModal_fadeOut" : ""
        }`}
        ref={modalRef}
      >
        <div className="row m-0 w-100 d-flex align-items-center">
          <div className="col-md-6 col-12 mbl_Xpadding0">
            <h3 className="LoginModal_heading">Add a commute</h3>
            <div style={{ marginTop: "12px" }}>
              <FormLabel>Choose destination</FormLabel>
              <FormControl className="w-100 d-flex align-items-center flex-row">
                <div className="position-relative w-100">
                  <div
                    className={`d-flex request_Info_input  ${
                      isFocused ? "Prop_commuteFocusBorder" : ""
                    }`}
                  >
                    <input
                      type="text"
                      placeholder="Enter destination"
                      value={newInputval}
                      onChange={handleinputChange}
                      onKeyDown={searchKeyDown}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      className="w-100"
                      style={{ border: "none", outline: "none" }}
                    />
                    <div>
                      <LocationIcon />
                    </div>
                  </div>

                  {openBox ? (
                    <div
                      ref={wrapperRef}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "auto",
                          backgroundColor: "white",
                          boxShadow: 4,
                          borderRadius: 2,
                          margin: 0,
                          paddingLeft: 0,
                        }}
                        className="PropDetail_addressSearchBox"
                      >
                        <div className="p-2">
                          {apiData?.slice(0, 5)?.map((suggestion, index) => (
                            <div
                              className={`d-flex align-items-start py-1 searchSuggestion ${
                                activeSuggestion === index && "active__search"
                              }`}
                              key={index}
                              onClick={() => {
                                handleSuggetionResult(suggestion);
                              }}
                            >
                              <div>
                                <LocationIcon size={"16px"} />
                              </div>
                              <div>
                                <p
                                  style={{
                                    fontFamily: "Open Sans",
                                    fontSize: "13px",
                                    color: "#4A4A4A",
                                  }}
                                  className="m-0 px-1 search__description"
                                >
                                  {suggestion.responseTitle}
                                </p>
                                <p
                                  style={{
                                    fontFamily: "Open Sans",
                                    fontSize: "13px",
                                    color: "#4A4A4A",
                                  }}
                                  className="m-0 px-1 search__description"
                                >
                                  {suggestion.responseDesc}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Box>
                    </div>
                  ) : null}
                </div>
              </FormControl>
              {!isAddressFilled && isSubmitted && (
                <div>
                  <span style={{ color: "red" }}>
                    {newInputval?.length <= 0
                      ? "Address is required"
                      : "Kindly select the address from options"}
                  </span>
                </div>
              )}
            </div>

            <div className="mt-3">
              <p className="ArriveDestination_commute">
                You will arrive at your destination in{" "}
                {distanceTime ? (
                  <span style={{ borderBottom: "2px solid #000" }}>
                    {Math.ceil(distanceTime)}
                  </span>
                ) : (
                  <span>___</span>
                )}{" "}
                minutes
              </p>
            </div>

            <div className="PropDetailsMob_md_show Property_detailsWeb_hide mt-3 w-100">
              <img
                src="/images/AddCommuteMap.png"
                alt="map_location"
                loading="lazy"
                width={"100%"}
                height="200px"
                style={{ objectFit: "cover", borderRadius: "10px" }}
              />
            </div>

            <div className="d-flex mt-3 justify-content-between">
              <div
                className={`${
                  selectedDistanceBy === "driving" &&
                  "SelectedAddCommute_WalkDistance"
                } AddCommute_WalkDistance`}
                onClick={() => handleCategoryChange("driving")}
              >
                <CarIcon
                  color={selectedDistanceBy === "driving" ? "#fff" : "#282828"}
                />
                <span>Car</span>
              </div>
              <div
                className={`${
                  selectedDistanceBy === "walking" &&
                  "SelectedAddCommute_WalkDistance"
                } AddCommute_WalkDistance`}
                onClick={() => handleCategoryChange("walking")}
              >
                <WalkingIcon
                  color={selectedDistanceBy === "walking" ? "#fff" : "#282828"}
                />
                <span>Walk</span>
              </div>
              <div
                className={`${
                  selectedDistanceBy === "cycling" &&
                  "SelectedAddCommute_WalkDistance"
                } AddCommute_WalkDistance`}
                onClick={() => handleCategoryChange("cycling")}
              >
                <CycleIcon
                  color={selectedDistanceBy === "cycling" ? "#fff" : "#282828"}
                />
                <span>Cycle</span>
              </div>
              <div
                className={`${
                  selectedDistanceBy === "driving-traffic" &&
                  "SelectedAddCommute_WalkDistance"
                } AddCommute_WalkDistance`}
                onClick={() => handleCategoryChange("driving-traffic")}
              >
                <TransitIcon
                  color={
                    selectedDistanceBy === "driving-traffic"
                      ? "#fff"
                      : "#282828"
                  }
                />
                <span>Transit</span>
              </div>
            </div>

            {isLoading ? (
              <div className="aligncenter justifycenter mt-3">
                <RotatingLines width={50} strokeColor="grey" />
              </div>
            ) : (
              <div>
                <button
                  type="submit"
                  onClick={() => {
                    setIsSubmitted(true);
                    fetchDistance();
                  }}
                  style={{ marginTop: "16px" }}
                >
                  Start
                </button>
              </div>
            )}
          </div>

          <div className="col-md-6 pe-0 col-12 PropDetailsMob_md_hide">
            <img
              src="/images/AddCommuteMap.png"
              alt="map_location"
              loading="lazy"
              width={"100%"}
              height="348px"
            />
          </div>
        </div>
        <div
          className="position-absolute Property_detailsWeb_hide"
          style={{ top: "20px", right: "15px" }}
          onClick={closeModal}
        >
          <CloseIcon />
        </div>
      </Box>
    </Modal>
  );
};

export default CommuteModal;
