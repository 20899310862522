import React from 'react';
import { BarChart, Bar, Tooltip, YAxis, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="Prop_lineChart_custom_tooltip">
                <p className='Prop_lineChart_custom_tooltip_year'>{`Year: ${label}`}</p>
                <p className='Prop_lineChart_custom_tooltip_price'>{`$ ${payload[0]?.payload?.price?.toLocaleString("en-US")}`}</p>
            </div>
        );
    }

    return null;
};

const SoldDistributionChart = ({ data }) => {

    return (
        <div className='PropDetailsGraph_scroll'>
            <div className='Prop_details_simpleChart_container mt-2 w-100' style={{ paddingBottom: '45px', minWidth: '700px' }}>
                <ResponsiveContainer width="100%" height={390}>
                    <BarChart data={data} className='Prop_details_simpleChart Prop_details_soldChart' {...{
                        overflow: 'visible'
                    }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" angle={90} textAnchor='startp' />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} position={{ x: 'auto', y: 'auto' }} />
                        {/* <Legend /> */}
                        <Bar type="monotone" dataKey="price" fill="#E2E2E2" activeBar={{ fill: '#0253A0', }} barSize={15} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default SoldDistributionChart;
